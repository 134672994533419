import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Box,
  FormControl,
  IconButton,
  Input,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FaLink , FaImage , FaTelegramPlane } from "react-icons/fa";
import { getSender, getSenderFull,getSenderId } from "../../config/chat";
import { useAppContext } from "../../context/ChatProvider";
import api from "../../utils/axios";
import ProfileModal from "./ProfileModal";
import ScrollableChat from "./ScrollableChat";


import io from "socket.io-client";

let socket, selectedChatCompare;

const SingleChat = ({ fetchAgain, setFetchAgain }) => {
  const { selectedChat, setSelectedChat, user, notification, setNotification } =
    useAppContext();

  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [socketConnected, setSocketConnected] = useState(false);
  const [typing, setTyping] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  const fetchMessages = async () => {
    if (!selectedChat) return;

    try {
      setLoading(true);

      const { data } = await api.get(`message/${selectedChat.id}`);

      setMessages(data);
      setLoading(false);
      socket.emit("join-chat", selectedChat.id);
    } catch (error) {
      toast.error(error);
    }
  };

  const sendMessage = async (e) => {
    if (e.key === "Enter" && newMessage) {
      socket.emit("stop-typing", selectedChat.id);
      try {
        const { data } = await api.post(`message/`, {
          message: newMessage,
          chatId: selectedChat.id,
        });

        data.sendUser = selectedChat.from === user?.data.id ? selectedChat.to : selectedChat.from;

        setNewMessage("");
        socket.emit("new-message", data);
        setMessages([...messages, data]);
      } catch (error) {
        toast.error(error);
      }
    }
  };

  useEffect(() => {
    //alert("dd")
    socket = io(process.env.REACT_APP_BASE_URL_SOCKET);
    console.log(user)
    socket.emit("setup", user);
    
    socket.on("connected", () => setSocketConnected(true));

    socket.on("typing", () => setIsTyping(true));
    socket.on("stop-typing", () => setIsTyping(false));
  }, []);

  useEffect(() => {
    fetchMessages();

    selectedChatCompare = selectedChat;
  }, [selectedChat]);

  useEffect(() => {
    socket.on("message-received", (newMessageReceived) => {
      console.log(newMessageReceived)
      if (
        !selectedChatCompare ||
        selectedChatCompare.id !== newMessageReceived.Chat.id
      ) {
        // notification
        if (!notification.includes(newMessageReceived)) {
         
          setNotification([newMessageReceived, ...notification]);
          //console.log(notification)
          setFetchAgain(!fetchAgain);
        }
      } else {
        console.log(selectedChatCompare.id + " " + newMessageReceived.Chat.id)
        setMessages([...messages, newMessageReceived]);
        console.log(messages)
      }
    });
  });

  const getInitials = function (string) {
        var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
        
        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

  const typingHandler = (e) => {
    setNewMessage(e.target.value);

    if (!socketConnected) return;

    if (!typing) {
      setTyping(true);
      socket.emit("typing", selectedChat.id);
    }
    let lastTypingTime = new Date().getTime();
    var timerLength = 3000;
    setTimeout(() => {
      var timeNow = new Date().getTime();
      var timeDiff = timeNow - lastTypingTime;
      if (timeDiff >= timerLength && typing) {
        socket.emit("stop-typing", selectedChat.id);
        setTyping(false);
      }
    }, timerLength);
  };

  return (
    <>
      {selectedChat ? (
        <>
          <Box
            
            py={5}
            px={4}
            w="100%"
            
            className="user_box"
            style={{margin: '0px'}}
            display="flex"
            justifyContent={{ base: "space-between" }}
            alignItems="center"
          >
            <IconButton
              display={{ base: "flex", md: "none" }}
              icon={<ArrowBackIcon />}
              onClick={() => setSelectedChat("")}
            />
            {!selectedChat.isGroupChat ? (
              <>
                
                <div className="d-flex">
                  <div className="name_box">
                    <h3>{getInitials(getSender(user, selectedChat.fromUser,selectedChat.toUser))}</h3>
                  </div>

                  <div className="mrg_lft_15">
                    <h2>{getSender(user, selectedChat.fromUser,selectedChat.toUser)}</h2>
                    <p>{selectedChat.Message.message}</p>
                  </div>
                </div>
                
                
              </>
            ) : (
             null
            )}
          </Box>
          <Box
            display="flex"
            flexDir="column"
            justifyContent="flex-end"
            
            bg="#f5f5f5"
            className="br_bot_rgt_10"
            w="100%"
            h="100%"
            
            overflowY="hidden"
          >
            {loading ? (
              <Spinner
                size="xl"
                w={20}
                h={20}
                alignSelf="center"
                margin="auto"
              />
            ) : (
              <div className="message">
                <ScrollableChat messages={messages} />
              </div>
            )}
            <FormControl className="message_type_area" onKeyDown={sendMessage} isRequired mt={3}>
              {isTyping ? <div>Typing ...</div> : <></>}
              <Input
                variant="filled"
                bg="#fff"
                placeholder="Write a message.."
                value={newMessage}
                onChange={typingHandler}
              />
              
            </FormControl>
          </Box>
        </>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          h="100%"
        >
          <Text fontSize="3xl" pb={3} fontFamily="Poppins">
            Click On Users to Start Conversation
          </Text>
        </Box>
      )}
    </>
  );
};

export default SingleChat;
