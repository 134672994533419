import React from 'react'
import noImagePlaceholder from '../assets/images/no_image.png'
import optionsDots from '../assets/svgs/Icon material-more-horiz.svg'
import ListGroup from 'react-bootstrap/ListGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useNavigate } from 'react-router-dom'
import { FiEye, FiEdit, FiTrash2 } from 'react-icons/fi';
import { FaSign } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
import Countdown from "react-countdown";
function AuctionListingCard(props) {
    const { listingDetails } = props;
    const { user } = useSelector((state) => state.auth)
    const { interiorNoOfBedRooms, propertyType, PropertyLocation, underReview, PropertyFiles,title,propertyDetail,bid_price,auctionTime} = listingDetails;
    

    const propertyDetailValue = JSON.parse(propertyDetail)
    const navigate = useNavigate()
    
    function titleCase(string) {
        var sentence = string.toLowerCase().split(" ");
        for(var i = 0; i< sentence.length; i++){
           sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
        }
     document.write(sentence.join(" "));
     return sentence;
     }

    const options = (
        <ListGroup style={{
            marginTop: '1rem',
            width: '12rem',
        }}>
            
            <ListGroup.Item
            onClick={() => navigate(`/property/${listingDetails.id}`, {state: listingDetails})}><FiEye style={{ color: 'blue' }}/><span>View</span></ListGroup.Item>
            { listingDetails.userId == user?.data?.id   ? (

                <>

            <ListGroup.Item
            onClick={() => navigate(`/edit-property/${listingDetails.id}`, {state: listingDetails})}
            ><FiEdit style={{ color: 'blue' }}/><span>Manage Listing</span></ListGroup.Item>

            <ListGroup.Item
            onClick={() => navigate(`/edit-property-images/${listingDetails.id}`, {state: listingDetails})}
            ><FiEdit style={{ color: 'blue' }}/><span>Manage Images</span></ListGroup.Item>

            <ListGroup.Item style={{ color: 'green' }} onClick={() => props.markSold(listingDetails.id)}><FaSign/><span>Mark As Complete</span></ListGroup.Item>
            <ListGroup.Item style={{ color: 'red' }} onClick={() => props.markDelete(listingDetails.id)}><FiTrash2/><span>Delete</span></ListGroup.Item>
            </>
            )
            : null
            }
        </ListGroup>
    )


    const Completionist = () => <span style={{color:'red'}}>Auction End!</span>;

// Renderer callback with condition
const renderer = ({ days,hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <span style={{color:'red'}}>
        {days > 0 ? (`${days} days and ${hours}:${minutes}:${seconds}`) : (`${hours}:${minutes}:${seconds}`)
        
        }
      </span>
    );
  }
};

    return (
        <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            width: '221px'
        }}>
            <div>
                <img 
                src={(PropertyFiles?.[0]?.fileURL !== 'string' ? PropertyFiles?.[0]?.fileURL : noImagePlaceholder) || noImagePlaceholder} 
                alt='img'
                style={{
                    width: '221px',
                    height: '137px',
                    borderRadius: '4px',
                    resize: 'both'
                }} />
                
            </div>
            <div>
                Base Price: {bid_price}
            </div>
            <div>
                <Countdown date={new Date(listingDetails?.auctionEndTime + ' 00:00:00') } renderer={renderer} />
            </div>
            <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                position: 'relative'
            }}>
                <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <span className="blue" style={{ fontWeight: '500', fontSize: '14px' }}>{title}</span>
                    
                    <OverlayTrigger trigger="click" rootClose placement="left-start" overlay={options}>
                    <img 
                    src={optionsDots} 
                    style={{
                        cursor: 'pointer'
                    }}
                    alt='options'
                    />
                    </OverlayTrigger>
                </div>
                {/* {optionsMenu === true ? (
                
                ) : null} */}

                <span style={{ display: 'flex', flexWrap: 'wrap', fontSize: '14px' }}>{propertyDetailValue.address}</span>
                {listingDetails.status_id == 10 && 
                    <span style={{color:'green',fontWeight:'bold'}} >Completed</span>
                }
            </div>
        </div>
    )
}

export default AuctionListingCard