import React from 'react';
import { FiChevronDown } from 'react-icons/fi'

function SelectProComponent(props) {
    const { label, options, id, onChange, onBlur, style, required, blueBorder,field,value} = props;
    const [selectedOption, setSelectedOption] = React.useState(null)
    
    return (
        <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
            position: 'relative',
            width: '368px',
            ...style
        }}>

            {label ? <h5
            style={{
                fontSize: '14px',
                fontWeight: '400',
                color: '#848484'
            }}>{label}{required === true ? (<span className='red'>*</span>) : null}</h5> : null}
            <div 
            style={{
                border: blueBorder === true ? '1px solid #1761B0' : '1px solid #292D2B26',
                borderRadius: '4px',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'white'
            }}>
                
                <select 
                className='input-text' 
                id={id} 
                value={value}
                onChange={(e) => {
                    
                    setSelectedOption(e.target.value)
                    onChange(e)
                }} 
                onBlur={onBlur}
                style={{
                  
                    width: '100%'
                }}>
                    <option value="">-- Select --- </option>
                    {options?.map(option => <option value={option.value}  id={option.value}>{option.text}</option>)}
                </select>    
                       
            </div>

        </div>
    )
}

export default SelectProComponent