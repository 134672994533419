import * as yup from "yup";

const addPropertySchema = yup.object({
    property:yup.object({

        propertyType:yup.string().required("Please select property type"),
        title:yup.string().required("Please enter property title"),
        saleType:yup.string().required("Please select sale type"),
        price:yup.string().required("Please enter Price"),
        propertyDescription:yup.string().required("required"),
        signatureFirst:yup.string().required("required"),
        signatureSecond:yup.string().required("required"),
        date:yup.string().required("required"),
        date1:yup.string().required("required"),
         maintenance:yup.string().when("propertyType", {
           is: (propertyType) => propertyType === "townhouse",
          then: yup.string().required("Please select maintenance"),
           }),






    
                             propertyDetail: yup.object({


                               
                                ownerName:yup.string().required("Please enter ownerName"),
                                // country:yup.string().required("Please select country"),
                                // state:yup.string().required("Please select state"),
                                // city:yup.string().required("Please select city"),
                                // address:yup.string().required("Please enter address"),


                               
                                
                                
                                

                                

                                

                               

                            }).when("propertyType", {
                                is: (propertyType) => propertyType === "pre-construction-house",
                                then: yup.object({
                                    address:yup.string().required("Please enter address"),
                                    builder:yup.string().required("Please select builder .."),
                                    country:yup.string().required("Please select country"),
                                    state:yup.string().required("Please select Province"),
                                    city:yup.string().required("Please select city"),
                                    developer:yup.string().required("Please enter developer"),
                                    neighbourhood:yup.string().required("Please enter neighbourhood"),
                                    nameOfProject:yup.string().required("Please enter nameOfProject"),
                                    noOfHome:yup.string().required("Please enter noOfHome"),
                                    noOfPhase:yup.string().required("Please enter noOfPhase"),
                                    approxCompletion:yup.string().required("Please select approxCompletion"),
                                    propertyType:yup.string().required("Please type is required"),
                                    bedroom:yup.string().required("Please bedroom is required"),
                                    squareFeet:yup.string().required("Please squareFeet  is required"),


                                }),
                             })

                            .when("propertyType", {
                                is: (propertyType) => propertyType === "pre-construction-condos",
                                then: yup.object({
                                     bedroom:yup.string().required("Please select bedroom"),
                                    builder:yup.string().required("Please select builder .."),
                                    address:yup.string().required("Please enter address"),
                                    squareFeet:yup.string().required("Please select squareFeet"),
                                     country:yup.string().required("Please select country"),
                                    state:yup.string().required("Please select Province"),
                                    city:yup.string().required("Please select city"),
                                    developer:yup.string().required("Please enter developer"),
                          
                                    nameOfProject:yup.string().required("Please enter nameOfProject"),
                                   
                                    approxCompletion:yup.string().required("Please select approxCompletion"),
                                    storey:yup.string().required("Please enter storey"),
                                    condo_unit:yup.string().required("Please enter condo_unit"),
                                    projectPhase:yup.string().required("Please enter projectPhase"),
                                    approxMaintenanceFee:yup.string().required("Please enter approxMaintenanceFee"),
                                }),
                             })

                            .when("propertyType", {
                                is: (propertyType) => propertyType === "VACANT-LAND" || propertyType === "ROOM-ONLY" || propertyType === "BASEMENT" || propertyType === "OTHER" ,
                                then: yup.object({
                                    country:yup.string().required("Please select country"),
                                    state:yup.string().required("Please select Province"),
                                    lotFrontage:yup.string().required("Please enter lotFrontage"),
                                    city:yup.string().required("Please select city"),
                                    address:yup.string().required("Please enter address"),
                                    zoning:yup.string().required("Please select zoning"),
                                    fronting:yup.string().required("Please select fronting"),
                                    
                                }),
                             })
                            

                            .when(["propertyType","saleType"], {
                                is: (propertyType,saleType) => saleType === "SALE" &&  propertyType === "HOUSES",
                                then: yup.object({
                                        address:yup.string().required("Please enter address"),
                                        legalName:yup.string().required("Please enter legalName"),
                                        country:yup.string().required("Please select country"),
                                        state:yup.string().required("Please select Province"),
                                        city:yup.string().required("Please select city"),                                     
                                        propertySize:yup.string().required("Please enter property size"),
                                        fronting:yup.string().required("Please select fronting"),
                                        lotFrontage:yup.string().required("Please enter lotFrontage"),
                                        // lotDepth:yup.string().required("Please enter lotDepth"),
                                        // legalDescription:yup.string().required("Please enter legalDescription"),
                                        bedroom:yup.string().required("Please select bedroom"),
                                        kitchen:yup.string().required("Please select country"),
                                        washroom:yup.string().required("Please select washroom"),
                                        squareFeet:yup.string().required("Please select squareFeet"),
                                        basement:yup.string().required("Please select basement"),
                                        parking:yup.string().required("Please enter parking"),
                                       
                                        garageType:yup.string().required("Please select garageType"),
                                        garageParking:yup.string().required("Please select garageParking"),
                                        heat:yup.string().required("Please select heat"),
                                        heatType:yup.string().required("Please select heatType"),
                                        airCondition:yup.string().required("Please select airCondition"),
                                        propertyTax:yup.string().required("Please enter propertyTax"),
                                        pool:yup.string().required("Please select pool"),
                                        firePlace:yup.string().required("Please select firePlace"),


                                }),
                             })

                             .when(["propertyType","saleType"], {
                                is: (propertyType,saleType) => saleType === "RENT" &&  propertyType === "HOUSES",
                                then: yup.object({

                                        heat:yup.string().required("Please select heat"),
                                        address:yup.string().required("Please enter address"),
                                         bedroom:yup.string().required("Please select bedroom"),
                                        squareFeet:yup.string().required("Please select squareFeet"),
                                        airCondition:yup.string().required("Please select airCondition"),
                                        parkingType:yup.string().required("Please enter parkingType"),
                                        parkingSpot:yup.string().required("Please enter parkingSpot"),
                                        country:yup.string().required("Please select country"),
                                        state:yup.string().required("Please select Province"),
                                        city:yup.string().required("Please select city"), 
                                        rentalType:yup.string().required("Please select rentalType"),
                                        bathroom:yup.string().required("Please select bathroom"),
                                        streetNo:yup.string().required("Please enter streetNo"),
                                        street:yup.string().required("Please enter street"),
                                        unitNumber:yup.string().required("Please enter unitNumber"),
                                        dateAvailable:yup.string().required("Please enter dateAvailable"),
                                        leaseLength:yup.string().required("Please enter LeaseLength"),
                                        amenities:yup.string().required("Please enter amenities"),
                                        laundryType:yup.string().required("Please enter laundryType"),
                                        petFriendly:yup.string().required("Please enter petFriendly"),


                                }),
                             })

                            .when(["propertyType","saleType"], {
                                is: (propertyType,saleType) => saleType === "SALE" && propertyType === "CONDOS",
                                then: yup.object({
                                    address:yup.string().required("Please enter address"),
                                        legalName:yup.string().required("Please enter legalName"),
                                        country:yup.string().required("Please select country"),
                                        state:yup.string().required("Please select Province"),
                                        city:yup.string().required("Please select city"),                                     
                                        propertySize:yup.string().required("Please enter property size"),
                                        fronting:yup.string().required("Please select fronting"),
                                        lotFrontage:yup.string().required("Please enter lotFrontage"),
                                        // lotDepth:yup.string().required("Please enter lotDepth"),
                                        // legalDescription:yup.string().required("Please enter legalDescription"),
                                        bedroom:yup.string().required("Please select bedroom"),
                                        kitchen:yup.string().required("Please select country"),
                                        washroom:yup.string().required("Please select washroom"),
                                        squareFeet:yup.string().required("Please select squareFeet"),
                                        basement:yup.string().required("Please select basement"),
                                        parking:yup.string().required("Please enter parking"),
                                        
                                        garageType:yup.string().required("Please select garageType"),
                                        garageParking:yup.string().required("Please select garageParking"),
                                        heat:yup.string().required("Please select heat"),
                                        heatType:yup.string().required("Please select heatType"),
                                        airCondition:yup.string().required("Please select airCondition"),
                                        propertyTax:yup.string().required("Please enter propertyTax"),
                                        pool:yup.string().required("Please select pool"),
                                        firePlace:yup.string().required("Please select firePlace"),                            
                                    unitNumber:yup.string().required("Please enter unitNumber"),       


                                }),
                             })

                            .when(["propertyType","saleType"], {
                                is: (propertyType,saleType) => saleType === "RENT" && propertyType === "CONDOS",
                                then: yup.object({
                                        address:yup.string().required("Please enter address"),
                                        rentalType:yup.string().required("Please select rentalType"),
                                       
                                        country:yup.string().required("Please select country"),
                                        state:yup.string().required("Please select Province"),
                                        city:yup.string().required("Please select city"),                                     
                                       
                                        bedroom:yup.string().required("Please enter bedroom"),
                                        // legalDescription:yup.string().required("Please enter legalDescription"),
                                        bathroom:yup.string().required("Please select bathroom"),
                                        parkingType:yup.string().required("Please enter parkingType"),
                                        parkingSpot:yup.string().required("Please enter parkingSpot"),
                                        squareFeet:yup.string().required("Please select squareFeet"),
                                        street:yup.string().required("Please enter street"),
                                        streetNo:yup.string().required("Please enter streetNo"),
                                        
                                        
                                       
                                        heat:yup.string().required("Please select heat"),
                                       
                                        airCondition:yup.string().required("Please select airCondition"),
                                       dateAvailable:yup.string().required("Please enter dateAvailable"),
                                        leaseLength:yup.string().required("Please enter LeaseLength"),
                                        amenities:yup.string().required("Please enter amenities"),
                                        laundryType:yup.string().required("Please enter laundryType"),
                                        petFriendly:yup.string().required("Please enter petFriendly"),                        
                                    unitNumber:yup.string().required("Please enter unitNumber"),       


                                }),
                             })


                            .when(["propertyType","maintenance"], {
                                is: (propertyType ,maintenance) => propertyType === "townhouse" && maintenance === "Yes",
                                then: yup.object({
                                        legalName:yup.string().required("Please enter legalName"),
                                        address:yup.string().required("Please enter address"),
                                        country:yup.string().required("Please select country"),
                                        state:yup.string().required("Please select Province"),
                                        city:yup.string().required("Please select city"),                                     
                                        propertySize:yup.string().required("Please enter property size"),
                                        fronting:yup.string().required("Please select fronting"),
                                        lotFrontage:yup.string().required("Please enter lotFrontage"),
                                        unitNumber:yup.string().required("Please enter unitNumber"),
                                        // legalDescription:yup.string().required("Please enter legalDescription"),
                                        bedroom:yup.string().required("Please select bedroom"),
                                        kitchen:yup.string().required("Please select country"),
                                        washroom:yup.string().required("Please select washroom"),
                                        squareFeet:yup.string().required("Please select squareFeet"),
                                        basement:yup.string().required("Please select basement"),
                                        parking:yup.string().required("Please enter parking"),
                                        
                                        garageType:yup.string().required("Please select garageType"),
                                        garageParking:yup.string().required("Please select garageParking"),
                                        heat:yup.string().required("Please select heat"),
                                        heatType:yup.string().required("Please select heatType"),
                                        airCondition:yup.string().required("Please select airCondition"),
                                        propertyTax:yup.string().required("Please enter propertyTax"),
                                        pool:yup.string().required("Please select pool"),
                                        firePlace:yup.string().required("Please select firePlace"),
                                        


                                }),
                             })

                            .when(["propertyType","maintenance"], {
                                is: (propertyType ,maintenance) => propertyType === "townhouse" && maintenance === "No",
                                then: yup.object({
                                        legalName:yup.string().required("Please enter legalName"),
                                        address:yup.string().required("Please enter address"),
                                        country:yup.string().required("Please select country"),
                                        state:yup.string().required("Please select Province"),
                                        city:yup.string().required("Please select city"),                                     
                                        propertySize:yup.string().required("Please enter property size"),
                                        fronting:yup.string().required("Please select fronting"),
                                        lotFrontage:yup.string().required("Please enter lotFrontage"),
                                        
                                        // legalDescription:yup.string().required("Please enter legalDescription"),
                                        bedroom:yup.string().required("Please select bedroom"),
                                        kitchen:yup.string().required("Please select country"),
                                        
                                        squareFeet:yup.string().required("Please select squareFeet"),
                                        basement:yup.string().required("Please select basement"),
                                        parking:yup.string().required("Please enter parking"),
                                        
                                        garageType:yup.string().required("Please select garageType"),
                                        garageParking:yup.string().required("Please select garageParking"),
                                        heat:yup.string().required("Please select heat"),
                                        heatType:yup.string().required("Please select heatType"),
                                        airCondition:yup.string().required("Please select airCondition"),
                                        propertyTax:yup.string().required("Please enter propertyTax"),
                                        pool:yup.string().required("Please select pool"),
                                        firePlace:yup.string().required("Please select firePlace"),
                                        


                                }),
                             })

    })
})

export default addPropertySchema;