import * as yup from "yup";

const addPropertySchema = yup.object({
    property:yup.object({

        propertyType:yup.string().required("Please select property type"),
        title:yup.string().required("Please enter property title"),
        saleType:yup.string().required("Please select sale type"),
        price:yup.string().required("Please enter Price"),
        propertyDescription:yup.string().required("required"),
        signatureFirst:yup.string().required("required"),
        signatureSecond:yup.string().required("required"),


    
                             propertyDetail: yup.object({
                                
                                ownerName:yup.string().required("Please enter ownerName"),
                                country:yup.string().required("Please select country"),
                                state:yup.string().required("Please select state"),
                                city:yup.string().required("Please select city"),
                                address:yup.string().required("Please enter address"),
                               
                                // lotDepth:yup.string().required("Please enter lotDepth"),
                                // legalDescription:yup.string().required("Please enter legalDescription"),
                               
                               
                                // parking:yup.string().required("Please enter parking"),
                                // parkingType:yup.string().required("Please enter parkingType"),
                                // parkingSpot:yup.string().required("Please enter parkingSpot"),
                                // garageType:yup.string().required("Please select garageType"),
                                // garageParking:yup.string().required("Please select garageParking"),
                                // heat:yup.string().required("Please select heat"),
                                // heatType:yup.string().required("Please select heatType"),
                                // airCondition:yup.string().required("Please select airCondition"),
                                // propertyTax:yup.string().required("Please enter propertyTax"),
                                // pool:yup.string().required("Please select pool"),
                                // firePlace:yup.string().required("Please select firePlace"),

                             


                                builder:yup.string().required("Please enter builder"),
                                developer:yup.string().required("Please enter developer"),
                                neighbourhood:yup.string().required("Please enter neighbourhood"),
                                nameOfProject:yup.string().required("Please enter nameOfProject"),
                                noOfHome:yup.string().required("Please enter noOfHome"),
                                noOfPhase:yup.string().required("Please enter noOfPhase"),
                                approxCompletion:yup.string().required("Please select approxCompletion"),

                                // condo_unit:yup.string().required("Please enter condo_unit"),
                                // projectPhase:yup.string().required("Please enter projectPhase"),
                                // approxMaintenanceFee:yup.string().required("Please enter approxMaintenanceFee"),

                                // rentalType:yup.string().required("Please select rentalType"),
                                // bathroom:yup.string().required("Please select bathroom"),
                                // streetNo:yup.string().required("Please enter streetNo"),
                                // street:yup.string().required("Please enter street"),
                                // unitNumber:yup.string().required("Please enter unitNumber"),
                                // dateAvailable:yup.string().required("Please enter dateAvailable"),
                                // leaseLength:yup.string().required("Please enter LeaseLength"),
                                // amenities:yup.string().required("Please enter amenities"),
                                // laundryType:yup.string().required("Please enter laundryType"),
                                // petFriendly:yup.string().required("Please enter petFriendly"),

                                propertyType:yup.string().required("Please select type")

                                // interiorNoOfRooms: yup
                                // .string()
                                // .required("Please enter number of rooms")
                                // .matches(new RegExp(/^\d/), "Number of rooms must be a number"),
                                // interiorNoOfBedRooms: yup
                                // .string()
                                // .required("Please enter number of bedrooms")
                                // .matches(new RegExp(/^\d/), "Number of rooms must be a number"),
                                // interiorNoOfKitchens: yup
                                // .string()
                                // .required("Please enter number of kitchens")
                                // .matches(new RegExp(/^\d/), "Number of rooms must be a number"),
                                // interiorNoOfWashrooms: yup
                                // .string()
                                // .required("Please enter number of washrooms")
                                // .matches(new RegExp(/^\d/), "Number of rooms must be a number"),
                                // listPrice: yup
                                // .string()
                                // .required("Please enter a list price")
                                // .matches(new RegExp(/^\d/), "Price must be a number"),     
                                // taxes: yup
                                // .string()
                                // .required("Please enter a list price")
                                // .matches(new RegExp(/^\d/), "Price must be a number"),     
                                // taxYear: yup.number()
                                // .test('len', 'Year must be exactly 4 characters long', 
                                // val => val && val.toString().length === 4),

                            })

    })
})

export default addPropertySchema;