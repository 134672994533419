import * as yup from "yup";

const accountDetailsSchema = yup.object({
    firstName: yup.string()
    .min(2, 'Requires 2 or more characters')
    .required('First name is required')
    .matches(new RegExp(/^[a-zA-Z]+[\sa-zA-Z]+$/), "Name should only contain letters"),
    contactNumber: yup
    .string()
    .required("Please enter a phone number")
    .matches(new RegExp(/^\d{7}/), "Phone number must be 7 digits long"),
})

export default accountDetailsSchema;
