import React, {useCallback,useState} from 'react'
import { useDropzone } from 'react-dropzone'
import Button from 'react-bootstrap/Button';
import { S3Client, AbortMultipartUploadCommand, PutObjectCommand } from "@aws-sdk/client-s3";
import { Upload } from '@aws-sdk/lib-storage';
import SelectComponent from './SelectProComponent'
function DropZoneSingle(props) {

    const [categoryId, setCategoryId] = useState(1);
    const { setFiles,id,updateImage,propertyId } = props;
    const filesArray = [];
    const client = new S3Client({ 
        region: 'ca-central-1',
        credentials: 
        {
            accessKeyId: "AKIASZC6WDTA4D3XNJF3",
            secretAccessKey: "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"
        }
    });

    const { getRootProps, getInputProps, acceptedFiles, isDragActive } = useDropzone({});
    const files = acceptedFiles.map((file) => (
        <li key={file.path}>
          {file.path} - {(file.size/1000).toFixed(1)} kB
          <img width="100" src={`https://propertyways-s3-dev.s3.ca-central-1.amazonaws.com/${file.path}`} />
         
        </li>
      ));

    const [loading, setLoading] = React.useState(null)
    
    React.useEffect(() => {
        acceptedFiles.forEach((file, idx) => {
          const params = {
            Bucket: "propertyways-s3-dev", // The name of the bucket. For example, 'sample_bucket_101'.
            Key: file?.name, // The name of the object. For example, 'sample_upload.txt'.
            Body: file, // The content of the object. For example, 'Hello world!".
          };


          async function fetchData() {
              const parallelUploads3 = new Upload({
                  client: client,
                  params: params
                });
              
                parallelUploads3.on("httpUploadProgress", (progress) => {
                  console.log("progress: ", progress);
                });
                setLoading(true)
                const result = await parallelUploads3.done();
                setLoading(false)
                const fileData = {
                  fileURL: result?.Location,
                  fileTitle: result?.Key,
                  documentType: (categoryId == 2) ? "video" : 'image',
                  categoryId:categoryId,
                  propertyId:propertyId,
                };
                filesArray.push(fileData)
                if (idx === acceptedFiles.length-1) {
                  updateImage(id,fileData)
                  //setFiles(filesArray)
                }
                return result
          }
          fetchData()
          if (loading === true) {console.log("loading...")}
          else {console.log("finished loading.")}
        })
        // setFiles(filesArray)
    }, [acceptedFiles])


   const meChange = (e) => {
       setCategoryId(e.target.value);
   }


    return (
      <>
        <div>
          
          <SelectComponent onChange={(e) => meChange(e)}  required={true} id='CategoryId' label="Category" 
          options={[{value: '1', text: 'Photos'}, {value: '2', text: 'Video'}, {value: '3', text: 'Floor Plan'}]} />
        </div>
        <div className='dropzone' style={{width:'100%'}} {...getRootProps({ className: "dropzone" })}>
          <input className="input-zone" {...getInputProps()} />
          <div className="text-center">
          {isDragActive ? (
          <h4 className="blue">
            Release to drop the files here 
          </h4>
        ) : (
          <h4 className="blue">
            Drag and drop file
          </h4>
        )}
            <p className='blue' style={{ fontWeight: '500' }}>or</p>
            <Button className='button' style={{ width: '12rem', height: '3rem', fontWeight: '500' }}>
                Choose Files
            </Button>

            <div style={{ marginTop: '2rem' }}>

                <ul>{files}</ul>

                
            </div>
          </div>
        </div>
        </>
      );
}

export default DropZoneSingle