import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import propertyService from './propertyService'

// const user = JSON.parse(localStorage.getItem('user'))

const initialState = {
    addedProperty: null,
    listings: null,
    dashboardData: null,
    monthCount:null,
    liveAuction:null,
    isError: false,
    isSuccess: false,
    isLoading: false,
   
    message: ''
}

export const addProperty = createAsyncThunk('property/add', async(reqData, thunkAPI) => {
    const token = thunkAPI.getState().auth.accessToken
    try {
        return await propertyService.addProperty(token, reqData)
    } catch (error) {
        
    }
})


export const updateProperty = createAsyncThunk('property/update', async(reqData, thunkAPI) => {

    const token = thunkAPI.getState().auth.accessToken
    try {
        return await propertyService.updateProperty(token, reqData)
    } catch (error) {
         console.log(error)
    }
})


export const getDashboard = createAsyncThunk('property/dashboard', async(reqData, thunkAPI) => {
    const token = thunkAPI.getState().auth.accessToken
    try {
        return await propertyService.getDashboard(token, reqData)
    } catch (error) {
        
    }
})


export const getLiveAuction = createAsyncThunk('property/live-auction', async(reqData, thunkAPI) => {
    const token = thunkAPI.getState().auth.accessToken
    try {
        return await propertyService.getLiveAuction(token)
    } catch (error) {
        
    }
})

export const getMonthCount = createAsyncThunk('property/monthcount', async(reqData, thunkAPI) => {
    const token = thunkAPI.getState().auth.accessToken
    try {
        return await propertyService.getMonthCount(token)
    } catch (error) {
        
    }
})

export const getProperties = createAsyncThunk('property/getAll', async(reqData, thunkAPI) => {
    const token = thunkAPI.getState().auth.accessToken
    try {
        return await propertyService.getProperties(token, reqData)
    } catch (error) {
        
    }
})

export const propertySlice = createSlice({
    name: 'property',
    initialState: initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.addedProperty = null
            state.message = ''
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(addProperty.pending, (state) => {
            state.isLoading = true
        })
        .addCase(addProperty.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.addedProperty = action.payload?.data

        })

        .addCase(updateProperty.pending, (state) => {
            state.isLoading = true
        })
        .addCase(updateProperty.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            //state.addedProperty = action.payload.data

        })


        .addCase(getProperties.pending, (state) => {
            state.isLoading = true
        })


        .addCase(getProperties.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.listings = action.payload.data
            
        })



        .addCase(getDashboard.pending, (state) => {
            state.isLoading = true
        })


        .addCase(getDashboard.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.dashboardData = action.payload.data
            
        })

        .addCase(getMonthCount.pending, (state) => {
            state.isLoading = true
        })


        .addCase(getMonthCount.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.monthCount = action.payload.data
            
        })


        .addCase(getLiveAuction.pending, (state) => {
            state.isLoading = true
        })


        .addCase(getLiveAuction.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.liveAuction = action.payload?.data
            
        })
    }
})

export const { reset } = propertySlice.actions
export default propertySlice.reducer