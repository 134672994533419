import React,{useEffect,useState} from 'react';
import AdminHeader from '../AdminHeader';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Link,
  useDisclosure,
  Spinner
} from '@chakra-ui/react'
import { fetchWrapper } from '../../../helpers';
import Loader from "../../../components/Loader";
import CustomModal from "../../../components/CustomModal";
import CustomModalLink from "../../../components/CustomModalLink";
import { BsPencil, BsFillTrashFill } from "react-icons/bs";
import Create from './Create'
import Edit from './Edit'


function Country() {
	const { isOpen, onOpen, onClose } = useDisclosure();
  	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
  	const [isOpenEdit,setIsOpenEdit] = useState(false)
  	const [modalEditView,setModalEditView] = useState(false)   

	const [list,setList]=useState([]);
	const [spinner, setSpinner] = useState(false);

	const meClose = () => {
	      
	       onClose();
	       
	  }
	  const meCloseEdit = () => {
	      
	       setIsOpenEdit(false);
	       
	  }
	useEffect(() => {
        LoadList();
    }, []);

     const LoadList= async () => {
 		setSpinner(true)
		const url = `${process.env.REACT_APP_BASE_URL}/country/list`;
		const data  = await fetchWrapper.get(url)
      	setList(data.data);
    	setSpinner(false)     
  	}

  	const meDelete =async(rs)=>{
  		const aa = window.confirm("Are you sure delete this country");
  		if(aa){
			const url = `${process.env.REACT_APP_BASE_URL}/country/delete/${rs.id}`;
			await fetchWrapper.delete(url)
			LoadList()
  		}
  	}

  	 const meEdit = (rs) => {
	    setIsOpenEdit(true);
	    setModalEditView(<Edit rs={rs} meCloseEdit={meCloseEdit}  loadList={LoadList} />)   
	}


	return(
		<>
		<CustomModalLink
	        showModalButtonText="Edit Country"
	        modalHeader="Edit Country"
	        isOpenEdit={isOpenEdit}
	        onCloseEdit={meCloseEdit}
	        onOpenEdit={onOpenEdit}   
	        modalBody={modalEditView}
	      />
		{spinner && <Loader /> }
		<AdminHeader/>
	<Box className="body_section">
		<Box>
	        <HStack justifyContent="space-between">
	          <Box className="dashboard_page">
	            <Text className="main_heading" mb="0px">Country</Text>
	          </Box>

                <Box>
	                <CustomModal
	                  showModalButtonText="Create New Country"
	                  modalHeader="Create New Country"
	                  isOpen={isOpen}
	                  onClose={onClose}
	                  onOpen={onOpen}
	                  modalBody=<Create meClose={meClose} loadList={LoadList} />
	                />
              </Box>
	        </HStack>
	    </Box>

	    <Box className="table_section">
          <TableContainer>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th>Name</Th>          
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
              	{list?.map((rs,index)=>{
              		return(
              		<>
              			<Tr key={index}>
              				<Td>{rs.name}</Td>
              				<Td>
              					<HStack mb="5px">
			                       <a onClick={() => meEdit(rs)} ><BsPencil /></a>  
			                        <a onClick={()=> meDelete(rs)} ><BsFillTrashFill /></a>
			                    </HStack>
              				</Td>
              			</Tr>
              		</>
              		)
              	})}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
    </Box>

		</>
	)
}

export default Country