import React from 'react'
import logo from '../assets/svgs/PW-Logo-Horizontal.svg'
import { MdOutlineArrowForward } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { addProperty,updateProperty,reset } from '../features/property/propertySlice'
import { toast } from "react-toastify";
function HeaderTemplate(props) {
    const { nextScreen, state, isReviewPropertyScreen, isDetailsScreen,isReview,pid } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleListMyProperty = () => {
         
         if(state && state['property']['mode'] == 'edit'){
            
            dispatch(updateProperty(state)).then(data => {
                
                navigate('/edit-property-images/' + state['propertyId'])
            })

            toast.success("Your property has been updated");
        }else{
            state.property.isDraft = false;
            dispatch(addProperty(state))
        }
        
    }

    const saveToDraft = () => {
        state.property.isDraft = true;
        //console.log(state);
        dispatch(addProperty(state))
        navigate('/home')
        dispatch(reset())
    }

    const saveProperty = () => {


        return false;
        state.property.isDraft = false;
        //console.log(state);
        const pData = dispatch(addProperty(state)).then(data => {
            
              navigate('/edit-property-images/' + data.payload.data.property.id)
        })
        
       
       
    }

    const reviewProperty = () => {
        navigate('/property/' + pid)
    }

    const propertyList = () => {
        navigate('/home',{state:'listings'})
    }

    

    return (
        <div
        style={{
            height: '77px',
            top: '0px',
            left:'0px',
            boxShadow: "0px 3px 6px #00000029",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'sticky',
            zIndex: '1000',
            top: '0',
            width: '100%',
            backgroundColor: 'white'
        }}>
            <img 
            src={logo} 
            alt='logo'
            style={{
                marginLeft: '74px',
                cursor: 'pointer'
            }}
            onClick={() => navigate('/home')} />
            <div
            style={{
                display: 'flex',
                gap: '2rem',
                marginRight: '60px'
            }}>

                {state && state['property']['mode'] == 'edit' ? (

                        <div 
                        className='blue' 
                        style={{ fontWeight: '600', cursor: 'pointer' }}
                        onClick={() => handleListMyProperty()}>Update <MdOutlineArrowForward style={{ width: '18px', height: '17px' }}/>
                        </div>

                    ) : (


                    <>

                        {isReviewPropertyScreen ? (
                    <div className='green' style={{ fontWeight: '600' }}>Edit</div>
                ) : null}


                {isReview  ?  <div className='red' style={{ fontWeight: '600', cursor: 'pointer' }} onClick={() => reviewProperty()} >Review Property</div> : null}

                {isDetailsScreen  ? null : <div className='red' style={{ fontWeight: '600', cursor: 'pointer' }} onClick={() => saveToDraft()} >Save to drafts</div>}
                {isDetailsScreen ? 

                    <div className='blue' style={{ fontWeight: '600', cursor: 'pointer' }} onClick={() => propertyList()} >Property List</div>

                 : (
                    isReviewPropertyScreen ? (
                        <div 
                        className='blue' 
                        style={{ fontWeight: '600', cursor: 'pointer' }}
                        onClick={() => handleListMyProperty()}>List My Property <MdOutlineArrowForward style={{ width: '18px', height: '17px' }}/>
                        </div>
                    ) : (
                        <button 
                        className='blue' 
                        style={{ fontWeight: '600', cursor: 'pointer' }}
                        ondClick={() => saveProperty()}
                        type="submit"
                       >Save & Continue <MdOutlineArrowForward style={{ width: '18px', height: '17px' }}/>
                        </button>


                    )
                )}

                    </>
                ) }
                
            </div>
        </div>
    )
}

export default HeaderTemplate