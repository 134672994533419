import React,{useState} from 'react'
import Button from 'react-bootstrap/Button';
import AuthPageSideImage from '../../components/AuthPageSideImage';
import fileUploadIcon from '../../assets/svgs/metro-file-upload.svg'
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Form, useFormik } from 'formik'
import { signup, reset } from '../../features/auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import accountDetailsSchema from '../../utils/validationSchema/accountDetailsSchema';
import Modal from 'react-bootstrap/Modal';
import SelectComponent from '../../components/SelectComponent'
import { fetchWrapper } from '../../helpers';
import Loader from "../../components/Loader";
function AccountDetails() {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const [accountCreated, setAccountCreated] = React.useState(false)
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [spinner, setSpinner] = useState(false)
    const { message } = useSelector((state) => state.auth)

    const { state } = location
    const { signUpValues } = state;

    React.useEffect(() => {
        if (message === 'User Created'){
            setAccountCreated(true)
            dispatch(reset())
        }
        loadState()
    }, [message])

    const loadState = async() => {

        const url = `${process.env.REACT_APP_BASE_URL}/master/state-list`;
        const data  = await fetchWrapper.get(url)
        setStateList(data);
    }

    const loadCity = async(state_id) => {

         const url = `${process.env.REACT_APP_BASE_URL}/master/city-list?state_id=${state_id}`;
        const data  = await fetchWrapper.get(url)
        setCityList(data);
    }

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            dob: '',
            contactNumber: '',
            retalerNumber: '',
            roleId: 1,
            companyName:'',
            companyAddress:'',
            companyCity:'',
            companyCountry:'',
            companyState:'',
            companyPostCode:'',
            companyPhoneNumber:'',
            jobRole:'',
            signingOfficer:'',

             license: null
        },
        validationSchema: accountDetailsSchema,
        onSubmit: async (values) => {
            
            const reqData = {
                ...values,
                userName: values.firstName,
                email: signUpValues.email,
                password: signUpValues.password
            }
            // console.log("requestData: ", reqData)
            setSpinner(true)
            dispatch(signup(reqData))
            setSpinner(false)
        }
    })

    const roles = [
        {
            "id": 1,
            "name": "Seller",
            "createdAt": "2022-09-11T08:26:53.225Z",
            "updatedAt": "2022-09-11T08:26:53.225Z"
        },
        {
            "id": 2,
            "name": "Buyer",
            "createdAt": "2022-09-11T08:27:17.030Z",
            "updatedAt": "2022-09-11T08:27:17.030Z"
        },
        {
            "id": 3,
            "name": "Realtor",
            "createdAt": "2022-09-11T08:27:17.030Z",
            "updatedAt": "2022-09-11T08:27:17.030Z"
        },
        {
            "id": 4,
            "name": "Builder",
            "createdAt": "2022-09-11T08:27:17.030Z",
            "updatedAt": "2022-09-11T08:27:17.030Z"
        },
        {
            "id": 5,
            "name": "Mortgage Agent",
            "createdAt": "2022-09-11T08:27:17.030Z",
            "updatedAt": "2022-09-11T08:27:17.030Z"
        },
        {
            "id": 6,
            "name": "Lawyer",
            "createdAt": "2022-09-11T08:27:17.030Z",
            "updatedAt": "2022-09-11T08:27:17.030Z"
        }
    ]

    React.useEffect(() => {
        if (formik.values.roleId === "1") {
            formik.setFieldValue("roleId", 1)
        } else if (formik.values.roleId === "2") {
            formik.setFieldValue("roleId", 2)
        }
    }, [formik.values.roleId])

    const getRoleName = () => {
        const searchRole = roles.filter(rs => rs.id == formik.values.roleId)
        if(formik.values.roleId == 2 || formik.values.roleId == 1){
            return "Realtor"
        }
        return searchRole[0].name
    }

  

    return (
        <>
        {spinner && <Loader /> }
        <div
        style={{
            display: 'flex',
            height: '100vh',
            flexWrap: 'wrap'
        }}>

            <AuthPageSideImage />
            <Formik
            initialValues={formik.initialValues}>
            {() => 
                {console.log("formik:", formik )
                return(
                <Form
                className='section'
                onSubmit={formik.handleSubmit}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2.5rem',
                }}>
                    <div
                    className='sub-section'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: '5rem',
                        marginRight: '5rem',
                        marginBottom: '5rem',
                        gap: '3rem'
                    }}>
                        <div
                        style={{
                            display:'flex',
                            flexDirection: 'column',
                            gap: '1rem'
                        }}>
                            <h4 className='blue'>Account Details</h4>
                            <p>Please enter your details to complete signing up</p>
                        </div>
                        <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem'
                        }}>
                            <div
                            className='row-2-cols'>
                                <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '0.5rem',
                                    width: '100%'
                                }}>
                                    <h5 className='blue'>First Name</h5>
                                    <input
                                    type='text'
                                    className='input-text'
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur} 
                                    id='firstName' 
                                    name='firstName' ></input>
                                    {(formik.errors.firstName && formik.touched.firstName) ? <div className='error-msg'>{formik.errors.firstName}</div> : null}
                                </div>
                                <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '0.5rem',
                                    width: '100%'
                                }}>
                                    <h5 className='blue'>Last Name</h5>
                                    <input
                                    type='text'
                                    className='input-text'
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur} 
                                    id='lastName' 
                                    name='lastName' ></input>
                                </div>
                            </div>
                            <div className='row-2-cols'>
                                <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '0.5rem',
                                    width: '100%'
                                }}>
                                    <h5 className='blue'>Date Of Birth</h5>
                                    <input
                                    type='date'
                                    className='input-text'
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur} 
                                    id='dob' 
                                    name='dob'></input>
                                </div>
                                <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '0.5rem',
                                    width: '100%'
                                }}>
                                    <h5 className='blue'>Phone Number</h5>
                                    <input
                                    type='text'
                                    className='input-text'
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur} 
                                    id='contactNumber' 
                                    name='contactNumber' ></input>
                                    {(formik.errors.contactNumber && formik.touched.contactNumber) ? <div className='error-msg'>{formik.errors.contactNumber}</div> : null}
                                </div>
                            </div>
                            <div className='row-2-cols'>
                                <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '0.5rem',
                                    width: '100%'
                                }}>
                                    <h5 className='blue'>Select Role</h5>
                                    <select 
                                    id='roleId'
                                    className='input-text'
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur} 
                                    style={{
                                        
                                    }} 
                                    >
                                        <option value="1">Seller</option>
                                        <option value="2">Buyer</option>
                                        <option value="3">Realtor</option>
                                        <option value="4">Builder</option>
                                        <option value="5">Mortgage Agent</option>
                                        <option value="6">Lawyer</option>
                                    </select>
                                </div>

                                {formik.values.roleId != 6 && formik.values.roleId != 4 ?  (
                                <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '0.5rem',
                                    width: '100%'
                                }}>
                                    <h5 className='blue'
                                    style={{
                                        fontStyle: 'italic',
                                        fontSize: '13px',
                                        fontWeight: '400'
                                    }}>To verify your account, please type your {getRoleName()} number.</h5>
                                    <input
                                    type='text'
                                    className='input-text'
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur} 
                                    id='retalerNumber' 
                                    name='retalerNumber' ></input>
                                </div>
                                ) : null}
                            </div>
                            <input 
                            type="file" 
                            accept="image/*"
                            id="userImage" 
                           style={{display: 'none'}}
                            onChange={(event) => {
                                formik.setFieldValue('license', event.target.files[0])
                                console.log("formik", formik)
                                console.log("event", event.target)}}
                            />

                            <label htmlFor='userImage'>
                                <Button
                                variant='danger'
                                style={{
                                    width: '12rem',
                                    padding: '1rem',
                                    fontWeight: '550',
                                    backgroundColor: '#D2282D',
                                    borderRadius: '10px',
                                    fontSize: '14px',
                                    marginTop: '1rem'
                                }}
                                onClick={
                                    () => {
                                        document.getElementById('userImage').click()
                                    }
                                }>
                                    <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '0.75rem'
                                    }}>
                                        <img src={fileUploadIcon} alt='file-upload-icon' />
                                        <p style={{marginBottom: '0'}}>Upload ID/License</p>
                                    </div>
                                </Button>                                
                            </label>
                            <span>{formik.values.license ? formik.values.license?.name : ''}
                           
                            </span>


                            <h4 className='blue'>Company Details</h4>


                                <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '0.5rem',
                                    width: '100%'
                                }}>
                                    <h5 className='blue'>Company Name</h5>

                                    <input
                                    type='text'
                                    className='input-text'
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur} 
                                    id='companyName' 
                                    name='companyName' ></input>
                                    {(formik.errors.companyName && formik.touched.companyName) ? <div className='error-msg'>{formik.errors.companyName}</div> : null}
                                    
                                </div>
                            <div className='row-2-cols'>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '0.5rem',
                                        width: '100%'
                                    }}>
                                        <h5 className='blue'>Are you the signing officer?</h5>
                                        <SelectComponent  formik={formik} onChange={formik.handleChange} onBlur={formik.handleBlur} id='signingOfficer' label="" options={[{value: 'Yes', text: 'Yes'},{value: 'No', text: 'No'}]} />
                                        
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '0.5rem',
                                        width: '100%'
                                    }}>
                                        <h5 className='blue'>Job Role</h5>
                                        <SelectComponent  formik={formik} onChange={formik.handleChange} onBlur={formik.handleBlur} id='jobRole' label="" options={[{value: '1', text: 'Manager'}]} />
                                        
                                    </div>
                                </div>

                            <div className='row-2-cols'>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '0.5rem',
                                        width: '100%'
                                    }}>
                                        <h5 className='blue'>Company Address</h5>
                                        <input
                                    type='text'
                                    className='input-text'
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur} 
                                    id='companyAddress' 
                                    name='companyAddress' ></input>
                                    {(formik.errors.companyAddress && formik.touched.companyAddress) ? <div className='error-msg'>{formik.errors.companyAddress}</div> : null}
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '0.5rem',
                                        width: '100%'
                                    }}>
                                        <h5 className='blue'>State</h5>
                                        <SelectComponent  formik={formik} onChange={

                                            e => {
                                          
                                            formik.handleChange(e)
                                            loadCity(e.target.value)

                                        }} onBlur={formik.handleBlur} id='companyState' label="" options={stateList} />
                                        
                                    </div>
                                </div>


                            <div className='row-2-cols'>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '0.5rem',
                                        width: '100%'
                                    }}>
                                        <h5 className='blue'>City</h5>
                                        <SelectComponent  formik={formik} onChange={formik.handleChange} onBlur={formik.handleBlur} id='companyCity' label="" options={cityList} />
                                        
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '0.5rem',
                                        width: '100%'
                                    }}>
                                        <h5 className='blue'>Country</h5>
                                        <SelectComponent  formik={formik} onChange={formik.handleChange} onBlur={formik.handleBlur} id='companyCountry' label="" options={[{value: '1', text: 'Canada'}]} />
                                       
                                    </div>
                                </div>


                            <div className='row-2-cols'>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '0.5rem',
                                        width: '100%'
                                    }}>
                                        <h5 className='blue'>Post code</h5>
                                        <input
                                    type='text'
                                    className='input-text'
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur} 
                                    id='companyPostCode' 
                                    name='companyPostCode' ></input>
                                    {(formik.errors.companyPostCode && formik.touched.companyPostCode) ? <div className='error-msg'>{formik.errors.companyPostCode}</div> : null}
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '0.5rem',
                                        width: '100%'
                                    }}>
                                        <h5 className='blue'>Company phone number</h5>
                                        <input
                                    type='text'
                                    className='input-text'
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur} 
                                    id='companyPhoneNumber' 
                                    name='companyPhoneNumber' ></input>
                                    {(formik.errors.companyPhoneNumber && formik.touched.companyPhoneNumber) ? <div className='error-msg'>{formik.errors.companyPhoneNumber}</div> : null}
                                    </div>
                                </div>

                            
                        </div>

                        <Button
                        type='submit'
                            style={{
                                fontWeight: '500',
                                fontSize: '20px',
                                height: '63px'
                            }}>
                                Finish
                        </Button>
                    </div>
                </Form>)}
                }
            </Formik>
            {accountCreated === true ? (
                <Modal
                show={true}
                // onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                >
                    <Modal.Body>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '18px'
                        }}>
                            <span className='red' style={{ fontWeight: '600' }}>Signup Successfull</span>
                            <span style={{ textAlign: 'center' }}>Click OK to return to the login screen</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => navigate('/')}>OK</Button>
                    </Modal.Footer>
                </Modal>
            ) : null}
        </div>
        </>
    )
}

export default AccountDetails