import React,{useState} from 'react'
import ToggleButton from 'react-bootstrap/ToggleButton';


import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import logo from '../assets/svgs/PW-logo-white.svg'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { Button } from 'react-bootstrap';
// import Form from 'react-bootstrap/Form';
import ListingCard from '../components/ListingCard';
import Map from '../components/Map';
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { getProperties } from '../features/property/propertySlice'
import { Formik, Form, useFormik } from 'formik'
import { Form as BootForm } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
} from '@chakra-ui/react'

function SearchAndFilter() {

    const [center, setCenter] = useState({lat:56.130366,lng:-106.346771});
    const [location, setLocation] = useState('Canada');
    const [addr, setAddr] = useState(
        {address_components:[{long_name:"Canada",short_name:"CA",types:["country","political"]}]}
            );
    const [query, setQuery] = useState('');
    const navigate = useNavigate();
    let loc = useLocation()
    const { listings } = useSelector(state => state.property)

     const [formSearch, setFormSearch] = useState({sale_method:""});
     const [price_end, setPrice_end] = useState(500000);
     const [price_start, setPrice_start] = useState(0);
     const { state } = loc;
    
    //const { center } = state;
    //const { location } = state;
   // const { addr } = state;
   // const { query } = state;



    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            propertyType: []
        }
    })

    // const [propertyType, setPropertyType] = React.useState([])
    let propertyType = []

    const handleChange = e => {
        const { value, checked } = e.target;
        if (checked === true) {
            propertyType.push(value)
        } else {
            propertyType = propertyType.filter((arrVal) => arrVal !== value)
        }
         console.log("propertyType: ", propertyType)
    }

    const handleQuery = (key,e) => {
        
        if(key == 'sale_method'){
            setFormSearch({...formSearch,

                sales_method:e.target.value

                })
        }


        if(key == 'bedroom'){
            setFormSearch({...formSearch,

                bedroom:e.target.value

                })
        }

        if(key == 'floor'){
            setFormSearch({...formSearch,

                floor:e.target.value

                })
        }

        if(key == 'price_end'){
            setFormSearch({...formSearch,

                price_end:e.target.value

                })
            setPrice_end(e.target.value)
        }

        if(key == 'price_start'){
            setFormSearch({...formSearch,

                price_start:e.target.value

                })
            setPrice_start(e.target.value)
        }

    }

    const searchProperty = async () => {
        let queryString = query + '?property_type='
        for (var i=0; i<propertyType.length; i++) {
            queryString = queryString+propertyType[i]+','
        }
        const qlist =Object.entries(formSearch)
        
        await qlist.map(f => {
                queryString = queryString +'&' + f[0] + '=' + f[1] 
                

        })
        console.log(queryString)
        dispatch(getProperties(queryString))
    }

    return (
        <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
        }}>
       
            <div
            style={{
                display: 'flex',
                height: '77px',
                width: '100%',
                backgroundColor: '#1761B0',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <a onClick={() => navigate('/home')}><img src={logo} /></a>
            </div>
            <div style={{
                position: 'relative',
                // zIndex: '1',
                minHeight: '126px',
                boxShadow: '0px 3px 6px #00000029',
                width: '100%',
                paddingLeft: '10rem',
                paddingRight: '10rem',
                paddingTop: '13px',
                paddingBottom: '13px',
                display: 'flex',
                flexWrap: 'wrap',
                // justifyContent: 'center',
                alignItems: 'center',
                gap: '1rem'
            }}>
                <div 
                style={{
                    backgroundColor: 'white',
                    color: 'black',
                    border: '1px solid #1761B0',
                    borderRadius: '5px',
                    height: '50px',
                    fontSize: '14px',
                    width: '248px',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    justifyContent: 'space-between'
                }}>
                    {location}
                    <HiOutlineLocationMarker style={{ color: '#D2282D', height: '20px', width: '20px' }}/>
                </div>
                <ToggleButtonGroup type='checkbox'  >
                    <ToggleButton className='toggle-button-search' onChange={(e) =>  handleQuery('sale_method',e)} id="multifamily" value='SALE'>
                        For Sale
                    </ToggleButton>
                    <ToggleButton className='toggle-button-search' onChange={(e) => handleQuery('sale_method',e)} id="retail" value='AUCTION'>
                        For Auction
                    </ToggleButton>
                   
                </ToggleButtonGroup>
                <Dropdown>
                    <Dropdown.Toggle 
                    menuVariant="dark" 
                    id="dropdown-basic"
                    style={{
                        backgroundColor: 'white',
                        color: 'black',
                        borderColor: '#1761B0',
                        borderRadius: '5px',
                        height: '50px',
                        fontSize: '14px',
                        width: '164px'
                    }}>
                        Property Type
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{
                        borderRadius: '0',
                        marginTop: '0.5rem',
                        width: '338px',
                        paddingTop: '0',
                        paddingBottom: '0',
                        fontSize: '14px',
                        fontWeight: '400',
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            boxShadow: '0px 3px 6px #00000029'
                        }}>
                            <div className="search-dropdown-menu blue-bg">Please Select</div>
                            <BootForm>
                                <BootForm.Group controlId='propertyType' onChange={(e) => handleChange(e)}>
                                    {/* <BootForm.Check id='propertyType' label='All Property Types'/> */}
                                    <BootForm.Check id='houses' label='Houses' value='HOUSES'/>
                                    <BootForm.Check id='townhouse' label='Town House' value='Town House'/>
                                    <BootForm.Check id='condos' label='Condos' value='CONDOS'/>
                                    <BootForm.Check id='vacant land' label='Vacant Land' value='VACANT-LAND'/>   
                                    <BootForm.Check id='pre-construction' label='Pre-construction' value='PRE-CONSTRUCTION'/>                           
                                    <div className='search-dropdown-menu'
                                    style={{
                                        justifyContent: 'space-between',
                                        marginBottom: '1rem',
                                        paddingLeft: '1rem',
                                        paddingRight: '1rem',
                                        marginTop: '1rem'
                                    }}>
                                        
                                        
                                    </div>
                                </BootForm.Group>    
                            </BootForm>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
                
                <Dropdown>
                    <Dropdown.Toggle 
                    menuVariant="dark" 
                    id="dropdown-basic"
                    style={{
                        width: '369px',
                        backgroundColor: 'white',
                        color: 'black',
                        borderColor: '#1761B0',
                        borderRadius: '5px',
                        height: '50px',
                        fontSize: '14px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: '1rem'
                    }}>
                        Price
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{
                        borderRadius: '0',
                        marginTop: '0.5rem',
                        width: '338px',
                        paddingTop: '0',
                        paddingBottom: '0',
                        fontSize: '14px',
                        fontWeight: '400',
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            boxShadow: '0px 3px 6px #00000029'
                        }}>
                            <div className="search-dropdown-menu blue-bg">Price Range</div>
                            <div className='search-dropdown-menu'
                            style={{
                                justifyContent: 'space-between',
                                marginBottom: '1rem',
                                paddingLeft: '1rem',
                                paddingRight: '1rem',
                                marginTop: '1rem'
                            }}>
                            <div>
                            Min Price
                            <input name="price_start" onChange={(e) => handleQuery('price_start',e)} className="form-control" id="start_price" type="text" />
                            </div>
                            <div>

                             Max Price
                            <input name="price_end" onChange={(e) => handleQuery('price_end',e)} className="form-control" id="price_end" type="text" />

                            </div>
                                
                            </div>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                    <Dropdown.Toggle 
                    menuVariant="dark" 
                    id="dropdown-basic"
                    style={{
                        width: '369px',
                        backgroundColor: 'white',
                        color: 'black',
                        borderColor: '#1761B0',
                        borderRadius: '5px',
                        height: '50px',
                        fontSize: '14px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: '1rem'
                    }}>
                        Radius
                    </Dropdown.Toggle>

                    {/* <Dropdown.Menu>

                    </Dropdown.Menu> */}
                </Dropdown>
                <Dropdown>
                    <Dropdown.Toggle 
                    menuVariant="dark" 
                    id="dropdown-basic"
                    style={{
                        width: '271px',
                        backgroundColor: 'white',
                        color: 'black',
                        borderColor: '#1761B0',
                        borderRadius: '5px',
                        height: '50px',
                        fontSize: '14px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: '1rem'
                    }}>
                        More
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{
                        borderRadius: '0',
                        marginTop: '0.5rem',
                        width: '338px',
                        paddingTop: '0',
                        paddingBottom: '0',
                        fontSize: '14px',
                        fontWeight: '400',
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            boxShadow: '0px 3px 6px #00000029'
                        }}>
                            <div className="search-dropdown-menu blue-bg">More Filters</div>
                            <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '1rem',
                                    padding: '1rem',
                                    fontSize: '14px'
                            }}>
                                <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '0.75rem'
                                }}>
                                    <p style={{ marginBottom: '0' }}>Number of Bedrooms</p>
                                    <ToggleButtonGroup type='radio' name='bedrooms'>
                                        <ToggleButton onChange={(e) => handleQuery('bedroom',e)} className='toggle-button-search' id="any" value='any'>
                                            Any
                                        </ToggleButton>
                                        <ToggleButton onChange={(e) => handleQuery('bedroom',e)} className='toggle-button-search' id="1" value='1'>
                                            1
                                        </ToggleButton>
                                        <ToggleButton onChange={(e) => handleQuery('bedroom',e)} className='toggle-button-search' id="2" value='2'>
                                            2
                                        </ToggleButton>
                                        <ToggleButton onChange={(e) => handleQuery('bedroom',e)} className='toggle-button-search' id="3" value='3'>
                                            3
                                        </ToggleButton>
                                        <ToggleButton onChange={(e) => handleQuery('bedroom',e)} className='toggle-button-search' id="4" value='4'>
                                            4
                                        </ToggleButton>
                                        <ToggleButton onChange={(e) => handleQuery('bedroom',e)} className='toggle-button-search' id="5" value='5'>
                                            5
                                        </ToggleButton>
                                        <ToggleButton onChange={(e) => handleQuery('bedroom',e)} className='toggle-button-search' id="6+" value='6+'>
                                            6+
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                                <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '0.75rem'
                                }}>
                                    <p style={{ marginBottom: '0' }}>Number of Floors</p>
                                    <ToggleButtonGroup type='radio' name='floors'>
                                        <ToggleButton className='toggle-button-search' onChange={(e) => handleQuery('floor',e)} id="any" value='any'>
                                            Any
                                        </ToggleButton>
                                        <ToggleButton className='toggle-button-search' onChange={(e) => handleQuery('floor',e)} id="1-floor" value='1'>
                                            1
                                        </ToggleButton>
                                        <ToggleButton className='toggle-button-search' onChange={(e) => handleQuery('floor',e)} id="2-floor" value='2'>
                                            2
                                        </ToggleButton>
                                        <ToggleButton className='toggle-button-search' onChange={(e) => handleQuery('floor',e)} id="3-floor" value='3'>
                                            3
                                        </ToggleButton>
                                        <ToggleButton className='toggle-button-search' onChange={(e) => handleQuery('floor',e)} id="4-floor" value='4'>
                                            4
                                        </ToggleButton>
                                        <ToggleButton className='toggle-button-search' onChange={(e) => handleQuery('floor',e)} id="5-floor" value='5'>
                                            5
                                        </ToggleButton>
                                        <ToggleButton className='toggle-button-search' onChange={(e) => handleQuery('floor',e)} id="6+floor" value='6+'>
                                            6+
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </div>                                
                            </div>

                            <div className='search-dropdown-menu'
                            style={{
                                justifyContent: 'space-between',
                                marginBottom: '1rem',
                                paddingLeft: '1rem',
                                paddingRight: '1rem',
                                marginTop: '1rem'
                            }}>
                                
                            </div>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>


                <Button 
                                        style={{ fontWeight: '500', fontSize: '14px', backgroundColor: '#1761B0', height: '42px', width: '145px' }}
                                        onClick={() => searchProperty()}>Search</Button>
            </div>
            <div style={{
                height: '100vh',
                width: '100%',
                display: 'flex',
                flexGrow: '1',
            }}>
                <div style={{
                    width: '50%',
                    
                    overflow: 'hidden'
                }}>
                    <Map center={center} properties={listings} addr={addr}/>
                </div>
                <div style={{
                    width: '50%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '2rem'
                }}>
                    <div>
                        <h3 style={{ fontSize: '25px', fontWeight: '600' }}>Properties for Sale in {location}</h3>
                        <p style={{ fontSize: '18px' }}>{listings?.data?.length} Results</p>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '2rem',
                        overflowY: 'scroll'
                    }}>
                        {listings?.data?.map(result => <ListingCard listingDetails={result} />)}
                        {/* {console.log("listings: ", listings?.data?.map(listing => listing?.location?.coordinates))} */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchAndFilter