import React,{useState} from 'react'
import Button from 'react-bootstrap/Button';
import AuthPageSideImage from '../../components/AuthPageSideImage';
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { login,reset } from '../../features/auth/authSlice'
import { Formik, Form, useFormik } from 'formik'
import Loader from "../../components/Loader";
function SignIn() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [spinner, setSpinner] = useState(false)
    const { user, isLoading,isError,message } = useSelector(( state ) => state.auth)

    React.useEffect(() => {
        dispatch(reset())
        if (user?.code === 200) {
            navigate('/home')
        }
    }, [user])

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: async (values) => {
            setSpinner(true)
            dispatch(login(values))
            setSpinner(false)
        }
    })


   

    return (
        <>
        {spinner && <Loader /> }
        <div
        style={{
            display: 'flex',
            height: '100vh',
            flexWrap: 'wrap',
        }}>
           
            <AuthPageSideImage />
            <div
            className='login-section'
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '3rem'
            }}>
                <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1.5rem'
                }}>
                    <h4 className='blue'>Log In</h4>
                    <p>Please log in to access Property Ways</p>
                </div>
                <Formik
                initialValues={formik.initialValues}>
                    {() => 
                        {
                        return(
                        <Form
                        onSubmit={formik.handleSubmit}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '2.5rem'
                        }}>
                            <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem'
                            }}>
                                <h5 className='blue'>Email Address</h5>
                                <input id="email" name="email" type='text' onChange={formik.handleChange} onBlur={formik.handleBlur} className='input-text'></input>
                            </div>
                            <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem'
                            }}>
                                <h5 className='blue'>Password</h5>
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} id='password' name='password' type='password' className='input-text'></input>
                                <p 
                                className='blue' 
                                style={{ textAlign: 'right', fontSize: '14px', fontWeight: '550', cursor: 'pointer' }}
                                onClick={() => navigate('/lost-password')}>Forgot Password?</p>
                            </div>
                            <div>

                            {isError && <div style={{color:'red',fontWeight:'bold',padding:'5px'}}>Invalid Credential</div>}
                                <Button 
                                className='primary'
                                type='submit'
                                style={{
                                    width: '14.5rem',
                                    height: '4rem',
                                    fontSize: '20px',
                                    width: '100%'
                                }}>Log In</Button>
                            </div>
                        </Form>)}
                    }
                </Formik>
                <div
                style={{
                    textAlign: 'center',
                    marginBottom: '5rem'
                }}>
                    <p>Don't have an account? <Link to='/signup'>Sign Up</Link></p>
                </div>
            </div>
        </div>
        </>
    )
}

export default SignIn