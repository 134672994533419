import React,{useEffect,useState} from 'react';
import { Button,useDisclosure } from '@chakra-ui/react'
import * as Yup from 'yup';
import { Formik, Form, useFormik } from 'formik'

import Loader from "../../../components/Loader";
import { toast } from "react-toastify";
import { fetchWrapper } from '../../../helpers';

function Edit({loadList,rs,meCloseEdit}) {
	const [spinner, setSpinner] = useState(false);
	const formik = useFormik({
        initialValues: {
            name: rs.name,
            userId:rs.userId,
        },
         validationSchema: Yup.object({
            name: Yup.string().required(`Required`)
        }),
         onSubmit: async (values) => {
         	
         		setSpinner(true)
         		let  data = values
         		
		     	const url = `${process.env.REACT_APP_BASE_URL}/country/update/${rs.id}`
	  		 	await fetchWrapper.put(url,data)
		  		loadList()
		  		meCloseEdit()
		  		setSpinner(false)
        }      
    })
	return(
		<>
		{spinner && <Loader /> }
		 <Formik
                initialValues={{ // Directly provide the initialValues object
		        name: rs.name,
		        userId: rs.userId,
		    }} validationSchema={formik.validationSchema}>
                    {() => 
                        {
                        return(
                        <Form
                        onSubmit={formik.handleSubmit}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '2.5rem'
                        }}>
                            <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem'
                            }}>
                                <h5 className='blue'>Name</h5>
                                <input id="name" name="name" type='text'  value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} className='input-text'></input>
                                {formik.touched.name && formik.errors.name ? (
                                    <div style={{ color: 'red', fontWeight: 'bold', padding: '5px' }}>{formik.errors.name}</div>
                                ) : null}
                            </div>
                            
                            <div>

                                <Button 
                                className="primary"
                                type='submit'
                                style={{
                                    width: '14.5rem',
                                    height: '4rem',
                                    fontSize: '20px',
                                    width: '100%'
                                }}>Update</Button>
                            </div>
                        </Form>)}
                    }
                </Formik>
		</>
	)
}

export default Edit