import React from 'react'
import { logout } from '../features/auth/authSlice'
import anon from '../assets/images/kisspng-user-profile-computer-icons-image-default-daniela-klasn-martin-societe-generale-securiti-5cca4fa5e3c847.179087381556762533933.png'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { BsSearch } from "react-icons/bs";

function GreyPage(props) {
    const { heading } = props;
    const { user } = useSelector((state) => state.auth)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (user === null){
            navigate('/')
        }
    }, [user])

    return (
        <div
        style={{
            display: 'flex',
            gap: '5rem',
            width: '100%',
            height: '45px',
            alignItems: 'center',
            justifyContent: 'space-between',
        }}>
            <span style={{ fontSize: '22px', margin: 'none', padding: 'none', fontWeight: '500' }}>{heading}</span>
            <div style={{
                display: 'flex',
                height: '45px',
                alignItems: 'center',
            }}>
            <div className="main_search_field">
              <BsSearch />
              <input type='text' placeholder="Search" />
            </div>

            </div>
            <div style={{
                display: 'flex', gap: '1rem', alignItems: 'center'
            }}>
                <Dropdown className='drop' style={{ backgroundColor: 'transparent'}}>
                    <Dropdown.Toggle variant='contained' className='drop' style={{ height: '4rem', color: 'black', border: 'none' , backgroundColor: 'transparent',display: 'flex', gap: '1rem', alignItems: 'center' }} id="dropdown-basic">
                        <img src={anon} style={{ height: '60%', width: '60%', paddingBottom: '0.25rem'}} alt='anon' />
                        <span style={{margin: 'none', padding: 'none', fontWeight: '500'}}>
                        {user?.data?.firstName} {user?.data?.lastName}
                        </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ marginLeft: '2rem' }}>
                        <Dropdown.Item 
                        style={{ fontWeight: '500', color: '#D2282D' }} 
                        onClick={() => {
                            dispatch(logout())
                        }}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
}

export default GreyPage