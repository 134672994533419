import React from "react";

function CheckBoxGen(props) {
    const { label, options, id, onChange, onBlur, limit, required,value,formik,cKey } = props;
    const [selectedOption, setSelectedOption] = React.useState(null)

    return (
        <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1.5rem'
        }}>
        <h5>{label}{required === true ? (<span className='red'>*</span>) : null}</h5>
        <div
        style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '1.5rem'
        }}>
        {options.map(option => {
              
            return(
                <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                    width: '20rem'
                }}>
                    <label class="container">{ option.label }

                    <input 
                        
                        type='checkbox' 
                        disabled={(selectedOption !== null && selectedOption !== option.label.toLowerCase()) ? true : false}
                        value={value}
                        defaultChecked={(option.id == value ) ? true : false}

                        id={id}
                        style={{
                            height: '18px',
                            width: '18px',
                            border: 'none',
                            outline: 'none',
                        }}
                        onChange={onChange}
                        onBlur={onBlur}
                        onClick={() => {
                            if (limit === 1){
                                if (selectedOption !== option.label.toLowerCase()){
                                    setSelectedOption(option.label.toLowerCase())
                                    
                                    
                                    
                                } else {
                                    setSelectedOption(null)
                                }
                            } 

                        }}
                        />
                    <span 
                    class="checkmark"
                    style={{
                        backgroundColor: selectedOption !== null && selectedOption !== option.label.toLowerCase() ? '#292D2B26' : null,
                        borderColor: selectedOption !== null && selectedOption !== option.label.toLowerCase() ? '#292D2B26' : null
                        }}></span>
                    </label>
                    
                </div>
            )
        })}
        </div>

        </div>
    )
}

export default CheckBoxGen