import './Loading.css'
const Loader=()=>{
  return(
    <div id="page-loading" styles="{{display:none}}">
    
      <div id="loader">
        
      </div>
    </div>
  )
}
export default Loader;