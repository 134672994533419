import React,{useState} from 'react'
import HeaderTemplate from '../components/HeaderTemplate'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import SelectComponent from '../components/SelectProComponent'
import TextInputComponent from '../components/TextInputComponent'
import CheckBoxGen from '../components/CheckBoxGen'
import addPropertySchema from '../utils/validationSchema/addPropertySchema';
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router';
import { Formik, Form, useFormik } from 'formik'

import MapPicker from 'react-google-map-picker'


import VacantLand from './PropertyForm/VacantLand'
import HouseSaleMN from './PropertyForm/HouseSaleMN'
import HouseSaleMY from './PropertyForm/HouseSaleMY'
import CondosRent from './PropertyForm/CondosRent'
import HouseRent from './PropertyForm/HouseRent'
import PreConHouse from './PropertyForm/PreConHouse'
import PreConCondos from './PropertyForm/PreConCondos'
import { fetchWrapper } from '../helpers';

// Forms



const DefaultZoom = 5;

function EditProperty(props) {

  //const { saleMethod } = useParams();

  const locationVal = useLocation()
  const { state } = locationVal;
  const DefaultLocation = JSON.parse(state.location);
  
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])

     React.useEffect(() => {
       
        loadState();
        loadCity(0)
    }, [])

     const loadState = async() => {

        const url = `${process.env.REACT_APP_BASE_URL}/master/state-list`;
        const data  = await fetchWrapper.get(url)
        setStateList(data);
    }

    const loadCity = async(state_id) => {

         const url = `${process.env.REACT_APP_BASE_URL}/master/city-list?state_id=${state_id}`;
        const data  = await fetchWrapper.get(url)
        setCityList(data);
    }

  function handleChangeLocation (lat, lng){

    setLocation({lat:lat, long:lng});
    
    formik.values['property']['location'] = {lat:lat, lng:lng}


    
  }
  
  function handleChangeZoom (newZoom){
    setZoom(newZoom);
  }

  function handleResetLocation(){
    setDefaultLocation({ ... DefaultLocation});
    setZoom(DefaultZoom);
  }

  const propertyDetailValue = JSON.parse(state.propertyDetail)
  
  const formik = useFormik({
    initialValues: {
      propertyId:state.id,
      property:{
        
        saleMethod: state.saleMethod,
        saleType:state.saleType,
        maintenance:'Yes',
        auctionTime:state.auctionDay,
        title:state.title,
        auctionStartTime:state.auctionStartTime,
        propertyType:state.propertyType,
        price: state.price,
        mode:'edit',
        location:location,
        propertyDescription:state.propertyDescription,
        signatureFirst:state.signatureFirst,
        signatureSecond:state.signatureSecond,
        signatureFirstDate:state.signatureFirstDate,
        signatureSecondDate: state.signatureFirstDate,
        propertyDetail: {
           
            country:propertyDetailValue.country,
            state:propertyDetailValue.state,
            city:propertyDetailValue.city,
            address: propertyDetailValue.address,
            ownerName:propertyDetailValue.ownerName,
            
            zoning:propertyDetailValue.zoning,
            propertySize:propertyDetailValue.propertySize,
            waterFront:propertyDetailValue.waterFront,
            lotFrontage: propertyDetailValue.lotFrontage,
            lotDepth: propertyDetailValue.lotDepth,
            waterSupplyTypes:propertyDetailValue.waterSupplyTypes,
            sewers:propertyDetailValue.sewers,
            utilities:propertyDetailValue.utilities,
            possesionDate:propertyDetailValue.possesionDate,
            rentalType:propertyDetailValue.rentalType,
            fronting:propertyDetailValue.fronting,
            pool:propertyDetailValue.pool,
            legalName:propertyDetailValue.legalName,
            propertySize:propertyDetailValue.propertySize,
            legalDescription:propertyDetailValue.legalDescription,
            bedroom:propertyDetailValue.bedroom,
            kitchen:propertyDetailValue.kitchen,
            washroom:propertyDetailValue.washroom,
            squareFeet:propertyDetailValue.squareFeet,
            basement:propertyDetailValue.basement,
            parking:propertyDetailValue.parking,
            garageType:propertyDetailValue.garageType,
            garageParking:propertyDetailValue.garageParking,
            heat:propertyDetailValue.heat,
            heatType:propertyDetailValue.heatType,
            airCondition:propertyDetailValue.airCondition,
            propertyTax:propertyDetailValue.propertyTax,
            pool:propertyDetailValue.pool,
            firePlace:propertyDetailValue.firePlace,
            amenities:propertyDetailValue.amenities,
            bathroom:propertyDetailValue.bathroom,
            rentalType:propertyDetailValue.rentalType,
            streetNo:propertyDetailValue.streetNo,
            street:propertyDetailValue.street,
            dateAvailable:propertyDetailValue.dateAvailable,
            leaseLength:propertyDetailValue.leaseLength,
            parkingSpot:propertyDetailValue.parkingSpot,
            parkingType:propertyDetailValue.parkingType,
            laundryType:propertyDetailValue.laundryType,
            petFriendly:propertyDetailValue.petFriendly,
            amenities:propertyDetailValue.amenities,
            unitNumber:propertyDetailValue.unitNumber,
            builder:propertyDetailValue.builder,
            developer:propertyDetailValue.developer,
            storey:propertyDetailValue.storey,
            condo_unit:propertyDetailValue.condo_unit,
            projectPhase:propertyDetailValue.projectPhase,
            approxCompletion:propertyDetailValue.approxCompletion,
            approxMaintenanceFee:propertyDetailValue.approxMaintenanceFee,
            neighbourhood:propertyDetailValue.neighbourhood,
            noOfHome:propertyDetailValue.noOfHome,
            noOfPhase:propertyDetailValue.noOfPhase,
            propertyStyle:propertyDetailValue.propertyStyle,
            propertyType:propertyDetailValue.propertyType,
            nameOfProject:propertyDetailValue.nameOfProject,

          },
      },
      
      propertyFile: state.PropertyFiles,
      
      

    },
    validationSchema: addPropertySchema
  })

  const [rooms, setRooms] = React.useState([])


  const dispatch = useDispatch()

  



  const PropertyCompletion = [{value:"2023",text:"2023"},{value:"2024",text:"2024"},
            ,{value:"2024",text:"2024"}
            ,{value:"2024",text:"2024"}
            ,{value:"2025",text:"2025"}
            ,{value:"2026",text:"2026"}
            ,{value:"2027",text:"2027"}
            ,{value:"2028",text:"2028"}
            ,{value:"2029",text:"2029"}
            ,{value:"2030",text:"2030"}
            ,{value:"2031",text:"2031"}
            ,{value:"2032",text:"2032"}
            ,{value:"2033",text:"2033"}

          ]


      const ProjectPhase = [{value:"1",text:"1"},{value:"2",text:"2"},
            ,{value:"3",text:"3"}
            ,{value:"4",text:"4"}
            ,{value:"5",text:"5"}
            ,{value:"6",text:"6"}
            ,{value:"7",text:"7"}
            ,{value:"8",text:"8"}
            ,{value:"9",text:"9"}
            

          ]

      const { RentalType,Bedroom,Zoning,Fronting,
        Bathroom,SquareFeet,LeaseLength,ParkingSpot,ParkingType,LaundryType,Amenities,
        Kitchen,Washroom,Basement,Parking,GarageType,GarageParking,Heat,HeatType,PropertyTax,
        Pool,FirePlace,PropertySize,masterData,PropertyStyle,PropertyTypeCon


       } = useSelector((state) => state.master)
   

    const AirCondition = [{value:"Yes",text:"Yes"}]
   
    const PetFriendly = [{value:"Yes",text:"Yes"},{value:"No",text:"No"}]

    const City = cityList;
    const State = stateList;
    const Country = [{value:1, text:'Canada'}];
    
 
  const filterData = (type) => {
      return  masterData.filter(rs => rs.master_type == type);
  }

  const initializeArray = noOfRooms => {
    const array = [];
    for (var i=0; i<noOfRooms; i++) {
      array.push(
        {
          level: "string",
          room: 1,
          length: 1.1,
          width: 1.1,
          descriptionOne: "string",
          descriptionTwo: "string",
          descriptionThree: "string"
        }
      )
    }
    formik.setFieldValue('propertyRoomDetail', array)
    return(array)
  }


  React.useEffect(() => {
        //console.log(message + 'dd')
        //dispatch(getMasterList('RentalType'))

    }, [])

 

  React.useEffect(() => {
    for (var i=0; i<formik.values.length; i++){
      for (var j=0; j<formik.values[i].length; j++){
        console.log(formik.values[i][j], typeof(formik.values[i][j]))
      }
    }
  }, [formik.values])

  const handleError = id => {
    if (formik.errors?.propertyDetail?.[id] !== undefined) {
      return formik.errors?.propertyDetail?.[id]
    } else {
      return false
    }
  }




  return (
    <>


        <HeaderTemplate nextScreen='/add-property-images'   state={formik.values}/>


        <Formik
          initialValues={formik.initialValues}
          
        >
          {() => 
            {//console.log("formik: ", formik)
            return (<Form 
            className='grey-background'
            style={{
              paddingBottom: '10rem'
            }}
            onSubmit={formik.handleSubmit}>

                <div id='property-details' className='add-section'>

                  
                  <h4 className='blue'>Property Details</h4>
                  <p>Please enter your listing details</p>
                  <div
                  style={{
                    display: 'flex',
                    gap: '2rem'
                  }}>
                  
                   {formik.values.property['saleMethod'] === 'SALE' ? <SelectComponent required={true} value={state.saleType}  formik={formik} onChange={formik.handleChange} onBlur={formik.handleBlur} id='property.saleType' label="What are you selling?" options={[{value: 'SALE', text: 'Sale'}, {value: 'RENT', text: 'Rent'}]} /> : null}
                   
                    {formik.values.property['saleMethod'] === 'AUCTION' && formik.values.property['saleType'] === 'SALE' ? <SelectComponent value={formik.values.property['auctionTime']} required={true} formik={formik} onChange={formik.handleChange} onBlur={formik.handleBlur} id='property.auctionTime' label="Auction Duration (in days)" options={[{value: '', text: ''}, {value: '5', text: '5'}, {value: '7', text: '7'}, {value: '14', text: '14'}, {value: '30', text: '30'}]} /> : null}
                    

                    {formik.values.property['saleType'] === 'SALE' ? <SelectComponent required={true} onChange={formik.handleChange} onBlur={formik.handleBlur} value={state.propertyType} id='property.propertyType' label="Property type" options={[{value: '', text: ''}, {value: 'HOUSES', text: 'House'}, {value: 'townhouse', text: 'Town House'},{value: 'CONDOS', text: 'Condo'}, {value: 'VACANT-LAND', text: 'Vacant Land'},{value: 'pre-construction-house', text: 'pre-Construction/Assignment (House)'},{value: 'pre-construction-condos', text: 'pre-Construction/Assignment (CONDOS)'}]} /> : <SelectComponent required={true} onChange={formik.handleChange} onBlur={formik.handleBlur} value={state.propertyType} id='property.propertyType' label="Property type" options={[{value: '', text: ''}, {value: 'HOUSES', text: 'House'}, {value: 'BASEMENT', text: 'Basement'},{value: 'CONDOS', text: 'Condo'}, {value: 'ROOM-ONLY', text: 'Room Only'},{value: 'VACANT-LAND', text: 'Vacant Land'},{value: 'OTHER', text: 'Other'}]} />}
                  
                    {formik.values.property['propertyType'] === 'townhouse' && formik.values.property['saleType'] === 'SALE' ? <SelectComponent required={true} value={state.maintenance} id='property.maintenance' formik={formik} onChange={formik.handleChange} onBlur={formik.handleBlur} id='property.maintenance' label="Is there any maintenance fee?" options={[{value: 'Yes', text: 'Yes'}, {value: 'No', text: 'No'}]} /> : null}
                  </div>
                </div>

                <div id='property-location' className='add-section'>


                <div className='inputs-area'>

                          
                
                          <TextInputComponent required={true} value={formik.values.property['title']} onChange={formik.handleChange} onBlur={formik.handleBlur} label='Property Title' id='property.title' />
                
                          <TextInputComponent required={true} value={formik.values.property.propertyDetail['ownerName']} onChange={formik.handleChange} onBlur={formik.handleBlur} label='Owner Name' id='property.propertyDetail.ownerName' />
                          <TextInputComponent required={true} value={formik.values.property['price']} onChange={formik.handleChange} onBlur={formik.handleBlur} label='Price' id='property.price' />
                </div>

                <h4 className='blue'>Property Location</h4>
                  <MapPicker defaultLocation={defaultLocation}
    zoom={zoom}
    mapTypeId="roadmap"
    style={{height:'300px'}}
    onChangeLocation={handleChangeLocation} 
    onChangeZoom={handleChangeZoom}
    apiKey='AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8'/>

                  <h4 className='blue'>Property Details</h4>
                  {formik.values.property['propertyType'] === 'VACANT-LAND' || formik.values.property['propertyType'] === 'ROOM-ONLY' || formik.values.property['propertyType'] === 'BASEMENT' || formik.values.property['propertyType'] === 'OTHER'   ? (
                      <>

                       <VacantLand 
                       SelectComponent={SelectComponent} Country={Country} State={State} formik={formik}
                        filterData={filterData}
                        TextInputComponent={TextInputComponent}
                        City={City}
                        CheckBoxGen={CheckBoxGen}
                        LeaseLength={LeaseLength}
                        ParkingSpot={ParkingSpot}
                        ParkingType={ParkingType}
                        LaundryType={LaundryType}
                        PetFriendly={PetFriendly}
                        Amenities={Amenities}
                        loadCity={loadCity}
                       />

                      </>
                  ) : null}


                  {formik.values.property['propertyType'] === 'HOUSES' && formik.values.property['saleType'] === 'RENT' ? (
                      <>
                         

                          <HouseRent
                        SelectComponent={SelectComponent} Country={Country} State={State} formik={formik}
                        filterData={filterData}
                        TextInputComponent={TextInputComponent}
                        City={City}
                        CheckBoxGen={CheckBoxGen}
                        LeaseLength={LeaseLength}
                        ParkingSpot={ParkingSpot}
                        ParkingType={ParkingType}
                        LaundryType={LaundryType}
                        PetFriendly={PetFriendly}
                        Amenities={Amenities}
                         loadCity={loadCity}
                       />

                        
                        
                      </>

                  ) : null}


                  {((formik.values.property['propertyType'] === 'HOUSES' && formik.values.property['saleType'] === 'SALE') ||  (formik.values.property['propertyType'] === 'townhouse' && formik.values.property['maintenance'] == 'No')) ? (
                      <>
                         <HouseSaleMN 
                        SelectComponent={SelectComponent} Country={Country} State={State} formik={formik}
                        filterData={filterData}
                        TextInputComponent={TextInputComponent}
                        City={City}
                        CheckBoxGen={CheckBoxGen}
                        LeaseLength={LeaseLength}
                        ParkingSpot={ParkingSpot}
                        ParkingType={ParkingType}
                        LaundryType={LaundryType}
                        PetFriendly={PetFriendly}
                        Amenities={Amenities}
                         loadCity={loadCity}
                       />
                      </>

                  ) : null}

                {((formik.values.property['propertyType'] === 'CONDOS' && formik.values.property['saleType'] === 'SALE') || (formik.values.property['propertyType'] === 'townhouse' && formik.values.property['maintenance'] == 'Yes')) ? (
                      <>
                         <HouseSaleMY
                        SelectComponent={SelectComponent} Country={Country} State={State} formik={formik}
                        filterData={filterData}
                        TextInputComponent={TextInputComponent}
                        City={City}
                        CheckBoxGen={CheckBoxGen}
                        LeaseLength={LeaseLength}
                        ParkingSpot={ParkingSpot}
                        ParkingType={ParkingType}
                        LaundryType={LaundryType}
                        PetFriendly={PetFriendly}
                        Amenities={Amenities}
                         loadCity={loadCity}

                       />
                      </>

                  ) : null}


                  {formik.values.property['propertyType'] === 'CONDOS' && formik.values.property['saleType'] === 'RENT' ? (
                      <>
                         

                        
                        <CondosRent
                        SelectComponent={SelectComponent} Country={Country} State={State} formik={formik}
                        filterData={filterData}
                        TextInputComponent={TextInputComponent}
                        City={City}
                        CheckBoxGen={CheckBoxGen}
                        LeaseLength={LeaseLength}
                        ParkingSpot={ParkingSpot}
                        ParkingType={ParkingType}
                        LaundryType={LaundryType}
                        PetFriendly={PetFriendly}
                        Amenities={Amenities}
                         loadCity={loadCity}
                       />
                        
                        
                      </>

                  ) : null}



                  {formik.values.property['propertyType'] === 'pre-construction-house' && formik.values.property['saleType'] === 'SALE' ? (
                      <>
                         

                          <PreConHouse
                        SelectComponent={SelectComponent} Country={Country} State={State} formik={formik}
                        filterData={filterData}
                        TextInputComponent={TextInputComponent}
                        City={City}
                        CheckBoxGen={CheckBoxGen}
                        LeaseLength={LeaseLength}
                        ParkingSpot={ParkingSpot}
                        ParkingType={ParkingType}
                        LaundryType={LaundryType}
                        PetFriendly={PetFriendly}
                        Amenities={Amenities}
                        PropertyStyle={PropertyStyle}
                        PropertyTypeCon={PropertyTypeCon}
                        PropertyCompletion={PropertyCompletion}
                         loadCity={loadCity}
                       />

                        
                        
                      </>

                  ) : null}


                  {formik.values.property['propertyType'] === 'pre-construction-condos' && formik.values.property['saleType'] === 'SALE' ? (
                      <>
                         

                          <PreConCondos
                        SelectComponent={SelectComponent} Country={Country} State={State} formik={formik}
                        filterData={filterData}
                        TextInputComponent={TextInputComponent}
                        City={City}
                        CheckBoxGen={CheckBoxGen}
                        LeaseLength={LeaseLength}
                        ParkingSpot={ParkingSpot}
                        ParkingType={ParkingType}
                        LaundryType={LaundryType}
                        PetFriendly={PetFriendly}
                        Amenities={Amenities}
                        PropertyStyle={PropertyStyle}
                        PropertyTypeCon={PropertyTypeCon}
                        PropertyCompletion={PropertyCompletion}
                        ProjectPhase={ProjectPhase}
                         loadCity={loadCity}
                       />

                        
                        
                      </>

                  ) : null}
                  
                </div>
               
                <div id='property-description' className='add-section'>
                  <h4 className='blue'>Property Description</h4>
                  <textarea onBlur={formik.handleBlur} className='input-text' id='property.propertyDescription' style={{ width: '90%', height: '143px' }} onChange={formik.handleChange} >{formik.values.property['propertyDescription']}</textarea>
                  
                  <div style={{
                    display: 'flex', gap: '1rem', width: '90%'
                  }}>
                    <TextInputComponent onChange={formik.handleChange} onBlur={formik.handleBlur} label='Signature 1 (please type your name)' value={formik.values.property['signatureFirst']} id='property.signatureFirst' style={{ width: '75%' }}/>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', width: '25%' }}>
                      <h5 style={{ color: '#848484', fontWeight: '400' }}>Date {formik.values.property['signatureFirstDate']}</h5>
                     
                      <input onChange={formik.handleChange} onBlur={formik.handleBlur} type='date' className='input-text' label='Date' id='property.signatureFirstDate' value={formik.values.property['signatureFirstDate']}  style={{ width: '100%' }} />
                    </div>
                    
                  </div>
                  <div style={{
                    display: 'flex', gap: '1rem', width: '90%'
                  }}>
                    <TextInputComponent onChange={formik.handleChange} onBlur={formik.handleBlur} label='Signature 2 (please type your name)' value={formik.values.property['signatureSecond']} id='property.signatureSecond' style={{ width: '75%' }}/>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', width: '25%' }}>
                      <h5 style={{ color: '#848484', fontWeight: '400' }}>Date</h5>
                      <input onChange={formik.handleChange} onBlur={formik.handleBlur} type='date' className='input-text' label='Date' value={formik.values.property['signatureSecondDate']} id='property.signatureSecondDate' style={{ width: '100%' }} />
                    </div>
                  </div>
                </div>
                <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                    width: '100%'
                }}>
                    <div
                    style={{
                        // border: '2px solid #1761B0',
                        padding: '0',
                        height: '18px',
                        // width: '90%',
                        borderRadius: '2px'
                    }}>
                        <input 
                        type='checkbox' 
                        value='acknowledged' 
                        id='propertyDetail.isInfoCorrect'
                        style={{
                            height: '18px',
                            width: '18px',
                            opacity: '100'
                        }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onClick={() => console.log('clciked!')}
                        />                        
                    </div>

                    <label for='propertyDetail.isInfoCorrect'>I hereby acknowledge that the information provided is true</label>
                </div>
            </Form>)}
          }          
        </Formik>

    </>
  )
}

export default EditProperty