import React from 'react'
import GreyPage from '../components/GreyPage'

import { GoogleMap, useJsApiLoader, MarkerF, InfoWindow, MarkerClustererF } from '@react-google-maps/api';
import { useNavigate } from "react-router-dom";
import { useState } from 'react'
import house from '../assets/images/house1.jpg'
import { fetchWrapper } from '../helpers';
function MapView() {
  const navigate = useNavigate()
  const [activeMarker, setActiveMarker] = useState(null);
  const [list, setList] = useState(null);
const containerStyle = {
  width: '100%',
  height: '800px'
};

const center = {
  lat: 10,
  lng: 106
};
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "YOUR_API_KEY"
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])


  React.useEffect(() => {
        
        loadProperty()
    }, [])

  const loadProperty = async () => {

     const url = `${process.env.REACT_APP_BASE_URL}/property/property-list`;
     const token = {}
     const data = await fetchWrapper.get(url);
     setList(data.data)
  }

  const convertPrice = price => {
        console.log("price: ", price)
        const result = (price).toLocaleString('en-US', { 
            style: 'currency', 
            currency: 'USD',
            minimumFractionDigits: 0
        });
        return result
    }

    const handleActiveMarker = (marker) => {
      if (marker === activeMarker) {
        return;
      }
      setActiveMarker(marker);
    };

  const properties = [];
    return (
        <div style={{width: '100%'}}>
          <div 
          className='grey-background-home' 
          style={{ 
              width: '79%', 
              height: '100vh', 
              marginLeft: '21%', 
              paddingRight: '1rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '1.5rem',
              paddingLeft: '2%',
          }}>
            <GreyPage heading='MapView' />


            <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={4}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <MarkerClustererF averageCenter={true}>
            {clusterer => 
            list?.data?.map(property => { 
                
              return(
                <MarkerF 
                    position={{ lat: JSON.parse(property?.location)?.lat, lng: JSON.parse(property?.location)?.lng}}
                    onClick={() => handleActiveMarker(property)}
                    clusterer={clusterer}
                >
                    {activeMarker === property ? (
                        <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                            <div
                            onClick={() => navigate(`/property/${property.id}`, {state: property})}
                            style={{
                                display: 'flex',
                                height: '90px',
                                width: '196px',
                                cursor: 'pointer'
                            }}>
                               <div style={{
                                width: '50%',
                                backgroundColor: 'black'
                               }}>
                                <img src={property?.PropertyFiles[0]?.fileURL || house} style={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'contain'
                                }}/>
                                </div> 
                               <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '0.5rem',
                                width: '50%',
                                padding: '0.5rem',
                                justifyContent: 'center',
                               }}>
                                <h4 style={{ fontStyle: 'bold', marginBottom: '0' }} className="blue">{property?.title}</h4>
                                <h4 style={{ fontStyle: 'bold', marginBottom: '0' }} className="blue">{convertPrice(property?.price)}</h4>
                                <p style={{ fontSize: '18px', marginBottom: '0' }}>{property?.PropertyLocation?.address}</p>
                               </div>
                            </div>
                        </InfoWindow>
                    ) : null}
                </MarkerF>
                )}
            )}
            
            </MarkerClustererF>
        <></>
      </GoogleMap>


          </div>
        </div>
      )
}

export default MapView