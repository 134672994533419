import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

export default class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: '', coordinates: null, addr: null };
    this.setCoordinates = props.setCoordinates;
    this.setLocation = props.setLocation;
    this.setAddr = props.setAddr;
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    this.setState({address})
    geocodeByAddress(address)
      .then(results => {this.setAddr(results); this.state.addr=results[0]})
      .then(() => getLatLng(this.state.addr))
      .then(latLng => { console.log('Success', latLng); this.setCoordinates(latLng); this.setLocation(address);})
      .catch(error => console.error('Error', error));
  };

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
            <input
              {...getInputProps({
                placeholder: 'Address, Street, City, ZIP',
                className: 'location-search-input',
              })}
            />
          {suggestions.length > 0 ? <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div> : null}

        </div>
        )}
      </PlacesAutocomplete>
    );
  }
}