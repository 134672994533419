import * as yup from "yup";

const signUpSchema = yup.object({
    email: yup.string().email('Please enter a valid email')
    .min(2, 'Requires 2 or more characters')
    .required('Email is required'),
    password: yup.string()
    .min(6, 'Password should be 6 or more characters long')
    .required('Password is required')
})

export default signUpSchema;
