import React from 'react'
import AuthPageSideImage from '../../components/AuthPageSideImage';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'
import { MdOutlineArrowBack } from 'react-icons/md'

function NewPassword() {
    return (
        <div
        style={{
            display: 'flex',
            height: '100vh',
            flexWrap: 'wrap'
        }}>
            <AuthPageSideImage />
            <div
            className='login-section'
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '3rem',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '3rem',
                    width: '100%'
                }}>
                    <div
                    style={{
                        display: 'flex',
                        gap: '1rem'
                    }}>
                        <MdOutlineArrowBack 
                        style={{
                            color: '#1761B0',
                            height: '24px',
                            width: '24px'
                        }}/>  <h4 className='blue'>New Password</h4>
                    </div>
                    <div>
                        <p>
                        Please create your new password.
                        </p>
                    </div>
                    <div 
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2rem',
                    }}>
                        <h5 className='blue'>New Password</h5>
                        <input type='text' className='input-text'></input>
                        <h5 className='blue'>Confirm Password</h5>
                        <input type='text' className='input-text'></input>
                        <Button
                        style={{
                            borderRadius: '10px',
                            height: '63px',
                            fontWeight: '600',
                            fontSize: '20px',
                            backgroundColor: '#1761B0'
                        }}>Confirm</Button>
                    </div>
                    <div style={{ marginBottom: '5rem' }}>
                        <p>Don't have an account? <Link to='/signup'>Sign Up</Link></p>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default NewPassword