import React from 'react'
import HeaderTemplate from '../components/HeaderTemplate'
import Dropzone from '../components/Dropzone'
import { useLocation } from 'react-router-dom'

function AddPropertyFloorPlan() {
    const location = useLocation()
    const { state } = location;
    console.log("state: ", state)

    const [files, setFiles] = React.useState([])
    const [newState, setNewState] = React.useState(null)

    React.useEffect(() => {
        const newFilesArray = [...state?.propertyFile, ...files]
        state.propertyFile = newFilesArray;
        console.log("pls2: ", state)
    }, [files,])

    return (
        <>
            <HeaderTemplate nextScreen='/add-property-documents' state={state}/>  
            <div className='grey-background'>
                <div id='add-floor-plan' className='add-section'>
                    <h4 className='blue'>Add Floor Plan</h4>
                    <p>Please upload your property's floor plan (optional)</p>
                    <Dropzone setFiles={setFiles}/>
                </div>
            </div>
        </>
    )
}

export default AddPropertyFloorPlan