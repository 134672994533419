import React from 'react'


function PreConCondos({InputText,SelectField,formik,SelectComponent,Country,State,City,filterData,TextInputComponent,CheckBoxGen,

  LeaseLength,
        ParkingSpot,
        ParkingType,
        LaundryType,
        PetFriendly,
        Amenities,
        PropertyTypeCon,
        PropertyCompletion,
        PropertyStyle,
        ProjectPhase,
        loadCity
}) {
    return (
        <>
        
           <div className='inputs-area'>
                        <SelectField  fieldRequired={true} name="property.propertyDetail.country" list={Country} title="Country" />
                        {/*<SelectComponent required={true} 


                        onChange={

                            e => {
                                         
                                            formik.handleChange(e)
                                            loadCity(e.target.value)

                                        }}
                                        
                         onBlur={formik.handleBlur}  value={formik.values.property.propertyDetail['state']} id='property.propertyDetail.state' label='State' options={State} />*/}
                        
                            <SelectField  fieldRequired={true} name="property.propertyDetail.state" list={State} title="Province" />
                            <SelectField  fieldRequired={true} name="property.propertyDetail.city" list={City} title="City/Town" />
                        
                        
                        
                        
                        </div>
                        <div className='inputs-area'>
                           <div style={{width:'368px'}}>
                            <InputText name="property.propertyDetail.builder" title="Builder(s)" fieldRequired={true} />
                          </div>
                          <div style={{width:'368px'}}>
                            <InputText name="property.propertyDetail.developer" title="Developers" fieldRequired={true} />
                          </div>
                           <div style={{width:'368px'}}>
                            <InputText name="property.propertyDetail.storey" title="Storey’s" fieldRequired={true} />
                          </div>
                          
                        </div>

                         <div className='inputs-area'>
                           <div style={{width:'368px'}}>
                            <InputText name="property.propertyDetail.address" title="Address" fieldRequired={true} />
                          </div>
                          <div style={{width:'368px'}}>
                            <InputText name="property.propertyDetail.nameOfProject" title="Name Of Project" fieldRequired={true}/>
                          </div>
                          <div style={{width:'368px'}}>
                            <InputText name="property.propertyDetail.condo_unit" title="Condo Units" fieldRequired={true} />
                          </div>
                                                  
                        </div>

                        <div className='inputs-area'>
                          
                          
                            <SelectField  fieldRequired={true} name="property.propertyDetail.bedroom" list={filterData('Bedroom')} title="Bedroom(s)" />
                           <div style={{width:'368px'}}>
                            <InputText name="property.propertyDetail.squareFeet" title="Square feet" fieldRequired={true} />
                          </div>
                        <SelectField  fieldRequired={true} name="property.propertyDetail.projectPhase" list={ProjectPhase} title="Project phases" fieldRequired={true} />
                          

                        </div>
                        
                        
                        

                        <div className='inputs-area'>
                          
                          
                          <SelectField  fieldRequired={true} name="property.propertyDetail.approxCompletion" list={PropertyCompletion} title="Approximate completion" />
                         <div style={{width:'368px'}}>
                            <InputText name="property.propertyDetail.approxMaintenanceFee" title="Approximate Maintenance Fee" fieldRequired={true} />
                          </div>
                          
                        </div>

                        
                      </>
      )
}

export default PreConCondos