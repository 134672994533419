import axios from 'axios'
import API_ENDPOINTS from '../../utils/apiEndpoints'

const base_url = process.env.REACT_APP_BASE_URL

const addProperty = async(token, reqData) => {
    const API_URL = base_url + API_ENDPOINTS.addproperty
    
    const config = {
        headers:{
          Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.post(API_URL, reqData, config)
    return response.data
}


const updateProperty = async(token, reqData) => {
    const API_URL = base_url + API_ENDPOINTS.addproperty
    const config = {
        headers:{
          Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.patch(API_URL, reqData, config)
    return response.data
}

const imageList = async(token, id) => {
    const API_URL = base_url + '/property/image-list?id=' + id
    const config = {
        headers:{
          Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.get(API_URL, config)
    return response.data
}


const removeImage = async(token, id) => {
    const API_URL = base_url + '/property/remove-image?id=' + id
    const config = {
        headers:{
          Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.get(API_URL, config)
    return response.data
}

const updateImage = async(token, id,data) => {
    const API_URL = base_url + '/property/update-image?id=' + id
    const config = {
        headers:{
          Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.post(API_URL, data, config)
    return response.data
}



const getProperties = async(token, query) => {
    const API_URL = base_url + API_ENDPOINTS.addproperty + (query || '')
    const config = {
        headers:{
          Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.get(API_URL, config)
    return response.data
}


const getDashboard = async(token) => {
    const API_URL = base_url + '/property/dashboard'
    const config = {
        headers:{
          Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.get(API_URL, config)
    return response.data
}


const getMonthCount = async(token) => {
    const API_URL = base_url + '/property/dashboardchart'
    const config = {
        headers:{
          Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.get(API_URL, config)
    return response.data
}

const getLiveAuction = async(token) => {
    const API_URL = base_url + '/property/live-auction'
    const config = {
        headers:{
          Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.get(API_URL, config)
    return response.data
}




const propertyService = {
    addProperty,
    getProperties,
    updateProperty,
    getDashboard,
    getMonthCount,
    getLiveAuction,
    imageList,
    removeImage,
    updateImage
}

export default propertyService