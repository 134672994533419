import axios from 'axios'
import API_ENDPOINTS from '../../utils/apiEndpoints'

const base_url = process.env.REACT_APP_BASE_URL



const getList = async(token, query) => {
    
    const API_URL = base_url + API_ENDPOINTS.master + (query || '')
    
    const config = {
        headers:{
          Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.get(API_URL, config)
    return response.data
}

const masterService = {
    
    getList
}

export default masterService