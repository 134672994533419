import React,{useState} from 'react'
import Button from 'react-bootstrap/Button';
import AuthPageSideImage from '../../components/AuthPageSideImage';
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { login,reset } from '../../features/auth/authSlice'
import { Formik, Form, useFormik } from 'formik'
import Loader from "../../components/Loader";
import { fetchWrapper } from '../../helpers';
import * as Yup from 'yup';

function Login() {
	const navigate = useNavigate()
	const { user, isLoading,isError,message } = useSelector(( state ) => state.auth)
	const [spinner, setSpinner] = useState(false)


	const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
         validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Email is required'),
            password: Yup.string().required('Password is required')
        }),
         onSubmit: async (values) => {
         	setSpinner(true)
            let  data = values
		  
	       
	     const url = `${process.env.REACT_APP_BASE_URL}/user-admin/login`;
  		 const user= await fetchWrapper.post(url,data)
  		 .then(data =>{
  		 	localStorage.setItem('user-admin',data.accesstoken)
  		 	navigate("/admin/dashboard")
  		 	
  		 })
  		 .catch(err=>{
  		 	alert(err)
  		 })
  		 setSpinner(false)
        }      
    })

	return(
		<>
		{spinner && <Loader /> }
        <div
        style={{
            display: 'flex',
            height: '100vh',
            flexWrap: 'wrap',
        }}>
           
            <AuthPageSideImage />
            <div
            className='login-section'
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '3rem'
            }}>
                <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1.5rem'
                }}>
                    <h4 className='blue'>Log In</h4>
                    <p>Please log in to Admin Panel Property Ways</p>
                </div>
                <Formik
                initialValues={formik.initialValues} validationSchema={formik.validationSchema}>
                    {() => 
                        {
                        return(
                        <Form
                        onSubmit={formik.handleSubmit}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '2.5rem'
                        }}>
                            <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem'
                            }}>
                                <h5 className='blue'>Email Address</h5>
                                <input id="email" name="email" type='text' onChange={formik.handleChange} onBlur={formik.handleBlur} className='input-text'></input>
                                {formik.touched.email && formik.errors.email ? (
                                    <div style={{ color: 'red', fontWeight: 'bold', padding: '5px' }}>{formik.errors.email}</div>
                                ) : null}
                            </div>

                            

                            <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem'
                            }}>
                                <h5 className='blue'>Password</h5>
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} id='password' name='password' type='password' className='input-text'></input>
                                {formik.touched.password && formik.errors.password ? (
                                    <div style={{ color: 'red', fontWeight: 'bold', padding: '5px' }}>{formik.errors.password}</div>
                                ) : null}
                                <p 
                                className='blue' 
                                style={{ textAlign: 'right', fontSize: '14px', fontWeight: '550', cursor: 'pointer' }}
                                >Forgot Password?</p>
                            </div>
                            <div>

                            {isError && <div style={{color:'red',fontWeight:'bold',padding:'5px'}}>Invalid Credential</div>}
                                <Button 
                                className='primary'
                                type='submit'
                                style={{
                                    width: '14.5rem',
                                    height: '4rem',
                                    fontSize: '20px',
                                    width: '100%'
                                }}>Log In</Button>
                            </div>
                        </Form>)}
                    }
                </Formik>
                <div
                style={{
                    textAlign: 'center',
                    marginBottom: '5rem'
                }}>
                    <p>Don't have an account? <Link to='#'>Sign Up</Link></p>
                </div>
            </div>
        </div>
		</>
	)
}

export default Login;