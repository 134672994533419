import React,{useState} from 'react'
import { Button,Dropdown } from 'react-bootstrap'
import dummyHouse from '../assets/images/house1.jpg'
import AuctionListingCard from '../components/AuctionListingCard'
import { useNavigate } from 'react-router-dom'
import { AiFillHome } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { getProperties } from '../features/property/propertySlice'
import { getMasterList } from '../features/master/masterSlice'
import GreyPage from '../components/GreyPage'
import SelectComponent from '../components/SelectComponent'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import optionsDots from '../assets/svgs/Icon material-more-horiz.svg'
import TextInputComponent from '../components/TextInputComponent'
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Slider from '@mui/material/Slider';
import home_banner from '../assets/images/housebanner.png'
import LocationSearchInput from '../components/PlacesAutocomplete'

import { fetchWrapper } from '../helpers';
import Loader from "../components/Loader";

function Auction() {
    const navigate = useNavigate()
    const [coordinates, setCoordinates] = React.useState(null)
    const [location, setLocation] = React.useState(null)
    const [addr, setAddr] = React.useState([])
    const [query, setQuery] = React.useState('')

    const [spinner, setSpinner] = useState(false)

    React.useEffect(() => {
        
        setQuery(`?lat=${coordinates?.lat}&lng=${coordinates?.lng}`)
    }, [coordinates])

    
    const { user } = useSelector((state) => state.auth)
    const { listings,isLoading } = useSelector((state) => state.property)
    const dispatch = useDispatch()

    React.useEffect(() => {
        if (user === null) {
            navigate('/')
        }
        dispatch(getProperties('?sales_method=AUCTION'))
         dispatch(getMasterList('RentalType'))
        

        

    }, [])

    React.useEffect(() => {
        if (user === null){
            navigate('/')
        }
    }, [user])

    const markSold = async(id) => {
        const aa = window.confirm("Are you sure sold this property?");
        if(aa){
            setSpinner(true);
             const url = `${process.env.REACT_APP_BASE_URL}/property/sold?id=${id}`;
             await fetchWrapper.get(url);
              dispatch(getProperties('?sales_method=AUCTION'))
             setSpinner(false)
        }
        
     }

     const markDelete = async(id) => {
         const aa = window.confirm("Are you sure delete this property?");
        if(aa){
            setSpinner(true);
             const url = `${process.env.REACT_APP_BASE_URL}/property/delete-property?id=${id}`;
             await fetchWrapper.get(url);
              dispatch(getProperties('?sales_method=AUCTION'))
             setSpinner(false)
         }
     }

    const options = (
        <div style={{
            backgroundColor: 'grey',
            padding: '1rem',
            width: '30rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            flexWrap: 'wrap'
        }}>
            <TextInputComponent />
            
            <ToggleButtonGroup onChange={(e) => console.log("e: ", e)} style={{ gap: '1rem', flexWrap: 'wrap' }} type="checkbox" className="mb-2">
                <ToggleButton className='toggle-button' style={{ borderRadius: '0' }} id="tbg-check" value='office'>
                Office
                </ToggleButton>
                <ToggleButton className='toggle-button' id="multifamily" value='multifamily'>
                Multifamily
                </ToggleButton>
                <ToggleButton className='toggle-button' id="retail" value='retail'>
                Retail
                </ToggleButton>
                <ToggleButton className='toggle-button' id="land" value='land'>
                Land
                </ToggleButton>
                <ToggleButton className='toggle-button' id="industrial" value='industrial'>
                Industrial
                </ToggleButton>
                <ToggleButton className='toggle-button' id="self-storage" value='self-storage'>
                Self-storage
                </ToggleButton>
                <ToggleButton className='toggle-button' id="special use" value='special use'>
                Special Use
                </ToggleButton>
                <ToggleButton className='toggle-button' id="hotel" value='hotel'>
                Hotel
                </ToggleButton>
            </ToggleButtonGroup>
            <Slider aria-label='Radius' />
            <Slider aria-label='Price' />
            <Slider aria-label='Floors' />
            <Slider aria-label='Bedrooms' />
        </div>
    )

    return (
        <>
        {spinner && <Loader /> }
        <div style={{width: '100%'}}>
            <div 
            className='grey-background-home' 
            style={{ 
                width: '79%', 
                  
                  marginLeft: '21%', 
                  paddingRight: '1rem',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1.5rem',
                  paddingLeft: '2%',
            }}>
                <GreyPage heading='Listings' />
                <div
                style={{
                    width: '100%',
                    height: '347px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1.5rem',
                    borderRadius: '8px',
                    backgroundImage: `url(${home_banner})`
                }}>
                    <div
                    style={{ 
                        fontSize: '25px',
                        color: 'white',
                        fontWeight: '500'
                    }}>Let us help you find a new home</div>
                    <div
                    style={{
                        display: 'flex',
                        gap: '1rem',
                        alignItems: 'center',
                        width: '70%',
                        backgroundColor: 'white',
                        padding: '1rem',
                        borderRadius: '4px',
                        justifyContent: 'space-between'
                    }}>
                        <LocationSearchInput setCoordinates={setCoordinates} setLocation={setLocation} setAddr={setAddr}/>
                        <div style={{ display: 'flex', gap:'1rem', width: '25%', alignItems: 'center', height:'100%',justifyContent:'end' }}>
                            
                            <Button 
                            onClick={
                                () => {

                                    if(!addr[0]){
                                        alert("Please, address is required.");
                                        return false
                                    }

                                    dispatch(getProperties(query))
                                    navigate('/search', { state : {
                                        center: coordinates,
                                        location: location,
                                        query: query,
                                        addr: {
                                            address_components: addr[0].address_components,
                                            types: addr[0].types
                                        }
                                    } })
                                }
                            }
                            className='button'
                            style={{
                                // width: '50%',
                                height: '100%',
                                paddingLeft: '1.5rem',
                                paddingRight: '1.5rem',
                                backgroundColor: '#1761B0',
                                fontWeight: '500'
                            }}>SEARCH</Button>                             
                        </div>
                     
                    </div>

                </div>
                <div
                style={{
                    width: '100%',
                    height: '65vh',
                    backgroundColor: 'white',
                }}>
                    <div style={{
                        display: 'flex',
                        position: 'relative',
                        top: '0',
                        padding: '2rem',
                        paddingLeft: '3rem',
                        borderBottom: '5px solid #F9F9F9',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <span style={{ fontSize: '18px', fontWeight: '550' }}>For Auction</span>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '2rem'
                        }}>
                            <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                                <span style={{ color: '#292D2B', fontSize: '14px' }}>Filter: </span>
                                <OverlayTrigger trigger="click" rootClose placement="left-start" overlay={options}>
                                <img 
                                src={optionsDots} 
                                style={{
                                    cursor: 'pointer'
                                }}
                                alt='options'
                                />
                                </OverlayTrigger>
                                {/* <SelectComponent style={{ width: '10rem' }}/> */}
                            </div>
                            




                            <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic"

         style={{
                                paddingTop: '0.75rem',
                                paddingBottom: '0.75rem',
                                paddingLeft: '1rem',
                                paddingRight: '1rem',
                                display: 'flex',
                                gap: '0.5rem',
                                alignItems: 'center',
                                backgroundColor:'#1761B0'
                            }}
      >
        <AiFillHome />
         Sell a Property
      </Dropdown.Toggle>

      <Dropdown.Menu>
     
        <Dropdown.Item  onClick={() => navigate('/add-property/sale')}>For Sale</Dropdown.Item>
        <Dropdown.Item  onClick={() => navigate('/add-property/auction')}>Auction</Dropdown.Item>
        
      </Dropdown.Menu>
    </Dropdown>
                        </div>
                    </div>
                    <div 
                    className='listings'
                    style={{
                        display: 'flex',
                        gap: '2rem',
                        flexWrap: 'wrap',
                        padding: '2rem',
                        overflowY: 'scroll',
                        height: '80%'
                    }}>
                        {

                            !isLoading && 
                            
                            listings?.data?.map(listing => {
                            return(<AuctionListingCard markSold={markSold} markDelete={markDelete} listingDetails={listing}/>)
                        })}
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Auction