import * as yup from "yup";

const changePasswordSchema = yup.object({
    password: yup.string()
    .min(6, 'Password should be 6 or more characters long')
    .required('Password is required'),
    confirmPassword: yup.string()
     .oneOf([yup.ref('password'), null], 'Passwords must match')
})

export default changePasswordSchema;
