import React from 'react'


function VacantLand({InputText,SelectField,formik,SelectComponent,Country,State,City,filterData,TextInputComponent,CheckBoxGen,

  LeaseLength,
        ParkingSpot,
        ParkingType,
        LaundryType,
        PetFriendly,
        Amenities,
        loadCity
}) {
    return (
        <>
        
           <div className='inputs-area'>
                        <SelectField  fieldRequired={true} name="property.propertyDetail.country" list={Country} title="Country" />
                        
                        {/*<SelectComponent required={true} 

                        onChange={

                            e => {
                                         
                                            formik.handleChange(e)
                                            loadCity(e.target.value)

                                        }}

                         onBlur={formik.handleBlur}  value={formik.values.property.propertyDetail['state']} id='property.propertyDetail.state' label='State' options={State} />*/}
                        
                        <SelectField  fieldRequired={true} name="property.propertyDetail.state" list={State} title="Province" />
                        <SelectField  fieldRequired={true} name="property.propertyDetail.city" list={City} title="City/Town" />
                        
                        
                        
                        
                        </div>
                        <div className='inputs-area'>
                          <div style={{width:'368px'}}>
                            <InputText name="property.propertyDetail.address" title="Address" fieldRequired={true} placeholder="Address"/>
                          </div>
                        </div>
                        
                        <div className='inputs-area'>
                          <SelectField  fieldRequired={true} name="property.propertyDetail.zoning" list={filterData('Zoning')} title="Zoning" />
                          <SelectField  fieldRequired={true} name="property.propertyDetail.fronting" list={filterData('Fronting')} title="Fronting On" />
                          <div style={{width: '368px'}}>
                            <InputText name="property.propertyDetail.lotFrontage" title="Lot Frontage" fieldRequired={true} placeholder="e.g 10" />
                          </div>
                          <div style={{width: '368px'}}>  
                            <InputText name="property.propertyDetail.lotDepth" title="Lot Dept" placeholder="e.g 10"/>
                          </div> 
                        </div>
                        <div className='inputs-area'>
                          <CheckBoxGen limit={1} onChange={formik.handleChange} onBlur={formik.handleBlur} label='Water Front' value={formik.values.property.propertyDetail['waterFront']} id='property.propertyDetail.waterFront' options={[
                                {label: 'Yes', id: 'yes'},
                                {label: 'No', id: 'no'},
                                
                              ]} />
                        </div>

                        <div className='inputs-area'>
                          <CheckBoxGen limit={1}  onChange={formik.handleChange} onBlur={formik.handleBlur} label='Water Supply Types (please select 1)' id='property.propertyDetail.waterSupplyTypes' options={[
                              {label: 'Bored Well', id: '1'},
                              {label: 'Cistern', id: '2'},
                              {label: 'Lake/River', id: '3'},
                              {label: 'City/Municipal', id: '4'},
                              {label: 'Shared Well', id: '5'},
                              {label: 'Drilled Well', id: '6'},
                              {label: 'Dug Well', id: '7'},
                            ]} />

                          <CheckBoxGen required={true} limit={1} onChange={formik.handleChange} onBlur={formik.handleBlur} label='Sewers (please select 1)' id='property.propertyDetail.sewers' options={[
                            {label: 'Holding Tank', id: 'holding tank'},
                            {label: 'Septic', id: 'septic'},
                            {label: 'Sewer', id: 'sewer'},
                            {label: 'None', id: 'none'},
                            {label: 'Other', id: 'other'},
                          ]} />
                        </div>

                        {/*<div className='inputs-area'>
                          <CheckBoxGen limit={1} onChange={formik.handleChange} onBlur={formik.handleBlur} label='Utilities' id='property.propertyDetail.utilities' options={[
                                {label: 'Yes', id: 'yes'},
                                {label: 'No', id: 'no'},
                                
                              ]} />
                        </div>*/}
                      </>
      )
}

export default VacantLand