import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import masterService from './masterService'

// const user = JSON.parse(localStorage.getItem('user'))

const initialState = {
    
    RentalType: [],
    City:[],
    Country:[],
    Bedroom:[],
    Zoning:[],
    Fronting:[],
    Bathroom:[],
    SquareFeet:[],
    LeaseLength:[],
    ParkingSpot:[],
    ParkingType:[],
    LaundryType:[],
    Amenities:[],
    Kitchen:[],
    Washroom:[],
    Basement:[],
    Parking:[],
    GarageType:[],
    GarageParking:[],
    Heat:[],
    HeatType:[],
    PropertySize:[],
    Pool:[],
    FirePlace:[],
    
    masterData:[],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}



export const getMasterList = createAsyncThunk('master/getAll', async(reqData, thunkAPI,dispatch) => {

    const token = thunkAPI.getState().auth.accessToken
   
    try {
       return await masterService.getList(token, '?type=1')
       .then((res) => {
       		return {data:res.data,type:reqData}
       })
    } catch (error) {
        
    }
})

export const masterSlice = createSlice({
    name: 'master',
    initialState:initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            
            state.message = ''
        }
    },
    extraReducers: (builder) => {
        builder
        
        .addCase(getMasterList.pending, (state) => {
            state.isLoading = true
            //state.masterData = [];
        })
        .addCase(getMasterList.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state['masterData'] = action.payload.data
            
            
        })
    }
    
})

export const { reset } = masterSlice.actions
export default masterSlice.reducer