import React from 'react'
import GreyPage from '../components/GreyPage'

function Notifications() {
    return (
        <div style={{width: '100%'}}>
          <div 
          className='grey-background-home' 
          style={{ 
              width: '79%', 
              height: '100vh', 
              marginLeft: '21%', 
              paddingRight: '1rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '1.5rem',
              paddingLeft: '2%',
          }}>
            <GreyPage heading='Notifications' />
          </div>
        </div>
      )
}

export default Notifications