
import React,{useEffect,useState} from 'react';
import { Button,useDisclosure } from '@chakra-ui/react'
import * as Yup from 'yup';
import { Formik, Form, useFormik } from 'formik'

import Loader from "../../../components/Loader";
import { toast } from "react-toastify";
import { fetchWrapper } from '../../../helpers';

function Create({loadList,rs,meClose}) {
	const [state, setstate] = useState([]);
	const [spinner, setSpinner] = useState(false);
	const [id,setId]=useState("")
	useEffect(() => {
        GetToken();

        formik.setValues({ 
	        ...formik.values,
	        userId: id
    	});
    	loadState()

    },[id]);

	const { isOpen, onOpen, onClose } = useDisclosure()
  	const btnRef = React.useRef()


  	const GetToken =()=> {
			const token = localStorage.getItem('user-admin');

	    if (token) {
	      const decodedToken = decodeToken(token);
	      const user = decodedToken.UserAdminData;
	       setId(user.id)
	    }
		}

	 const decodeToken = (token) => {
	    const decodedString = atob(token.split('.')[1]);
	    const decodedObject = JSON.parse(decodedString);
	    // console.log(decodedObject)
	    return decodedObject;
	  };

	  const loadState= async () => {
	 		setSpinner(true)
			const url = `${process.env.REACT_APP_BASE_URL}/state/list`;
			const data  = await fetchWrapper.get(url)
	    	setstate(data.data);
	    	setSpinner(false)     
  	}

	const formik = useFormik({
        initialValues: {
            name: '',
            stateId:'',
            userId:id,
        },
        validationSchema : Yup.object({
        	name: Yup.string().required(`Required`),
            stateId: Yup.string().required(`Required`)
        }),
         onSubmit: async (values) => {
         		setSpinner(true)
         		let  data = values
		     	const url = `${process.env.REACT_APP_BASE_URL}/city/create`;
	  		 	await fetchWrapper.post(url,data)
		  		loadList()
		  		meClose()
		  		setSpinner(false)
         	
         	
           
  		 
        }      
    })
	return(

		<>
		{spinner && <Loader /> }
		 <Formik
                initialValues={formik.initialValues} validationSchema={formik.validationSchema}>
                    {() => 
                        {
                        return(
                        <Form
                        onSubmit={formik.handleSubmit}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '2.5rem'
                        }}>
                            <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem'
                            }}>
                                <h5 className='blue'>Name</h5>
                                <input id="name" name="name" type='text' onChange={formik.handleChange} onBlur={formik.handleBlur} className='input-text'></input>
                                 {formik.touched.name && formik.errors.name ? (
                                    <div style={{ color: 'red', fontWeight: 'bold', padding: '5px' }}>{formik.errors.name}</div>
                                ) : null}
                            </div>
                             <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
	                            <h5 className='blue'>State</h5>
	                            <select id="stateId" name="stateId" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.stateId} className='input-text'>
	                                <option value="">Select a State</option>
	                                {state.map(state => (
	                                    <option key={state.id} value={state.id}>{state.name}</option>
	                                ))}
	                            </select>
	                             {formik.touched.stateId && formik.errors.stateId ? (
                                    <div style={{ color: 'red', fontWeight: 'bold', padding: '5px' }}>{formik.errors.stateId}</div>
                                ) : null}
	                            
	                        </div>
                            
                            <div>

                                <Button 
                                className="primary"
                                type='submit'
                                style={{
                                    width: '14.5rem',
                                    height: '4rem',
                                    fontSize: '20px',
                                    width: '100%'
                                }}>Create</Button>
                            </div>
                        </Form>)}
                    }
                </Formik>
		</>
		)
}
export default Create;