import React from 'react'



function HouseSaleMN({InputText,SelectField,formik,SelectComponent,Country,State,City,filterData,TextInputComponent,CheckBoxGen,

  LeaseLength,
        ParkingSpot,
        ParkingType,
        LaundryType,
        PetFriendly,
        Amenities,
        loadCity
}) {
    return (
        <>
                         <div className='inputs-area'>
                         

                          <SelectField name="property.propertyDetail.country" list={Country} title="Country" fieldRequired={true}   />
                          <SelectField name="property.propertyDetail.state"  list={State} title="Province" fieldRequired={true} />

                          <SelectField name="property.propertyDetail.city"  list={City} title="City/Town" fieldRequired={true}/>
                            <div style={{width: '368px'}}>
                              <InputText name="property.propertyDetail.address" fieldRequired={true}  title="Address"  />
                            </div>  

                          {/*<SelectComponent required={true} 
                          onChange={

                            e => {
                                         
                                            formik.handleChange(e)
                                            loadCity(e.target.value)

                                        }}
                          onBlur={formik.handleBlur} id='property.propertyDetail.state' value={formik.values.property.propertyDetail['state']} label='Province' options={State} */}
                          
                          
                          
                          
                        </div>


                        <div className='inputs-area'>
                          <div style={{width: '368px'}}>
                            <InputText name="property.propertyDetail.legalName" fieldRequired={true}  title="Full Legal Name"  />
                          </div> 
                        </div>

                        <div className='inputs-area'>

                              <SelectField fieldRequired={true} name="property.propertyDetail.propertySize" list={filterData('PropertySize')} title="Property Size" />
                              <SelectField fieldRequired={true} name="property.propertyDetail.fronting" list={filterData('Fronting')} title="Fronting On" />
                              <div style={{width: '368px'}}>
                                <InputText name="property.propertyDetail.lotFrontage" fieldRequired={true} title="Lot Frontage" placeholder="e.g 10"/>
                              </div>
                              <div style={{width: '368px'}}>  
                                <InputText name="property.propertyDetail.lotDepth" fieldRequired={false} title="Lot Dept" placeholder="e.g 10"/>
                              </div> 
                                                     
                        </div>
                        <div className='inputs-area'>
                          <CheckBoxGen limit={1} onChange={formik.handleChange} onBlur={formik.handleBlur} label='Water Front' value={formik.values.property.propertyDetail['waterFront']} id='property.propertyDetail.waterFront' options={[
                                {label: 'Yes', id: 'yes'},
                                {label: 'No', id: 'no'},
                                
                              ]} />
                        </div>

                        <div className='inputs-area'>
                          <CheckBoxGen limit={1}  onChange={formik.handleChange} onBlur={formik.handleBlur} label='Water Supply Types (please select 1)' value={formik.values.property.propertyDetail['waterSupplyTypes']} id='property.propertyDetail.waterSupplyTypes' options={[
                              {label: 'Bored Well', id: '1'},
                              {label: 'Cistern', id: '2'},
                              {label: 'Lake/River', id: '3'},
                              {label: 'City/Municipal', id: '4'},
                              {label: 'Shared Well', id: '5'},
                              {label: 'Drilled Well', id: '6'},
                              {label: 'Dug Well', id: '7'},
                            ]} />

                          <CheckBoxGen required={true} limit={1} onChange={formik.handleChange} onBlur={formik.handleBlur} label='Sewers (please select 1)' id='property.propertyDetail.sewers' value={formik.values.property.propertyDetail['sewers']} options={[
                            {label: 'Holding Tank', id: 'holding tank'},
                            {label: 'Septic', id: 'septic'},
                            {label: 'Sewer', id: 'sewer'},
                            {label: 'None', id: 'none'},
                            {label: 'Other', id: 'other'},
                          ]} />
                        </div>

                        <div className='inputs-area'>
                          {/*<CheckBoxGen limit={1} onChange={formik.handleChange} onBlur={formik.handleBlur} label='Utilities' id='property.propertyDetail.utilities' value={formik.values.property.propertyDetail['utilities']} options={[
                                {label: 'Yes', id: 'yes'},
                                {label: 'No', id: 'no'},
                                
                              ]} />*/}
                           
                          <div style={{width: '90%'}}>
                            <InputText name="property.propertyDetail.legalDescription"   title="Legal Description or ARN Number"  />
                          </div>                                
                          
                        </div>
                        <div className='inputs-area'>
                          <SelectField fieldRequired={true} name="property.propertyDetail.bedroom" list={filterData('Bedroom')} title="Bedrooms" />
                          <SelectField fieldRequired={true} name="property.propertyDetail.kitchen" list={filterData('Kitchen')} title="Kitchens" />
                          <SelectField fieldRequired={true} name="property.propertyDetail.washroom" list={filterData('Washroom')} title="Washrooms" />
                          <SelectField fieldRequired={true}  name="property.propertyDetail.squareFeet" list={filterData('SquareFeet')} title="SquareFeet" />
                          <SelectField fieldRequired={true}  name="property.propertyDetail.basement" list={filterData('Basement')} title="Basement" />

                          
                          <div style={{width: '368px'}}>
                            <InputText name="property.propertyDetail.parking" fieldRequired={true} title="Parking"/>
                          </div>

                          <SelectField fieldRequired={true}  name="property.propertyDetail.garageType" list={filterData('GarageType')} title="Garage Type" />                          
                          <SelectField fieldRequired={true}  name="property.propertyDetail.garageParking" list={filterData('GarageParking')} title="Garage Parking" />
                          <SelectField fieldRequired={true}  name="property.propertyDetail.heat" list={filterData('Heat')} title="Heat" />
                          <SelectField fieldRequired={true}  name="property.propertyDetail.heatType" list={filterData('HeatType')} title="Heat Type" />
                          <SelectField fieldRequired={true}  name="property.propertyDetail.airCondition" list={filterData('AirCondition')} title="Air Conditioning" />

                          
                          
                          <div style={{width: '368px'}}>
                            <InputText name="property.propertyDetail.propertyTax" fieldRequired={true} title="Property Tax" placeholder="$"/>
                          </div>
                          
                          <SelectField fieldRequired={true} name="property.propertyDetail.pool" list={filterData('Pool')} title="Pool" />
                          <SelectField fieldRequired={true} name="property.propertyDetail.firePlace" list={filterData('FirePlace')} title="Fireplace" />
                          
                        </div>
                      </>
      )
}

export default HouseSaleMN