import React,{useState,useEffect} from 'react'
import HeaderTemplate from '../components/HeaderTemplate'
import { useLocation, useNavigate,useParams } from 'react-router-dom'
import house1 from '../assets/images/house1.jpg'
import house2 from '../assets/images/house2.jpg'
import house3 from '../assets/images/house3.jpg'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import AgentCard from '../components/AgentCard'
import { useDispatch, useSelector } from 'react-redux'
import Loader from "../components/Loader";
import LawyerCard from '../components/LawyerCard'
import RealtorCard from '../components/RealtorCard'
import Bidding from '../components/Bidding'
import api from "../utils/axios";
import { toast } from "react-toastify";


function PropertyDetails(props) {
  const location = useLocation()
  let params = useParams()
  const propertyId = params.id
  const { user } = useSelector((state) => state.auth)
 
  const [propertyDetail, setPropertyDetail] = useState([]);
  const [imgs, setImgs] = useState([]);
  
  const [propertyDetailValue, setPropertyDetailValue] = useState([]);
   const [spinner, setSpinner] = useState(false)
 
  //const propertyDetailValue = {};//JSON.parse(state?.propertyDetail)

 // const propertyDetailArr = Object.entries(propertyDetailValue);


  const fetchProperty = async () => {
    

        try {
          setSpinner(true);

          const { data } = await api.get(`property/${propertyId}`);

          setPropertyDetail(data.data);
          setPropertyDetailValue(JSON.parse(data.data.propertyDetail))
           const imgData = data.data?.PropertyFiles?.map(fileObj => fileObj?.fileURL) || [house1, house2, house3]

           setImgs(imgData)
          console.log(data.data.PropertyFiles)
          setSpinner(false);
          
        } catch (error) {
          toast.error(error);
        }
  };
  useEffect(() => {
    fetchProperty();

    
  }, [propertyId]);

  const camelize = (str)  =>{
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 1 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}


const dispatch = useDispatch()

  


      const { RentalType,City,Country,State,Bedroom,Zoning,Fronting,
        Bathroom,SquareFeet,LeaseLength,ParkingSpot,ParkingType,LaundryType,Amenities,
        Kitchen,Washroom,Basement,Parking,GarageType,GarageParking,Heat,HeatType,PropertyTax,
        Pool,FirePlace,PropertySize,masterData


       } = useSelector((state) => state.master)
   

    const AirCondition = [{value:"Yes",text:"Yes"}]
    const PetFriendly = [{value:"Yes",text:"Yes"}]

const masterValue = (value,key) => {

    if(key == 'city'){
       // const  mL =  City.filter(rs => rs.value == value);

        //return mL[0].text
    }

    if(key == 'state'){
        //const  mL =  State.filter(rs => rs.value == value);

        //return mL[0].text
    }

    if(key == 'country'){
       // const  mL =  Country.filter(rs => rs.value == value);

        //return mL[0].text
    }

    if(key == 'zoning'){
        const  mL =  masterData.filter(rs => rs.value == value && rs.master_type == "Zoning");

        return mL[0]?.text
    }

    if(key == 'fronting'){
        const  mL =  masterData.filter(rs => rs.value == value && rs.master_type == "Fronting");

        return mL[0]?.text
    }

    if(key == 'bedroom'){
        const  mL =  masterData.filter(rs => rs.value == value && rs.master_type == "Bedroom");

        return mL[0]?.text  == "" ? mL[0]?.text  : value;
    }

    if(key == 'kitchen'){
        const  mL =  masterData.filter(rs => rs.value == value && rs.master_type == "Kitchen");

        return mL[0]?.text
    }

    if(key == 'washroom'){
        const  mL =  masterData.filter(rs => rs.value == value && rs.master_type == "Washroom");

        return mL[0]?.text
    }

    if(key == 'squareFeet'){
        const  mL =  masterData.filter(rs => rs.value == value && rs.master_type == "SquareFeet");

        return mL[0]?.text  == "" ? mL[0]?.text  : value;
    }

    if(key == 'basement'){
        const  mL =  masterData.filter(rs => rs.value == value && rs.master_type == "Basement");

        return mL[0]?.text
    }

    if(key == 'garageType'){
        const  mL =  masterData.filter(rs => rs.value == value && rs.master_type == "GarageType");

        return mL[0]?.text
    }

    if(key == 'garageParking'){
        const  mL =  masterData.filter(rs => rs.value == value && rs.master_type == "GarageParking");

        return mL[0]?.text
    }

    if(key == 'heat'){
        const  mL =  masterData.filter(rs => rs.value == value && rs.master_type == "Heat");

        return mL[0]?.text
    }

    if(key == 'heatType'){
        const  mL =  masterData.filter(rs => rs.value == value && rs.master_type == "HeatType");

        return mL[0]?.text
    }


    if(key == 'firePlace'){
        const  mL =  masterData.filter(rs => rs.value == value && rs.master_type == "FirePlace");

        return mL[0]?.text
    }


    

    if(value == true){
        return "Yes";
    }
    return   value
}

  return (

    <div>
    {spinner && <Loader /> }
        <HeaderTemplate isDetailsScreen={true} />


        <div className='grey-background' style={{
            paddingRight: '8rem'
        }}>
            <div className='add-section'>
                <div 
                className='images-display'
                style={{
                    height: '311px',
                    display: 'flex',
                    gap: '1rem',
                    width: '100%',
                }}>
                    <Carousel style={{
                        height: '311px',
                        width: '100%',
                        color: 'blue'
                    }}>
                        {imgs.map(image => {
                            return(
                                <Carousel.Item style={{
                                    height: '311px',
                                    width: '100%'
                                }}>
                                    <img style={{ height: '100%', width: 'inherit', objectFit: 'contain' }} src={image} alt='image' />
                                </Carousel.Item>
                            )
                        })}
                    </Carousel>
                    {/* <div
                    style={{
                        width: '70%'
                    }}>
                        <img src={house1} alt='image-1'
                        style={{
                            height: '100%',
                            width: '100%',
                            overflowY: 'hidden',
                            borderRadius: '5px'
                            // objectFit: 'contain',
                        }}/>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem',
                        width: '30%',
                        height: '311px',
                    }}>
                        <img src={house2} alt='image-2' style={{
                            height: '48%',
                            width: '100%',
                            borderRadius: '5px'
                            // objectFit: 'contain',
                        }}/>
                        <img src={house3} alt='image-3' style={{
                            height: '48%',
                            width: '100%',
                            overflowY: 'hidden',
                            borderRadius: '5px'
                            // objectFit: 'contain',
                        }}/>
                    </div> */}
                </div>                
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{width:'25vw'}}>
                        <div >
                            <h4 className='blue'>
                                {propertyDetail.title}
                            </h4>
                            <p>{propertyDetailValue.address}</p>                    
                        </div>
                        <div >
                            <h3 className='blue' style={{fontWeight: '600'}}>
                                ${propertyDetail.price}
                            </h3>
                            <p>Guide Price</p>
                        </div>
                        <div >
                            <h4 className='blue bold'>Property  Details</h4>



                            <div >
                            
                                <div style={{
                                    marginTop: '2rem'
                                }}>
                                
                                    <div className='tabbed-details'>
                                       
                                           <p className='blue bold fixed-length-p'>Type</p><p>{propertyDetail.propertyType}</p>

                                       
                                    </div>


                                    <div className='tabbed-details'>
                                       
                                           <p className='blue bold fixed-length-p'>Seller Name</p><p>{propertyDetail?.userRs?.fullName}</p>
                                           
                                       
                                    </div>

                                    
                                  
                                </div>
                            </div>



                            <div style={{ 
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem',
                                marginTop: '1.5rem'
                            }}>
                                <div>
                                    <h4 className='blue' style={{ fontSize: '16px' }}>Property Description</h4>
                                    <p>{propertyDetail.propertyDescription}</p>
                                </div>



                               
                            </div>


                        </div>

                        <div >
                            <h4 className='blue bold'>Full  Details</h4>

                           {/* <div style={{ 
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem',
                                marginTop: '1.5rem'
                            }}>
                                <div>
                                    <h4 className='blue' style={{ fontSize: '16px' }}>Legal Description</h4>
                                    <p>The Lorem Ipsum text used today can be tracked down to the printing press industry in the 16th century. An unknown printer used a scrambled version of Cicero's philosophical book "De Finibus Bonorum et Malorum", written in 45 BC, to create filler text. The text became popular among printers and typesetters because it provided a standard dummy text that could be used to showcase different fonts, layouts, and designs without the distraction of meaningful content.</p>
                                </div>



                               
                            </div>
*/}

                             
                        </div>


                        <div >
                            <h4 className='blue bold'>Property Interior / Exterior  Details</h4>

                            


                             <div >
                            
                                <div style={{
                                marginTop: '2rem'
                            }}>
                             {
                                //Object.keys(propertyDetailValue).forEach(([key, value]) => {

                                    Object.keys(propertyDetailValue).map(key =>  {
                                    if(propertyDetailValue[key] != ''){
                                 return(<>
                                <div className='tabbed-details'>
                                   
                                       <p className='blue bold fixed-length-p'>{camelize(key)}</p><p>{masterValue(propertyDetailValue[key], key)}</p>
                                   
                                </div>
                                </>)
                                }  
                                 })}
                            </div>

                            </div>
                        </div>





                        

                        
                       
                       
                    </div>
                    <div
                          style={{
                               
                               
                                display: 'flex',
                                
                            }}

                    >
                        {propertyDetail.saleMethod == 'AUCTION' ? <Bidding propertyId={propertyDetail.id}  /> : null}
                    </div>
                    <div>
                    { propertyDetail.userId == user.data.id   ? (
                        <>
                        
                         <br />
                        <LawyerCard state={propertyDetail} fetchProperty={fetchProperty} propertyId={propertyDetail.id}  typeName="Lawyer" companyName='Baker & Smith' />
                        <br />
                       
                        <RealtorCard state={propertyDetail} fetchProperty={fetchProperty} propertyId={propertyDetail.id}  typeName="Realtor" companyName='Baker & Smith' />
                       </>
                       )
                        : (
                            <>
                            <AgentCard name={propertyDetail?.userRs?.fullName} toUser={propertyDetail?.userRs?.id} typeName="Seller" companyName='Baker & Smith' />
                            {propertyDetail.realtorRs && (
                                <AgentCard name={propertyDetail?.realtorRs?.fullName} toUser={propertyDetail?.realtorRs?.id} typeName="Realtor" companyName='Baker & Smith' />

                            )}


                            {propertyDetail.lawyerRs && (
                                <AgentCard name={propertyDetail?.lawyerRs?.fullName} toUser={propertyDetail?.lawyerRs?.id} typeName="Lawyer" companyName='Baker & Smith' />

                            )}
                            
                            
                            </>
                            )
                   }
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
)
}

export default PropertyDetails