const API_ENDPOINTS = {
    auth: '/auth',
    signup: '/signup',
    user: '/user',
    login: '/login',
    property: '/property/property-list',
    addproperty: '/property',
    forgotPassword: '/forgot-password',
    master: '/master',
    chat: '/chat',
}

export default API_ENDPOINTS