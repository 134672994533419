import React from 'react'
import HeaderTemplate from '../components/HeaderTemplate'
import { useLocation } from 'react-router-dom'
import house1 from '../assets/images/house1.jpg'
import house2 from '../assets/images/house2.jpg'
import house3 from '../assets/images/house3.jpg'
import { useSelector, useDispatch } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom'
import Carousel from 'react-bootstrap/Carousel';
import { reset } from '../features/property/propertySlice'

function ListMyProperty() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { addedProperty } = useSelector((state) => state.property)
    const { state } = location;
    const [isListed, setIsListed] = React.useState(false)


    const propertyDetailValue = state.property.propertyDetail;

    const imgs = state?.propertyFile?.map(fileObj => fileObj?.fileURL) || [house1, house2, house3]

    console.log("state: ", state)

    React.useEffect(() => {
        if (addedProperty) {
            setIsListed(true)
        }
    }, [addedProperty])

    
    return (
        <div>
            <HeaderTemplate isReviewPropertyScreen={true} state={state}/>
            <div className='grey-background' style={{
                paddingRight: '8rem'
            }}>
                <div className='add-section'>
                    <div 
                    className='images-display'
                    style={{
                        height: '311px',
                        display: 'flex',
                        gap: '1rem',
                        width: '100%',
                    }}>
                        <Carousel style={{
                            height: '311px',
                            width: '100%',
                            color: 'blue'
                        }}>
                            {imgs.map(image => {
                                return(
                                    <Carousel.Item style={{
                                        height: '311px',
                                        width: '100%'
                                    }}>
                                        <img style={{ height: '100%', width: 'inherit', objectFit: 'contain' }} src={image} alt='image' />
                                    </Carousel.Item>
                                )
                            })}
                        </Carousel>
                        {/* <div
                        style={{
                            width: '70%'
                        }}>
                            <img src={house1} alt='image-1'
                            style={{
                                height: '100%',
                                width: '100%',
                                overflowY: 'hidden',
                                borderRadius: '5px'
                                // objectFit: 'contain',
                            }}/>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                            width: '30%',
                            height: '311px',
                        }}>
                            <img src={house2} alt='image-2' style={{
                                height: '48%',
                                width: '100%',
                                borderRadius: '5px'
                                // objectFit: 'contain',
                            }}/>
                            <img src={house3} alt='image-3' style={{
                                height: '48%',
                                width: '100%',
                                overflowY: 'hidden',
                                borderRadius: '5px'
                                // objectFit: 'contain',
                            }}/>
                        </div> */}
                    </div>
                    <div className='section'>
                        <h4 className='blue'>
                            {state.property.title}
                        </h4>
                        <p>{state.property.propertyDetail.address}</p>                    
                    </div>
                    <div className='section'>
                        <h3 className='blue' style={{fontWeight: '600'}}>
                            ${state.property.price}
                        </h3>
                        <p>Selling Price</p>
                    </div>
                    <div className='section'>
                        <h4 className='blue bold'>Property Full Details</h4>
                        <div style={{
                            marginTop: '2rem'
                        }}>
                            
                        </div>
                        <div style={{ 
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                            marginTop: '1.5rem'
                        }}>
                            <div>
                                <h4 className='blue' style={{ fontSize: '16px' }}>Legal Description</h4>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque repudiandae rerum vitae omnis quis beatae laborum corrupti voluptas quod nihil, soluta accusantium? Officiis quasi voluptatem illo harum, est, fugiat labore assumenda beatae quis quas temporibus nulla, corrupti suscipit minus nostrum at. Facere consequuntur architecto nesciunt nihil voluptatem libero? Eveniet officia eum quibusdam itaque inventore voluptas et est rerum deleniti, amet, eligendi corporis! Nisi soluta, odit doloribus architecto nemo asperiores at voluptas commodi. Consectetur laborum quam inventore atque similique ullam expedita deserunt, voluptatum doloremque excepturi dolores aspernatur omnis non nobis accusamus id dolorem aperiam voluptatibus aliquam sapiente perspiciatis reprehenderit ratione. Totam!</p>
                            </div>
                            <div>
                                <h4 className='blue' style={{ fontSize: '16px' }}>Lot Irregularities</h4>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque repudiandae rerum vitae omnis quis beatae laborum corrupti voluptas quod nihil, soluta accusantium? Officiis quasi voluptatem illo harum, est, fugiat labore assumenda beatae quis quas temporibus nulla, corrupti suscipit minus nostrum at. Facere consequuntur architecto nesciunt nihil voluptatem libero? Eveniet officia eum quibusdam itaque inventore voluptas et est rerum deleniti, amet, eligendi corporis! Nisi soluta, odit doloribus architecto nemo asperiores at voluptas commodi. Consectetur laborum quam inventore atque similique ullam expedita deserunt, voluptatum doloremque excepturi dolores aspernatur omnis non nobis accusamus id dolorem aperiam voluptatibus aliquam sapiente perspiciatis reprehenderit ratione. Totam!</p>                               
                            </div>
                        </div>
                       
                    </div>
                    <div className='section'>
                        
                        <div style={{
                            marginTop: '2rem'
                        }}>
                         {
                                //Object.keys(propertyDetailValue).forEach(([key, value]) => {

                                    Object.keys(propertyDetailValue).map(key =>  {
                                    if(propertyDetailValue[key] != ''){
                                 return(<>
                            <div className='tabbed-details'>
                                <p className='blue bold fixed-length-p'>{key}</p><p>{propertyDetailValue[key]}</p>
                            </div>

                             </>)
                                }  
                                 })}
                            
                        </div>
                    </div>
                    
                    
                    <div className='section'>
                        <h4 className='blue'>Property Description</h4>
                        <p>{state?.propertyDetail?.propertyDescription}</p>
                    </div>
                </div>
            </div>
            {isListed === true ? (
                <Modal
                show={true}
                // onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                >
                    <Modal.Body>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '18px'
                        }}>
                            <span className='red' style={{ fontWeight: '600' }}>Thank You!</span>
                            <span style={{ textAlign: 'center' }}>We'll review the property you listed and get back to you as soon as we can!</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button 
                        variant="primary" 
                        onClick={() => {
                                navigate('/home')
                                dispatch(reset())
                            }
                        }>OK</Button>
                    </Modal.Footer>
                </Modal>
            ) : null}
        </div>
    )
}

export default ListMyProperty