import React,{useEffect} from 'react'
import logo from '../assets/svgs/PW-Logo-Horizontal.svg'
import Listings from '../pages/Listings'
import Auction from '../pages/Auction'
import Draft from '../pages/Draft'
import MapView from '../pages/MapView'
import Dashboard from '../pages/Dashboard'
import Messages from '../pages/Messages'
import Profile from '../pages/Profile'
import Settings from '../pages/Settings'
import Notifications from '../pages/Notifications'
import AddPropertyImages from '../pages/AddPropertyImages'
import featureListing from '../assets/images/Feature Listing@2x.png'
import {MdOutlineSpaceDashboard} from 'react-icons/md'
import {AiOutlineHome} from 'react-icons/ai'
import {MdMailOutline} from 'react-icons/md'
import {BiBell} from 'react-icons/bi'
import {FiSettings, FiUser, FiHelpCircle} from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
function Navbar() {
      const navigate = useNavigate()
    const { user } = useSelector((state) => state.auth)
    
   
    const [ currentTab, setCurrentTab ] =  React.useState('Listings')
   
    const locationVal = useLocation()
    const { state } = locationVal;


     React.useEffect(() => {
        if (user === null){
            navigate('/')
        }
    }, [user])

    useEffect(() => {
        
       if(state == 'listings'){
            tabSel('Listings')
        }
        

    }, [state])
   
    const tabSel = (name) => {
        
        setCurrentTab(name)
    }

    const searchList = (name) => {
            navigate('/search')
    }


    return (
        <div style={{
            display: 'flex'
        }}>
            <div
            style={{
                width: '20%',
                backgroundColor: 'white',
                height: '100vh',
                position: 'fixed',
                top: '0',
                left: '0',
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                paddingTop: '3rem',
                paddingBottom: '3rem',
                paddingLeft: '2.5rem',
                paddingRight: '1rem'
            }}>
                <img src={logo} alt='logo'
                style={{
                    width: '191px',
                    height: '30px',
                    marginBottom: '2rem'
                }}/>
                <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                    fontWeight: '450',
                    fontSize: '14px'
                }}>
                    <span className='navbar-option' id='Dashboard' onClick={() => setCurrentTab('Dashboard')}
                    style={{
                        backgroundColor: currentTab === 'Dashboard' ? '#1761B0' : null,
                        color: currentTab === 'Dashboard' ? 'white' : null,
                        paddingLeft: currentTab === 'Dashboard' ? '1.5rem' : null,
                        display: 'flex',
                        gap: '1.5rem'
                    }}>
                        <MdOutlineSpaceDashboard />
                        Dashboard</span>
                    <span className='navbar-option' id='Listings' onClick={() => setCurrentTab('Listings')}
                    style={{
                        backgroundColor: currentTab === 'Listings' ? '#1761B0' : null,
                        color: currentTab === 'Listings' ? 'white' : null,
                        paddingLeft: currentTab === 'Listings' ? '1.5rem' : null,
                        display: 'flex',
                        gap: '1.5rem'
                    }}>
                        <AiOutlineHome />
                        Listings
                    </span>

                    

                            <div style={{display: currentTab === 'Listings'  || currentTab === 'Draft' || currentTab === 'Auction' ? 'block' : 'none',
                            paddingLeft: '40px'}}>
                                <span className='navbar-option' id='Auction' onClick={() => setCurrentTab('Auction')}
                                style={{
                                    backgroundColor: currentTab === 'Auction' ? '#1761B0' : null,
                                    color: currentTab === 'Auction' ? 'white' : null,
                                    paddingLeft: currentTab === 'Auction' ? '1.5rem' : null,
                                    display: 'flex',
                                    gap: '1.5rem'
                                }}>
                                    <AiOutlineHome />
                                    Auction</span>

                                <span className='navbar-option' id='Draft' onClick={() => setCurrentTab('Draft')}
                                style={{
                                    backgroundColor: currentTab === 'Draft' ? '#1761B0' : null,
                                    color: currentTab === 'Draft' ? 'white' : null,
                                    paddingLeft: currentTab === 'Draft' ? '1.5rem' : null,
                                    display: 'flex',
                                    gap: '1.5rem'
                                }}>
                                    <AiOutlineHome />
                                    Draft</span>
                            </div>
                        


                    

                    <span className='navbar-option' id='MapView' onClick={() => searchList('MapView')}
                    style={{
                        backgroundColor: currentTab === 'MapView' ? '#1761B0' : null,
                        color: currentTab === 'MapView' ? 'white' : null,
                        paddingLeft: currentTab === 'MapView' ? '1.5rem' : null,
                        display: 'flex',
                        gap: '1.5rem'
                    }}>
                        <AiOutlineHome />
                        Map View</span>
                    <span className='navbar-option' id='Messages' onClick={() => setCurrentTab('Messages')}
                    style={{
                        backgroundColor: currentTab === 'Messages' ? '#1761B0' : null,
                        color: currentTab === 'Messages' ? 'white' : null,
                        paddingLeft: currentTab === 'Messages' ? '1.5rem' : null,
                        display: 'flex',
                        gap: '1.5rem'
                    }}>
                        <MdMailOutline />
                        Messages</span>
                    
                    <span className='navbar-option' id='Settings' onClick={() => setCurrentTab('Settings')}
                    style={{
                        backgroundColor: currentTab === 'Settings' ? '#1761B0' : null,
                        color: currentTab === 'Settings' ? 'white' : null,
                        paddingLeft: currentTab === 'Settings' ? '1.5rem' : null,
                        display: 'flex',
                        gap: '1.5rem'
                    }}>
                        <FiSettings />
                        Settings</span>
                    <span className='navbar-option' id='Profile' onClick={() => setCurrentTab('Profile')}
                    style={{
                        backgroundColor: currentTab === 'Profile' ? '#1761B0' : null,
                        color: currentTab === 'Profile' ? 'white' : null,
                        paddingLeft: currentTab === 'Profile' ? '1.5rem' : null,
                        display: 'flex',
                        gap: '1.5rem'
                    }}>
                        <FiUser />
                        Profile</span>
                    
                </div>
                <img src={featureListing} alt='feature-listing'
                style={{
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer'
                }} />
            </div>
            {currentTab === 'Listings' ? <Listings  /> : 
                currentTab === 'Auction' ? <Auction /> : 
                currentTab === 'Draft' ? <Draft /> : 
            (currentTab === 'Dashboard' ? <Dashboard tabSel={tabSel} /> : 
            (currentTab === 'Messages' ? <Messages /> : 
            (currentTab === 'MapView' ? <MapView /> : 
            (currentTab === 'Profile' ? <Profile /> :
            (currentTab === 'Settings' ? <Settings /> :
            (currentTab === 'AddPropertyImages' ? <AddPropertyImages /> :
            (currentTab === 'Notifications' ? <Notifications /> :
            
            null)))))))}
        </div>
    )
}

export default Navbar