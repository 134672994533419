import React,{useState,useEffect} from 'react';
import { HStack,
	  Box,
	  Image,
	  Text,
	  Button } from '@chakra-ui/react';
import { Link,useNavigate } from "react-router-dom";
import "../../index.css";
import { HamburgerIcon,BellIcon} from '@chakra-ui/icons'
import { FaSignOutAlt } from "react-icons/fa";
import Header_logo from "../../assets/svgs/PW-Logo-Horizontal.svg"
import { useDisclosure } from '@chakra-ui/react'
import { FaUserCircle } from "react-icons/fa";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react'


function AdminHeader(){
	const navigate  = useNavigate();
	const [user, setUser] = useState('');
	const Logout = () => {
		localStorage.removeItem('user-admin');
		navigate('/admin')

	}

	useEffect(() => {
        GetToken();

        

    },[]);

	const { isOpen, onOpen, onClose } = useDisclosure()
  	const btnRef = React.useRef()


  	const GetToken =()=> {
			const token = localStorage.getItem('user-admin');

	    if (token) {
	      const decodedToken = decodeToken(token);
	      const user = decodedToken.UserAdminData;
	       setUser(user)

	    }
		}

	 const decodeToken = (token) => {
	    const decodedString = atob(token.split('.')[1]);
	    const decodedObject = JSON.parse(decodedString);
	    // console.log(decodedObject)
	    return decodedObject;
	  };

	return(
		<>
		<HStack className="admin_header_section">
			<Box>
				<HStack>
					<a href="/admin/dashboard">					
						<Image src={Header_logo} w="191px"  h="29.95px"  />	
					</a>
				</HStack>			
			</Box>

			<Menu>
			  <MenuButton as={Button} className="header_user_section">
			    <HStack>
			    	<Box>
			    		<Text fontSize="14px" fontFamily="Urbanist" fontWeight="800" textAlign="right" mb="0px">{user.name}</Text>
			    		<Text fontSize="14px" fontFamily="Urbanist" fontWeight="400" textAlign="right"  mb="0px">{user.email}</Text>
			    	</Box>

			    	<Box>
			    		
				    	<FaUserCircle
	                cursor="pointer"
	                fontSize="30px"
	                color="#012169"
	            />
			    	</Box>
			    </HStack>
			  </MenuButton>
			  <MenuList onClick={()=> Logout()}>
			    <MenuItem>Logout</MenuItem>
			  </MenuList>
			</Menu>
		</HStack>
		</>
	);
}

export default AdminHeader;