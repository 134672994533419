import React,{useEffect,useState} from 'react';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Link,
  useDisclosure,
  Spinner
} from '@chakra-ui/react'
import { fetchWrapper } from '../../../helpers';
import Loader from "../../../components/Loader";
import CustomModal from "../../../components/CustomModal";
import CustomModalLink from "../../../components/CustomModalLink";
import Create from "./Create";
import Edit from "./Edit";
import { BsPencil, BsFillTrashFill } from "react-icons/bs";


function MainSetup({rs}) {

 	const { isOpen, onOpen, onClose } = useDisclosure();
  	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
  	const [isOpenEdit,setIsOpenEdit] = useState(false)
  	const [modalEditView,setModalEditView] = useState(false)   

	const [list,setList]=useState([]);
	const [spinner, setSpinner] = useState(false);

	const meClose = () => {
	      
	       onClose();
	       
	  }
	  const meCloseEdit = () => {
	      
	       setIsOpenEdit(false);
	       
	  }
	useEffect(() => {
        LoadList(rs);
    }, [rs]);

    const LoadList= async (rs) => {
 		setSpinner(true)
		const url = `${process.env.REACT_APP_BASE_URL}/master/listed-by-type?master_type=${rs?.master_type}`;
		const data  = await fetchWrapper.get(url)
      	setList(data.data);
    	setSpinner(false)     
  	}

  	 const meEdit = (rs) => {
	    setIsOpenEdit(true);
	    setModalEditView(<Edit rs={rs} meCloseEdit={meCloseEdit}  loadList={LoadList} />)   
	  }

  	const meDelete = async(rs)=>{
  		const aa = window.confirm(`Are you sure delete this ${rs?.name}`);
  		if(aa){
			const url = `${process.env.REACT_APP_BASE_URL}/master/delete/${rs.id}`;
			await fetchWrapper.delete(url)
			LoadList(rs)
  		}
  	}

	return(
		<>
			<CustomModalLink
	        showModalButtonText={`Edit ${rs?.master_type}`}
	        modalHeader={`Edit ${rs?.master_type}`}
	        isOpenEdit={isOpenEdit}
	        onCloseEdit={meCloseEdit}
	        onOpenEdit={onOpenEdit}   
	        modalBody={modalEditView}
	      />
		{spinner && <Loader /> }


	 	<Box>
	        
      	</Box>

      	<Box>
            <HStack justifyContent="space-between">
              <Box className="dashboard_page">
                <Text className="main_heading" mb="0px">{rs?.master_type}</Text>
              </Box>

              <Box>
                <CustomModal
		          showModalButtonText={`Create ${rs?.master_type}`}
		          modalHeader={`Create ${rs?.master_type}`}
		          isOpen={isOpen}
		          onClose={onClose}
		          onOpen={onOpen}
		          modalBody=<Create meClose={meClose} loadList={LoadList} rs={rs} />
	        />
              </Box>
            </HStack>
          </Box>


		<Box className="table_section">
          <TableContainer>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Sort</Th>          
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
              	{list?.map((rs,index)=>{
              		return(
              		<>
              			<Tr key={index}>
              				<Td>{rs.name}</Td>
              				<Td>{rs.sort_order}</Td>
              				<Td>
              					<HStack mb="5px">
			                       <a onClick={() => meEdit(rs)}><BsPencil /></a>  
			                        <a onClick={() => meDelete(rs)}><BsFillTrashFill /></a>
			                    </HStack>
              				</Td>
              			</Tr>
              		</>
              		)
              	})}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
		 
		</>
	)
}

export default MainSetup;	