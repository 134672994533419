import signUpBg from '../assets/images/pexels-essow-936722.png'
import mainLogo from '../assets/svgs/PW-Logo-Signup.svg'

function AuthPageFormat() {
  return (
    <div
    style={{
        color: 'white',
        backgroundImage: `url(${signUpBg})`,
        backgroundRepeat: 'no-repeat',
        // width: '28rem',
        // height: '100%',
        backgroundPosition: 'center center',
        // backgroundSize: '100% 100%',
        display: 'flex',
        justifyContent: 'center'
    }}
    className='side-image'>
        <img 
        className='side-image-logo'
            src={mainLogo}
            style={{
                width: '20rem',
                // height: '8rem',
                marginTop: '5rem'
            }}
            alt='logo'
        ></img>
    </div>
  )
}

export default AuthPageFormat