import React from 'react'

function TextInputComponent(props) {
    const { label, id, style, onChange, onBlur, error, required, blueHeader, textArea, 
    disabled, value, fontSize,placeholder } = props;

    return (
        <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
            width: '368px',
            ...style
        }}>
            <h5
            style={{
                fontSize: '14px',
                fontWeight: blueHeader ? '550' : '400',
                color: blueHeader ? '#1761B0' : '#848484',
                marginBottom: blueHeader ? '0' : ''
            }}>{label}{required === true ? (<span className='red'>*</span>) : null}</h5>
            
            {textArea ? (
                <textarea id={id} 
                onChange={(e) => {
                    onChange(e)
                }} 
                rows={5} className='input-text' style={{ height: 'auto', fontSize: '14px', paddingTop: '0.7rem' }}></textarea>
            ) : (
                <input 
                style={{
                    fontSize: fontSize ? fontSize : 'auto'
                }}
                placeholder={placeholder}
                value={value ? value : null}
                disabled={disabled ? true : false}
                className='input-text' 
                id={id} 
                onChange={(e) => {
                    onChange(e)
                }} 
                onBlur={onBlur}/>
            )}
            {error !== false ? (<div className='error-msg'>{error}</div>) : null}
        </div>
    )
}

export default TextInputComponent