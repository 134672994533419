import React from 'react'
import GreyPage from '../components/GreyPage';
import anon from '../assets/images/kisspng-user-profile-computer-icons-image-default-daniela-klasn-martin-societe-generale-securiti-5cca4fa5e3c847.179087381556762533933.png';
import { BsFillHouseDoorFill , BsFillBarChartLineFill , BsHammer , BsChatLeftFill, BsThreeDots } from "react-icons/bs";
import { useDispatch, useSelector } from 'react-redux'
import { getDashboard,getMonthCount,getLiveAuction } from '../features/property/propertySlice'
import { useNavigate } from 'react-router-dom'
import { useAppContext } from "../context/ChatProvider";
import { getSender, getSenderFull } from "../config/chat";
import ListChart from '../components/chart/ListChart'
import Countdown from "react-countdown";
function Dashboard({tabSel}) {

  const navigate = useNavigate()
  const {user} = useAppContext();
  const dispatch = useDispatch()

  const { dashboardData,monthCount,liveAuction } = useSelector((state) => state.property)

  React.useEffect(() => {
        
        dispatch(getDashboard())
        dispatch(getMonthCount())
        dispatch(getLiveAuction())
        

    }, [])
const getInitials = function (string) {
        var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
        
        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    const Completionist = () => <span>Auction End!</span>;

// Renderer callback with condition
const renderer = ({ days,hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <span>
        {days > 0 ? (`${days} days and ${hours}:${minutes}:${seconds}`) : (`${hours}:${minutes}:${seconds}`)
        
        }
      </span>
    );
  }
};



  return (
    <div style={{width: '100%'}}>
      <div 
      className='grey-background-home' 
      style={{ 
          width: '79%', 
         
          marginLeft: '21%', 
          paddingRight: '1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem',
          paddingLeft: '2%',

      }}>
        <GreyPage heading='Dashboard' />

        <div
                  style={{
                    display: 'flex',
                    gap: '2rem'
        }}>
          
            <div style={{width:'70%'}}>
              <div className="dashboard_box">
                <ul>
                  <li>
                    <div className="listing_box_section">
                      <div className="icon_box">
                        <BsFillHouseDoorFill />
                      </div>
                      
                      <div className="text_section">
                        <p>All Listings</p>
                        <h4>{dashboardData?.allCount}</h4>
                        <a className="view_link" href="#" onClick={() => tabSel('Listings')}>View All</a>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="listing_box_section">
                      <div className="icon_box" style={{ backgroundColor: 'rgba(23, 97, 176, 0.2)' }}>
                        <BsFillBarChartLineFill style={{ color: 'rgba(23, 97, 176, 1)' }} />
                      </div>
                      
                      <div className="text_section">
                        <p>For Sale</p>
                        <h4>{dashboardData?.saleCount}</h4>
                        <a className="view_link" href="#" onClick={() => tabSel('Listings')}>View All</a>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="listing_box_section">
                      <div className="icon_box" style={{ backgroundColor: 'rgba(210, 40, 45, 0.2)' }}>
                        <BsHammer style={{ color: 'rgba(210, 40, 45, 1)' }} />
                      </div>
                      
                      <div className="text_section">
                        <p>Auction</p>
                        <h4>{dashboardData?.auctionCount}</h4>
                        <a className="view_link" href="#" onClick={() => tabSel('Auction')}>View All</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="dashboard_box chart_section">
                <div className="heading_row_section">
                  <div className="heading_section">
                    <h2>Listing views</h2>

                  </div>

                  <div className="filter_section">
                    <p>Filter :</p>
                    <div className="select_field_section">
                      <select>
                        <option>Monthly</option>
                        <option>Yearly</option>
                        <option>Weekly</option>
                      </select>
                    </div>
                    <div className="select_field_section">
                      <select>
                        <option>For Sale</option>
                        <option>For Sale</option>
                        <option>For Sale</option>
                      </select>
                    </div>
                  </div>


                </div>
                {monthCount ?  <ListChart monthCount={monthCount} /> : null}
                
              </div>
            </div>
            <div style={{width:'30%'}}>
              <div className="message_box_section">
                <div className="message_list_section">
                  <h3>Recent messages</h3>
                  
                  {dashboardData?.chat?.map(rs => {


                 return(
                  <div className="main_user_box">
                    <div className="user_box_name">
                      <h3>{getInitials(getSender(user, rs.fromUser,rs.toUser))}</h3>
                    </div>

                    <div className="text_section">
                      <h2>{getSender(user, rs.fromUser,rs.toUser)}</h2>
                      <p>Realtor</p>
                    </div>

                    <div className="icon_box" onClick={() => tabSel('Messages')}>
                      <BsChatLeftFill />
                    </div>
                  </div>
                  )
                   })}
                 

                  
                </div>
                
                <div className="link_section">
                  <a href="#" className="view_message" onClick={() => tabSel('Messages')}>View all messages</a>
                </div>
              </div> 
            </div>
        </div>
        {
          liveAuction ? (
        <div className="dashboard_box auction_box_section">
          <div className="heading_row_section">
            <h2>Live Auction</h2>
            <BsThreeDots />
          </div>
       
          <div className="auction_list_section">
            <ul>
              <li>
                <div className="auction_box">
                  <h3>{liveAuction?.liveAuction?.title}</h3>
                  <a href="#" onClick={() => navigate(`/property/${liveAuction?.liveAuction?.id}`, {state: ''})}>View property details</a>
                </div>
              </li>

              <li>
                <div className="auction_box">
                  <h3 className="blue">{liveAuction?.liveAuction?.price}</h3>
                  <p>Starting</p>
                </div>
              </li>

              <li>
                <div className="auction_box">
                  <h3 className="blue">${liveAuction?.detail[0]?.price} </h3>
                  <p>Current bid</p>
                </div>
              </li>

              <li>
                <div className="auction_box">

                  <h3 className="red"><Countdown date={new Date(liveAuction?.liveAuction?.auctionEndTime + ' 00:00:00') } renderer={renderer} /></h3>
                  <p>Auction ends</p>
                </div>
              </li>
            </ul>
          </div>

          <div>
            <table className="table">
              <tr>
                <th>
                  <h3>Highest bidders</h3>
                </th>

                <th>
                  <h3>Date</h3>
                </th>

                <th>
                  <h3>Amount</h3>
                </th>

                <th>
                  <h3>Message</h3>
                </th>
              </tr>
              {liveAuction?.detail?.map(rs => {


                 return(
              <tr>
                <td>
                  <div className="table_user_box">
                    <img src={anon} alt='anon' />
                    <h3>{rs.User.fullName}</h3>
                  </div>
                </td>

                <td>
                  <p>16/09/21</p>
                </td>

                <td>
                  <p className="red">$ {rs.price}</p>
                </td>

                <td>
                  <p className="blue">Send message</p>
                </td>
              </tr>
              )
                   })}

              
            </table>
          </div>
         
        </div>

        ) : null
        }
      </div>
    </div>
  )
}

export default Dashboard