import React,{useState,useEffect} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
function ListChart(props) {
    const {monthCount} = props
    
   const options = {
  title: {
    text: 'Views'
  },
  chart: {
    type: 'column'
   },
    xAxis: {
        min: 0,
    type: 'category',
    title: {
        text: 'Month'
    },
    labels: {
       
        style: {
            fontSize: '13px',
            fontFamily: 'Verdana, sans-serif'
        }
      }
   },
   yAxis: {
    min: 0,
    title: {
        text: 'Views'
    }
   },
    series: [{
    name: 'Month',
   
    data: (function() {
              
            

               const data = monthCount.map(row => {
                
                return [row.month, row.count * 1]
              })
               console.log(data)
              return data;
            })()
          
    

 }]
}

    return (
        <>
        
       <HighchartsReact
    highcharts={Highcharts}
    options={options}
  />
  </>
    )
}

export default ListChart