import React from 'react'


function PreConHouse({InputText,SelectField,formik,SelectComponent,Country,State,City,filterData,TextInputComponent,CheckBoxGen,

  LeaseLength,
        ParkingSpot,
        ParkingType,
        LaundryType,
        PetFriendly,
        Amenities,
        PropertyTypeCon,
        PropertyCompletion,
        PropertyStyle,
        loadCity
}) {
    return (
        <>
        
           <div className='inputs-area'>
                         <SelectField  fieldRequired={true} name="property.propertyDetail.country" list={Country} title="Country" />
                        {/*<SelectComponent required={true} 

                          onChange={

                            e => {
                                         
                                            formik.handleChange(e)
                                            loadCity(e.target.value)

                                        }}

                         onBlur={formik.handleBlur}  value={formik.values.property.propertyDetail['state']} id='property.propertyDetail.state' label='State' options={State} />*/}
                        
                        <SelectField  fieldRequired={true} name="property.propertyDetail.state" list={State} title="Province" />
                        <SelectField  fieldRequired={true} name="property.propertyDetail.city" list={City} title="City/Town" />
                        
                        
                        
                        
                        </div>
                        <div className='inputs-area'>
                          <div style={{width:'368px'}}>
                            <InputText name="property.propertyDetail.builder" fieldRequired={true} title="Builder(s)" />
                          </div>
                          <div style={{width:'368px'}}>
                            <InputText name="property.propertyDetail.developer" fieldRequired={true} title="Developers" />
                          </div>
                          <div style={{width:'368px'}}>
                            <InputText name="property.propertyDetail.neighbourhood" fieldRequired={true} title="Neighbourhood" />
                          </div>
                        </div>

                         <div className='inputs-area'>
                         <div style={{width:'368px'}}>
                            <InputText name="property.propertyDetail.address" fieldRequired={true} title="Address" />
                          </div>
                          <div style={{width:'368px'}}>
                            <InputText name="property.propertyDetail.nameOfProject" fieldRequired={true} title="Name Of Project" />
                          </div>
                          
                        </div>

                        <div className='inputs-area'>
                          <div style={{width:'368px'}}>
                            <InputText name="property.propertyDetail.noOfHome" title="# of homes" fieldRequired={true} />
                          </div>
                          <div style={{width:'368px'}}>
                            <InputText name="property.propertyDetail.noOfPhase" title="# of phases" fieldRequired={true} />
                          </div>
                          
                          
                        

                          <CheckBoxGen limit={2}  onChange={formik.handleChange} onBlur={formik.handleBlur} label='Style' id='property.propertyDetail.propertyStyle' options={[
                              {label: 'Attached', id: 'Attached'},
                              {label: 'Row', id: 'Row'},
                              {label: 'Town House', id: 'Town House'},
                              {label: 'Semi Detached', id: 'Semi Detached'},
                              {label: 'Detached', id: 'Detached'},
                              
                            ]} />

                        </div>
                        
                        <div className='inputs-area'>
                          <SelectField  fieldRequired={true} name="property.propertyDetail.propertyType" list={filterData('PropertyTypeCon')} title="Type" />
                          <div style={{width:'368px'}}>
                            <InputText name="property.propertyDetail.bedroom" title="Bedrooms" fieldRequired={true} />
                          </div>
                          <div style={{width:'368px'}}>
                            <InputText name="property.propertyDetail.squareFeet" title="Square feet"  fieldRequired={true}/>
                          </div>
                        </div>
                        

                        <div className='inputs-area'>
                          <SelectField  fieldRequired={true} name="property.propertyDetail.approxCompletion" list={PropertyCompletion} title="Approximate completion" />
                          
                          
                          
                        </div>

                        
                      </>
      )
}

export default PreConHouse