import React,{useState,useEffect} from 'react'
import HeaderTemplate from '../components/HeaderTemplate'
import Button from 'react-bootstrap/Button';
import SelectComponent from '../components/SelectProComponent'
import TextInputComponent from '../components/TextInputComponent'
import CheckBoxGen from '../components/CheckBoxGen'

import { addProperty,updateProperty,reset } from '../features/property/propertySlice'

///Schema
import addPropertySchema from '../utils/validationSchema/addPropertySchema';
import HouseSaleMNSchema from '../utils/validationSchema/property/HouseSaleMNSchema';
import PreConHouseSchema from '../utils/validationSchema/property/PreConHouseSchema';


import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router';
import { Formik, Form, useFormik } from 'formik'
import MapPicker from 'react-google-map-picker'
import VacantLand from './PropertyForm/VacantLand'
import HouseSaleMN from './PropertyForm/HouseSaleMN'
import HouseSaleMY from './PropertyForm/HouseSaleMY'
import CondosRent from './PropertyForm/CondosRent'
import HouseRent from './PropertyForm/HouseRent'
import PreConHouse from './PropertyForm/PreConHouse'
import PreConCondos from './PropertyForm/PreConCondos'
import { fetchWrapper } from '../helpers';
import { useNavigate } from 'react-router-dom'

import {InputText,SelectField,TextArea,InputDateField} from '../components/form'
const DefaultLocation = { lat: 43.651070, lng: -79.347015};
const DefaultZoom = 5;

function AddProperty(props) {
  const navigate = useNavigate();
  const { saleMethod } = useParams();



  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);



  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])
  const [list, setList] = useState([])

  React.useEffect(() => {
       
        loadState();
        loadCity(0);
        loadList()
    }, [])

  const loadState = async() => {

        const url = `${process.env.REACT_APP_BASE_URL}/master/state-list`;
        const data  = await fetchWrapper.get(url)
        setStateList(data);
    }

    const loadCity = async(state_id) => {

         const url = `${process.env.REACT_APP_BASE_URL}/master/city-list?state_id=${state_id}`;
        const data  = await fetchWrapper.get(url)
        setCityList(data);
    }

    const loadList = async ()=>{
      const url = `${process.env.REACT_APP_BASE_URL}/master`
      const data = await fetchWrapper.get(url)
      setList(data.data)
    }

  function handleChangeLocation (lat, lng){

    setLocation({lat:lat, lng:lng});
    formik.values['property']['location'] = {lat:lat, lng:lng}
  }
  
  function handleChangeZoom (newZoom){
    setZoom(newZoom);
  }

  function handleResetLocation(){
    setDefaultLocation({ ... DefaultLocation});
    setZoom(DefaultZoom);
  }


  
  const formik = useFormik({
    initialValues: {
      property:{
        
        saleMethod: saleMethod,
        saleType:"SALE",
        maintenance:"",
        auctionTime:"",
        title:"",
        price:"",
        mode:'add',
        location:location,
        propertyType:'',
        signatureFirst:"",
        signatureSecond:"",
        propertyDescription:"",
        date:"",
        date1:"",

        propertyDetail: {
            country:"",
            state:"",
            city:"",
            address: "",
            ownerName:"",
            price:0,
            zoning:"",
            propertySize:"",
            waterFront:"",
            lotFrontage: "",
            lotDepth: "",
            waterSupplyTypes:"",
            sewers:"",
            utilities:"",
            possesionDate:"",
            rentalType:"",
            fronting:"",
            pool:"",
            legalName:"",
            
            legalDescription:"",
            bedroom:"",
            kitchen:"",
            washroom:"",
            squareFeet:"",
            basement:"",
            parking:"",
            garageType:"",
            garageParking:"",
            heat:"",
            heatType:"",
            airCondition:"",
            propertyTax:"",
            pool:"",
            firePlace:"",
            amenities:"",
            bathroom:"",
            rentalType:"",
            streetNo:"",
            street:"",
            dateAvailable:"",
            leaseLength:"",
            parkingSpot:"",
            parkingType:"",
            laundryType:"",
            petFriendly:"",
            amenities:"",
            builder:"",
            developer:"",
            storey:"",
            unitNumber:"",
            condo_unit:"",
            projectPhase:"",
            approxCompletion:"",
            approxMaintenanceFee:"",
            neighbourhood:"",
            noOfHome:"",
            noOfPhase:"",
            propertyStyle:"",
            
            nameOfProject:"",
          },
      },
      
      propertyFile: [

      ],
      
      

    },
    
  })

  const [rooms, setRooms] = React.useState([])


  const dispatch = useDispatch()

  
      const PropertyCompletion = [{value:"2023",text:"2023"},{value:"2024",text:"2024"},
            ,{value:"2024",text:"2024"}
            ,{value:"2024",text:"2024"}
            ,{value:"2025",text:"2025"}
            ,{value:"2026",text:"2026"}
            ,{value:"2027",text:"2027"}
            ,{value:"2028",text:"2028"}
            ,{value:"2029",text:"2029"}
            ,{value:"2030",text:"2030"}
            ,{value:"2031",text:"2031"}
            ,{value:"2032",text:"2032"}
            ,{value:"2033",text:"2033"}

          ]


      const ProjectPhase = [{value:"1",text:"1"},{value:"2",text:"2"},
            ,{value:"3",text:"3"}
            ,{value:"4",text:"4"}
            ,{value:"5",text:"5"}
            ,{value:"6",text:"6"}
            ,{value:"7",text:"7"}
            ,{value:"8",text:"8"}
            ,{value:"9",text:"9"}
            

          ]

      const { RentalType,Bedroom,Zoning,Fronting,
        Bathroom,SquareFeet,LeaseLength,ParkingSpot,ParkingType,LaundryType,Amenities,
        Kitchen,Washroom,Basement,Parking,GarageType,GarageParking,Heat,HeatType,PropertyTax,
        Pool,FirePlace,PropertySize,masterData,PropertyStyle,PropertyTypeCon


       } = useSelector((state) => state.master)
   
    const City = cityList;
    const State = stateList;
    const Country = [{value:1, text:'Canada'}];

    const AirCondition = [{value:"Yes",text:"Yes"},{value:"No",text:"No"}]
    const PetFriendly = [{value:"Yes",text:"Yes"},{value:"No",text:"No"}]
    
    const filterData = (type) => {
      return  list.filter(rs => rs.master_type == type);
    }


  const initializeArray = noOfRooms => {
    const array = [];
    for (var i=0; i<noOfRooms; i++) {
      array.push(
        {
          level: "string",
          room: 1,
          length: 1.1,
          width: 1.1,
          descriptionOne: "string",
          descriptionTwo: "string",
          descriptionThree: "string"
        }
      )
    }
    formik.setFieldValue('propertyRoomDetail', array)
    return(array)
  }


  React.useEffect(() => {
        //console.log(message + 'dd')
        //dispatch(getMasterList('RentalType'))

    }, [])

 

  React.useEffect(() => {
    for (var i=0; i<formik.values.length; i++){
      for (var j=0; j<formik.values[i].length; j++){
        console.log(formik.values[i][j], typeof(formik.values[i][j]))
      }
    }


  }, [formik.values])

  const handleError = id => {
    if (formik.errors?.propertyDetail?.[id] !== undefined) {
      return formik.errors?.propertyDetail?.[id]
    } else {
      return false
    }
  }

  const formSave = (values) => {
      const pData = dispatch(addProperty(values)).then(data => {
        navigate('/edit-property-images/' + data.payload.data.property.id)
        // console.log(data)
      })
      
      alert("Submit")
  }




  return (
    <>
        
        <Formik
            validationSchema={addPropertySchema}
            initialValues={formik.initialValues}
             onSubmit={values => {
             // same shape as initial values
              
               formSave(values);

           }}
        >
          {({values, errors, touched }) => 
            {//console.log("formik: ", formik)
            return (
              <>
               
              <Form 
           
            >
           

           <HeaderTemplate nextScreen='/add-property-images' state={formik.values}/>
           <div
            className='grey-background'
            style={{
              paddingBottom: '10rem'
            }}
           >
              
                <div id='property-details' className='add-section'>

                 
                  <h4 className='blue'>Property Details</h4>
                  <p>Please enter your listing details</p>
                  <div
                  style={{
                    display: 'flex',
                    gap: '2rem'
                  }}>
                   {values.property['saleMethod'] === 'sale' ? 

                   <SelectField 
                   list={[{value: 'SALE', text: 'Sale'}, {value: 'RENT', text: 'Rent'}]} 
                   name="property.saleType" title="What are you selling?"  />
                    : null }
                    
                    {values.property['saleMethod'] === 'auction' && values.property['saleType'] === 'SALE' ? 
                    <SelectComponent required={true} formik={formik} onChange={formik.handleChange} onBlur={formik.handleBlur} id='property.auctionTime' label="Auction Duration (in days)" options={[{value: '', text: ''}, {value: '5', text: '5'}, {value: '7', text: '7'}, {value: '14', text: '14'}, {value: '30', text: '30'}]} /> : null}
                    

                    {values.property['saleType'] === 'SALE' ? 
                    <SelectField 
                   list={[{value: '', text: ''}, {value: 'HOUSES', text: 'House'}, {value: 'townhouse', text: 'Town House'},{value: 'CONDOS', text: 'Condo'}, {value: 'VACANT-LAND', text: 'Vacant Land'},{value: 'pre-construction-house', text: 'pre-Construction/Assignment (House)'},{value: 'pre-construction-condos', text: 'pre-Construction/Assignment (CONDOS)'}]} 
                   name="property.propertyType" title="Property type"  />
                     : 
                     <SelectField 
                   list={[{value: '', text: ''}, {value: 'HOUSES', text: 'House'}, {value: 'BASEMENT', text: 'Basement'},{value: 'CONDOS', text: 'Condo'}, {value: 'ROOM-ONLY', text: 'Room Only'},{value: 'VACANT-LAND', text: 'Vacant Land'},{value: 'OTHER', text: 'Other'}]} 
                   name="property.propertyType" title="Property type"  />
                     }
                    
                    {values.property['propertyType'] === 'townhouse' && values.property['saleType'] === 'SALE' 
                    ? 
                    <SelectField 
                   list={[{value: '', text: ''}, {value: 'Yes', text: 'Yes'}, {value: 'No', text: 'No'}]} 
                   name="property.maintenance" title="Is there any maintenance fee?"  />
                     : null}
                  </div>
                </div>

                <div id='property-location' className='add-section'>


                <div className='inputs-area'>
                  <div style={{width: '368px'}}>
                    <InputText name="property.title" fieldRequired={true}  title="Property Title"  />
                  </div> 
                  <div style={{width: '368px'}}> 
                    <InputText name="property.propertyDetail.ownerName" fieldRequired={true} title="Owner Name"  />
                  </div>
                  <div style={{width: '368px'}}>  
                    <InputText name="property.price"  title="Price"  fieldRequired={true}  />
                  </div>  
                   
                         
                
                  {/*<TextInputComponent required={true} onChange={formik.handleChange} onBlur={formik.handleBlur} label='Owner Name' id='property.propertyDetail.ownerName' />
                  <TextInputComponent required={true} onChange={formik.handleChange} onBlur={formik.handleBlur} label='Price' id='property.price' />*/}
                </div>

                <h4 className='blue'>Property Location</h4>
                  <MapPicker defaultLocation={defaultLocation}
    zoom={zoom}
    mapTypeId="roadmap"
    style={{height:'300px'}}
    onChangeLocation={handleChangeLocation} 
    onChangeZoom={handleChangeZoom}
    apiKey='AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8'/>

                  <h4 className='blue'>Property Details</h4>
                  {values.property['propertyType'] === 'VACANT-LAND' || values.property['propertyType'] === 'ROOM-ONLY' || values.property['propertyType'] === 'BASEMENT' || values.property['propertyType'] === 'OTHER'   ? (
                      <>
                      <VacantLand 
                       SelectComponent={SelectComponent} InputText={InputText} SelectField={SelectField} Country={Country} State={State} formik={formik}
                        filterData={filterData}
                        TextInputComponent={TextInputComponent}
                        City={City}
                        CheckBoxGen={CheckBoxGen}
                        LeaseLength={LeaseLength}
                        ParkingSpot={ParkingSpot}
                        ParkingType={ParkingType}
                        LaundryType={LaundryType}
                        PetFriendly={PetFriendly}
                        Amenities={Amenities}
                        loadCity={loadCity}
                       />
                       
                      </>
                  ) : null}


                  {((values.property['propertyType'] === 'HOUSES' && values.property['saleType'] === 'SALE') ||  (values.property['propertyType'] === 'townhouse' && values.property['maintenance'] == 'No')) ? (
                      <HouseSaleMN 
                        SelectComponent={SelectComponent} InputText={InputText} SelectField={SelectField} Country={Country} State={State} formik={formik}
                        filterData={filterData}
                        TextInputComponent={TextInputComponent}
                        City={City}
                        CheckBoxGen={CheckBoxGen}
                        LeaseLength={LeaseLength}
                        ParkingSpot={ParkingSpot}
                        ParkingType={ParkingType}
                        LaundryType={LaundryType}
                        PetFriendly={PetFriendly}
                        Amenities={Amenities}
                        loadCity={loadCity}
                       />

                  ) : null}

                {((values.property['propertyType'] === 'CONDOS' && values.property['saleType'] === 'SALE') || (values.property['propertyType'] === 'townhouse' && values.property['maintenance'] == 'Yes')) ? (
                      <>

                      <HouseSaleMY
                        SelectComponent={SelectComponent} InputText={InputText} SelectField={SelectField} Country={Country} State={State} formik={formik}
                        filterData={filterData}
                        TextInputComponent={TextInputComponent}
                        City={City}
                        CheckBoxGen={CheckBoxGen}
                        LeaseLength={LeaseLength}
                        ParkingSpot={ParkingSpot}
                        ParkingType={ParkingType}
                        LaundryType={LaundryType}
                        PetFriendly={PetFriendly}
                        Amenities={Amenities}
                        loadCity={loadCity}

                       />
                         
                      </>

                  ) : null}


                  {values.property['propertyType'] === 'CONDOS' && values.property['saleType'] === 'RENT' ? (
                      <>

                      <CondosRent
                        SelectComponent={SelectComponent} InputText={InputText} SelectField={SelectField} Country={Country} State={State} formik={formik}
                        filterData={filterData}
                        TextInputComponent={TextInputComponent}
                        City={City}
                        CheckBoxGen={CheckBoxGen}
                        LeaseLength={LeaseLength}
                        ParkingSpot={ParkingSpot}
                        ParkingType={ParkingType}
                        LaundryType={LaundryType}
                        PetFriendly={PetFriendly}
                        Amenities={Amenities}
                        loadCity={loadCity}
                       />
                         

                        

                        
                        
                      </>

                  ) : null}


                  {values.property['propertyType'] === 'HOUSES' && values.property['saleType'] === 'RENT' ? (
                      <>
                         

                          <HouseRent
                        SelectComponent={SelectComponent} InputText={InputText} SelectField={SelectField} Country={Country} State={State} formik={formik}
                        filterData={filterData}
                        TextInputComponent={TextInputComponent}
                        City={City}
                        CheckBoxGen={CheckBoxGen}
                        LeaseLength={LeaseLength}
                        ParkingSpot={ParkingSpot}
                        ParkingType={ParkingType}
                        LaundryType={LaundryType}
                        PetFriendly={PetFriendly}
                        Amenities={Amenities}
                        loadCity={loadCity}
                       />

                        
                        
                      </>

                  ) : null}


                  {values.property['propertyType'] === 'pre-construction-house' && values.property['saleType'] === 'SALE' ? 


                  (
                      <>
                         

                          <PreConHouse
                        SelectComponent={SelectComponent} InputText={InputText} SelectField={SelectField} Country={Country} State={State} formik={formik}
                        filterData={filterData}
                        TextInputComponent={TextInputComponent}
                        City={City}
                        CheckBoxGen={CheckBoxGen}
                        LeaseLength={LeaseLength}
                        ParkingSpot={ParkingSpot}
                        ParkingType={ParkingType}
                        LaundryType={LaundryType}
                        PetFriendly={PetFriendly}
                        Amenities={Amenities}
                        PropertyStyle={PropertyStyle}
                        PropertyTypeCon={PropertyTypeCon}
                        PropertyCompletion={PropertyCompletion}
                        loadCity={loadCity}
                       />

                        
                        
                      </>

                  ) : null}


                  {values.property['propertyType'] === 'pre-construction-condos' && values.property['saleType'] === 'SALE' ? 



                    (
                      <>
                         

                          <PreConCondos
                        SelectComponent={SelectComponent} InputText={InputText} SelectField={SelectField}  Country={Country} State={State} formik={formik}
                        filterData={filterData}
                        TextInputComponent={TextInputComponent}
                        City={City}
                        CheckBoxGen={CheckBoxGen}
                        LeaseLength={LeaseLength}
                        ParkingSpot={ParkingSpot}
                        ParkingType={ParkingType}
                        LaundryType={LaundryType}
                        PetFriendly={PetFriendly}
                        Amenities={Amenities}
                        PropertyStyle={PropertyStyle}
                        PropertyTypeCon={PropertyTypeCon}
                        PropertyCompletion={PropertyCompletion}
                        ProjectPhase={ProjectPhase}
                        loadCity={loadCity}
                       />

                        
                        
                      </>

                  ) : null}
                  
                </div>
               
                <div id='property-description' className='add-section'>
                  <h4 className='blue'>Property Description</h4>
                  <TextArea name="property.propertyDescription" title=""  className='input-text' />
                  {/*<textarea onBlur={formik.handleBlur} className='input-text' id='property.propertyDescription' style={{ width: '90%', height: '143px' }} onChange={formik.handleChange} ></textarea>*/}
                  
                  <div style={{
                    display: 'flex', gap: '1rem', width: '90%' ,alignItems:"flex-end"
                  }}>
                  <div style={{width: '75%'}}>
                    <InputText name="property.signatureFirst" fieldRequired={true} title="Signature 1 (please type your name)"  />
                  </div>
                   
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', width: '25%' }}>
                      {/*<h5 style={{ color: '#848484', fontWeight: '400' }}>Date</h5>*/}
                      {/*<input onChange={formik.handleChange} onBlur={formik.handleBlur} type='date' className='input-text' label='Date' id='' style={{ width: '100%' }} />*/}
                      <InputDateField inputProps={{type:'date'}}  name="property.date" title="Date"  />
                    </div>
                    
                  </div>
                  <div style={{
                    display: 'flex', gap: '1rem', width: '90%' ,alignItems:"flex-end"
                  }}>
                    <div style={{width: '75%'}}>
                      <InputText name="property.signatureSecond" fieldRequired={true}  title="Signature 2 (please type your name)"  />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', width: '25%' }}>
                      {/*<h5 style={{ color: '#848484', fontWeight: '400' }}>Date</h5>*/}
                      {/*<input onChange={formik.handleChange} onBlur={formik.handleBlur} type='date' className='input-text' label='Date' id='' style={{ width: '100%' }} />*/}
                      <InputDateField inputProps={{type:'date'}}  name="property.date1" title="Date"  />

                    </div>
                  </div>
                </div>
                <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                    width: '100%'
                }}>
                    <div
                    style={{
                        // border: '2px solid #1761B0',
                        padding: '0',
                        height: '18px',
                        // width: '90%',
                        borderRadius: '2px'
                    }}>
                        <input 
                        type='checkbox' 
                        value='acknowledged' 
                        id='propertyDetail.isInfoCorrect'
                        style={{
                            height: '18px',
                            width: '18px',
                            opacity: '100'
                        }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onClick={() => console.log('clciked!')}
                        />                        
                    </div>

                    <label for='propertyDetail.isInfoCorrect'>I hereby acknowledge that the information provided is true</label>
                </div>
                </div>
            </Form>
            </>)}
          }          
        </Formik>

    </>
  )
}

export default AddProperty