import axios from "axios";

import { getUserFromLocalStorage } from "./localStorage";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const user = getUserFromLocalStorage();
console.log(user?.data?.token)
api.interceptors.request.use((config) => {
  const user = getUserFromLocalStorage();
  if (user) {
    config.headers.common["Authorization"] = `Bearer ${user?.data?.token}`;
  }
  return config;
});

export default api;
