import React,{useState,useEffect} from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import TextInputComponent from './TextInputComponent';
import api from "../utils/axios";
import { toast } from "react-toastify";
function RealtorCard(props) {
    const { state, companyName,typeName,propertyId,fetchProperty } = props;
    const [contactAgent, setContactAgent] = React.useState(false)
    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


     const [list, setList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [selectedUser, setSelectedUser] = useState(0);
    const [loading, setLoading] = useState(false);

    
    const getInitials = function (string) {
        var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
        
        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };



    

    const fetchList = async () => {
    

        try {
          setLoading(true);

          const { data } = await api.get(`master/realtor-list`);

          setList(data);
          setFilteredList(data);
          setLoading(false);
          
        } catch (error) {
          toast.error(error);
        }
  };


    useEffect(() => {
    fetchList();

    
  }, []);


   const filterBySearch = (event) => {
    // Access input value
    const query = event.target.value;
    // Create copy of item list
    var updatedList = [...list];
    // Include all elements which includes the search query
    updatedList = updatedList.filter((item) => {
      return item.fullName.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    // Trigger render with updated values
    //console.log(updatedList)
    setSelectedUser(0)
    setFilteredList(updatedList);
  };


  const saveUser = async (e) => {
      
      if(selectedUser == 0){
         toast.error(`Please, select ${typeName}`);
         alert(`Please, select ${typeName}`)
         return false
      }
      try {
        const { data } = await api.put(`master/add-realtor`, {
          id: propertyId,
          realtor_id: selectedUser,
        });
        fetchProperty()
        setContactAgent(false);
      } catch (error) {
        toast.error(error);
      }
    
  };

    return (
        <div
        style={{
            width: '211px',
            height: '227px',
            boxShadow: '0px 3px 6px #00000029',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
            paddingTop: '22px',
            paddingBottom: '22px',
            paddingLeft: '22px',
            paddingRight: '22px'
        }}>

        {state.realtorRs && (
            <>
            <div
            style={{
                height: '57px',
                width: '57px',
                backgroundColor: '#E6E6E6',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '19px',
                color: '#1761B0'
            }}>
                {getInitials(state.realtorRs.fullName)}
            </div>
            
            <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                // gap: '0.5rem'
            }}>
                <h5 className='blue'>{state.realtorRs.fullName}</h5>
                <p style={{ fontSize: '12px', marginBottom: '0' }}>Realtor at {companyName}</p>
            </div>
            </>
            )}
            <Button 
            style={{ fontSize: '14px', padding: '0.5rem', paddingLeft: '1rem', paddingRight: '1rem' }}
            onClick={() => setContactAgent(true)}>{state.realtorRs ? 'Update' : 'Add'} {typeName}</Button>
            {contactAgent === true ? (
                <Modal
                show={contactAgent}
                onHide={() => setContactAgent(false)}
                backdrop="static"
                keyboard={false}
                centered
                >
                     <Modal.Body>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0.5rem',
                            justifyContent: 'center',
                            // alignItems: 'center',
                            fontSize: '18px',
                        }}>
                            <h5 className='blue' style={{ marginBottom: '0', fontWeight: '700' }}>Add {typeName}</h5>
                           
                            <div style={{
                                display: 'flex',
                                gap: '0.75rem'
                            }}>
                                <TextInputComponent fontSize="14px" onChange={filterBySearch} label='Search' blueHeader style={{width: '100%'}}/>
                            </div>
                           
                            {filteredList?.map((rs) => 

                                    <div
                                        style={{border:'1px solid',padding:'10px',borderColor:(selectedUser == rs.id) ? '#000' :'#ccc',borderRadius:'6px',
                                        backgroundColor:(selectedUser == rs.id) ? '#1761B0' :'#fff',
                                        color:(selectedUser == rs.id) ? '#fff' :'#000'

                                        }}
                                        onClick={() => setSelectedUser(rs.id)}
                                    >{rs.fullName}</div>

                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button 
                        style={{
                            width: '100%'
                        }}
                        variant="primary" 
                        onClick={() => {
                            saveUser()
                        }
                        }>Save</Button>
                    </Modal.Footer>
                </Modal>
            ) : null}
        </div>
    )
}

export default RealtorCard