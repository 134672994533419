import { GoogleMap, MarkerF, InfoWindow, MarkerClustererF } from "@react-google-maps/api";
import { useState } from 'react'
import { useNavigate } from "react-router-dom";
 import { MarkerClusterer } from "@googlemaps/markerclusterer";
import house from '../assets/images/house1.jpg'

function Map(props) {
    const { center, properties, addr } = props;
    const { address_components, types } = addr;
    const addressLength = address_components?.length;
    const [activeMarker, setActiveMarker] = useState(null);
    const navigate = useNavigate()


    

    const handleActiveMarker = (marker) => {

        console.log(marker + " " + activeMarker)
      if (marker === activeMarker) {
        return;
      }
      setActiveMarker(marker);
    };



    const convertPrice = price => {
        console.log("price: ", price)
        const result = (price).toLocaleString('en-US', { 
            style: 'currency', 
            currency: 'USD',
            minimumFractionDigits: 0
        });
        return result
    }

    

    return (
        <>
       
        <GoogleMap zoom={4} center={center} mapContainerClassName="map">
            <MarkerClustererF averageCenter={true}>
            {clusterer => 
            properties?.data?.map(property =>  { 
                const location = JSON.parse(property.location)
               //console.log(property.title + " " + location.lat + ' '  + location.lng)
              return(
                <MarkerF 
                    position={{ lat: location.lat, lng:location.lng }}
                    onClick={() => handleActiveMarker(property)}
                    clusterer={clusterer}
                >
                    {activeMarker === property ? (
                        <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                            <div
                            onClick={() => navigate(`/property/${property.id}`, {state: property})}
                            style={{
                                display: 'flex',
                                height: '90px',
                                width: '196px',
                                cursor: 'pointer'
                            }}>
                               <div style={{
                                width: '50%',
                                backgroundColor: 'black'
                               }}>
                                <img src={property?.PropertyFiles[0]?.fileURL || house} style={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'contain'
                                }}/>
                                </div> 
                               <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '0.5rem',
                                width: '50%',
                                padding: '0.5rem',
                                justifyContent: 'center',
                               }}>
                               <h4 style={{ fontStyle: 'bold', marginBottom: '0' }} className="blue">{property?.title}</h4>
                                <h4 style={{ fontStyle: 'bold', marginBottom: '0' }} className="blue">{convertPrice(property?.price)}</h4>
                                <p style={{ fontSize: '18px', marginBottom: '0' }}>{property?.PropertyDetail?.address}</p>
                               </div>
                            </div>
                        </InfoWindow>
                    ) : null}
                </MarkerF>
                )}
            )}
            
            </MarkerClustererF>
        </GoogleMap>
        </>
    )
}

export default Map