import React,{useState} from 'react'
import Button from 'react-bootstrap/Button';
import AuthPageSideImage from '../../components/AuthPageSideImage';
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Form, useFormik} from 'formik'
import signUpSchema from '../../utils/validationSchema/signUpSchema';
import { fetchWrapper } from '../../helpers';
import Loader from "../../components/Loader";
function SignUp() {
    const navigate = useNavigate()
    const [spinner, setSpinner] = useState(false)
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            confirmPassword: ''
        },
        validationSchema: signUpSchema,
        onSubmit: async (values) => {

            setSpinner(true);
            const url = `${process.env.REACT_APP_BASE_URL}/user/check-user`;
            const data = {email:values.email}
            const checkUser  = await fetchWrapper.post(url,data)
            console.log(checkUser)
            setSpinner(false);
            if(!checkUser.status){
                alert("User Already exist..");
                return false;
             
            }
            if (values.password === values.confirmPassword) {
                navigate('/account-details', {state: {signUpValues: values}})
            } else {
                console.log("dfgfdg")
            }
        }
    })

    return (
        <>
        {spinner && <Loader /> }
        <div
        style={{
            display: 'flex',
            height: '100vh',
            flexWrap: 'wrap'
        }}>
            <AuthPageSideImage />
            <Formik
            initialValues={formik.initialValues}>
            {/* // validationSchema={formik.validationSchema}> */}
            {() => 
                {console.log("formik:", formik )
                return(
                <Form
                className='section'
                onSubmit={formik.handleSubmit}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2.5rem',
                }}>
                    <div
                    className='sub-section'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: '5rem',
                        marginRight: '5rem',
                        // marginTop: '7rem',
                        gap: '3rem'
                    }}>
                        <div>
                            <h4 className='blue' style={{fontSize: '20px', marginBottom: '1rem', marginTop: '0', fontWeight: '600'}}>Sign Up</h4>
                            <p style={{fontSize: '16px', fontWeight: '400', lineHeight: '3rem'}}>Start your property journey now by signing up</p>
                        </div>
                            <div
                            style={{
                                display: 'flex',
                                flexDirection:'column',
                                gap: '2rem'
                            }}>
                                <div>
                                    <h4 className='blue' style={{ fontSize: '16px', marginBottom: '1.5rem', fontWeight: '550' }}>Email Address</h4>
                                    <input 
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur} 
                                    id='email' 
                                    name='email' 
                                    className='input-text'
                                    type='text'
                                    style={{
                                        width: '100%'
                                    }}></input>
                                    {(formik.errors.email && formik.touched.email) ? <div className='error-msg'>{formik.errors.email}</div> : null}
                                </div>
                                <div 
                                style={{
                                    display: 'flex',
                                    gap: '3rem',
                                    justifyContent: 'space-between',
                                    flexWrap: 'wrap'
                                }}>
                                    <div 
                                    style={{ 
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        width: '100%',
                                    }}>
                                        <h4 className='blue' style={{ fontSize: '16px', marginBottom: '1.5rem', fontWeight: '550' }}>Password</h4>
                                        <input 
                                        onChange={formik.handleChange} 
                                        onBlur={formik.handleBlur} 
                                        id='password' 
                                        name='password' 
                                        type='password'
                                        className='input-text'
                                        style={{
                                        }}></input>
                                        {(formik.errors.password && formik.touched.password) ? <div className='error-msg'>{formik.errors.password}</div> : null}
                                    </div>  
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <h4 className='blue' style={{ fontSize: '16px', marginBottom: '1.5rem', fontWeight: '550' }}>Confirm Password</h4>
                                        <input 
                                        className='input-text' 
                                        onChange={formik.handleChange} 
                                        onBlur={formik.handleBlur} 
                                        id='confirmPassword' 
                                        name='confirmPassword' 
                                        type='password'></input>
                                    </div>  
                                </div>
                            </div>
                            <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem'
                            }}>
                                <input id='agree-terms' type='checkbox'></input>
                                <label for='agree-terms' style={{ fontSize: '16px', lineHeight: '2rem' }}>By signing up you agree to the following Terms & Conditions and Privacy Policy without reservation.</label>
                            </div>
                            <div>
                                <Button 
                                type='submit'
                                className='primary'
                                style={{
                                    width: '14.5rem',
                                    height: '4rem',
                                    fontSize: '20px'
                                }}>Sign Up</Button>
                            </div>
                            <div
                            style={{
                                marginBottom: '5rem'
                            }}>
                                <p>Already have an account? <Link to='/'>Sign In</Link></p>
                            </div>
                    </div>
                </Form>)}
                }
            </Formik>
        </div>
        </>
    )
}

export default SignUp