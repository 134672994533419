import React from 'react'


function CondosRent({InputText,SelectField,formik,SelectComponent,Country,State,City,filterData,TextInputComponent,CheckBoxGen,
        LeaseLength,
        ParkingSpot,
        ParkingType,
        LaundryType,
        PetFriendly,
        Amenities,
        loadCity
      }) {
    return (
        <>
        
        <div className='inputs-area'>
                              <SelectField name="property.propertyDetail.rentalType" list={filterData('RentalType')} title="Rental Type" fieldRequired={true} />
                         </div>
                         
                         <div className='inputs-area'>
                           <SelectField  fieldRequired={true} name="property.propertyDetail.bedroom" list={filterData('Bedroom')} title="Bedrooms" />
                          <SelectField  fieldRequired={true} name="property.propertyDetail.bathroom" list={filterData('Bathroom')} title="Bathrooms" />
                          
                        </div>
                         <div className='inputs-area'>
                          <div style={{width:'90%'}}>
                            <InputText name="property.propertyDetail.address" title="Address" placeholder="Address" fieldRequired={true}/>
                          </div>


                          <div style={{width:'368px'}}>
                            <InputText name="property.propertyDetail.streetNo" title="Street #"  fieldRequired={true}/>
                          </div>

                           <div style={{width:'368px'}}>
                            <InputText name="property.propertyDetail.street" title="Street" fieldRequired={true} />
                          </div>
                          <div style={{width:'368px'}}>
                            <InputText name="property.propertyDetail.unitNumber" title="Unit Number" fieldRequired={true} />
                          </div>

                          
                         
                          
                          
                          
                          
                        </div>
                        
                        
                        <div className='inputs-area'>
                          <SelectField  fieldRequired={true} name="property.propertyDetail.country" list={Country} title="Country" />
                          {/*<SelectComponent required={true} 

                          onChange={

                            e => {
                                         
                                            formik.handleChange(e)
                                            loadCity(e.target.value)

                                        }}
                          onBlur={formik.handleBlur} id='property.propertyDetail.state' label='State' options={State} />*/}
                          <SelectField  fieldRequired={true} name="property.propertyDetail.state" list={State} title="Province" />
                          <SelectField  fieldRequired={true} name="property.propertyDetail.city" list={City} title="City/Town" />
                        </div>



                        <div className='inputs-area'>
                           <SelectField  fieldRequired={true} name="property.propertyDetail.squareFeet" list={filterData('SquareFeet')} title="Square Feet" />
                          <div style={{width:'368px'}}>
                            <InputText name="property.propertyDetail.dateAvailable" title="Date Available" fieldRequired={true} />
                          </div>
                          <SelectField  fieldRequired={true} name="property.propertyDetail.leaseLength" list={filterData('LeaseLength')} title="Lease Length" />
                          <SelectField  fieldRequired={true} name="property.propertyDetail.parkingSpot" list={filterData('ParkingSpot')} title="Parking Spot" />
                          <SelectField  fieldRequired={true} name="property.propertyDetail.parkingType" list={filterData('ParkingType')} title="Parking Type" />
                          <SelectField  fieldRequired={true} name="property.propertyDetail.laundryType" list={filterData('LaundryType')} title="Laundry Type" />
                          <SelectField  fieldRequired={true} name="property.propertyDetail.airCondition" list={filterData('AirCondition')} title="Air Conditioning" />
                          <SelectField  fieldRequired={true} name="property.propertyDetail.heat" list={filterData('Heat')} title="Heat" />
                          <SelectField  fieldRequired={true} name="property.propertyDetail.petFriendly" list={PetFriendly} title="Pet Friendly" />
                          <SelectField  fieldRequired={true} name="property.propertyDetail.amenities" list={filterData('Amenities')} title="Amenities" />
                        </div>
                                        
        </>
      )
}

export default CondosRent