import React from 'react'
import HeaderTemplate from '../components/HeaderTemplate'
import Dropzone from '../components/Dropzone'
import SelectComponent from '../components/SelectComponent'
import { Button } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'

function AddPropertyDocuments() {
    const location = useLocation();
    const { state } = location;

    const [files, setFiles] = React.useState([])
    const [newState, setNewState] = React.useState(null)
    
    console.log("state: ", state)

    React.useEffect(() => {
        const newFilesArray = [...state?.propertyFile, ...files]
        state.propertyFile = newFilesArray;
        console.log("pls3: ", state)
    }, [files])

    return (
        <>
            <HeaderTemplate nextScreen='/list-my-property' state={state}/>  
            <div className='grey-background'>
                <div id='add-floor-plan' className='add-section'>
                    <h4 className='blue'>Upload property documents</h4>
                    <p>Please upload legal documents related to your property (optional)</p>
                    <SelectComponent style={{ width: '90%' }} label='Document type/category' options={[
                        {label: '', value: ''},
                    ]} />
                    <Dropzone setFiles={setFiles}/>
                    <Button className='button' style={{ width: '90%', height: '3rem' }}>ADD MORE DOCUMENTS</Button>
                </div>
            </div>
        </>
      )
}

export default AddPropertyDocuments