import React from 'react'
import AuthPageSideImage from '../../components/AuthPageSideImage';
import { Button } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom'

function ThankYou() {
    const navigate = useNavigate()

    return (
        <div
        style={{
            display: 'flex',
            height: '100vh'
        }}>
            <AuthPageSideImage />
            <div
            style={{
                width: '72%',
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '5rem',
                marginRight: '5rem',
                // marginTop: '7rem',
                gap: '3rem',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    gap: '3rem',
                    width: '30%',
                    alignItems: 'center'
                }}>
                    <h4 className='blue'>Thank You for signing up!</h4>
                    <p style={{ fontSize: '16px' }}>We will review your account and get back to you as soon as we can.</p>
                    <Button
                    style={{
                        width: '60%',
                        height: '4rem'
                    }}
                    onClick={
                        () => navigate('/signin')
                    }>Close</Button>
                </div>
            </div>
        </div>
  )
}

export default ThankYou