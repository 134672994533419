
import React,{useEffect,useState} from 'react';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Link,
  useDisclosure,
  Spinner
} from '@chakra-ui/react'
import MainSetup from './Setup/List.js';
import Loader from "../../components/Loader";
// import NavIcon from '../../images/nav_icon_img.svg'
// import NavIconActive from '../../images/nav_icon_active_img.svg';
import AdminHeader from './AdminHeader';
import { fetchWrapper } from '../../helpers';
import { useNavigate } from 'react-router-dom'

function AdminDashboard() {
	const navigate = useNavigate()

	const [spinner, setSpinner] = useState(false)

	 const [contentPage, setContentPage] = useState(<MainSetup />);
	 const [activeLink, setActiveLink] = useState('Water');

	 const changeMenu = (param,rs) => {
	 	// console.log(rs)
	   // alert(param)
	    if(param == 'MS'){
	      changeContent('MainSetup',rs)
	    }

	 
	    setActiveLink(rs.master_type);
	 };

	 const changeContent = (param ,rs) => {
	   // alert(param)
	    if(param === 'MainSetup'){
	      setContentPage(<MainSetup rs={rs} />);
	    }	   
	 };
	 	const [list,setList] = useState([]);

	 	useEffect(() => {
	 		changeContent('MainSetup', { master_type: 'Water' });
        LoadSideBar();
       
    }, []);

    const LoadSideBar= async () => {
 			setSpinner(true)
      const url = `${process.env.REACT_APP_BASE_URL}/master/listed`;
      const data  = await fetchWrapper.get(url)
      setList(data.data);
    	setSpinner(false)
      
      
  }

	return(
		<>
		{spinner && <Loader /> }
		{/*web page html*/}

		<Box className="mobile_hide">

		<AdminHeader/>
		<HStack className="dashboard_section" alignItems="top">
			<Box className="left_bar_section">

				{list?.map((rs,index)=>{
				 		return(
				 			<Box mb="10px" key={index}>
								<Link className={`nav_link ${activeLink === rs.master_type ? 'active' : ''}`} onClick={() => changeMenu('MS' ,rs)} >
									<HStack>
										<Box>
											{/*<Image className="link_img" src={NavIcon} />*/}
											{/*<Image className="active_img" src={NavIconActive} />*/}
										</Box>

										<Box>
											<Text className="nav_link_text" mb="0px">{rs.master_type}</Text>
										</Box>
									</HStack>
							 	</Link>
							</Box>
					)
				})}

				<Link className={`nav_link ${activeLink === "co" ? 'active' : ''}`} onClick={()=> navigate('/admin/country')}> 
					<HStack>
						<Box>
							{/*<Image className="link_img" src={NavIcon} />*/}
							{/*<Image className="active_img" src={NavIconActive} />*/}
						</Box>

						<Box>
							<Text className="nav_link_text" mb="0px">Country</Text>
						</Box>
					</HStack>
			 	</Link>

			 	<Link className={`nav_link ${activeLink === "co" ? 'active' : ''}`} onClick={()=> navigate('/admin/state')}> 
					<HStack>
						<Box>
							{/*<Image className="link_img" src={NavIcon} />*/}
							{/*<Image className="active_img" src={NavIconActive} />*/}
						</Box>

						<Box>
							<Text className="nav_link_text" mb="0px">State</Text>
						</Box>
					</HStack>
			 	</Link>

			 	<Link className={`nav_link ${activeLink === "co" ? 'active' : ''}`} onClick={()=> navigate('/admin/city')}> 
					<HStack>
						<Box>
							{/*<Image className="link_img" src={NavIcon} />*/}
							{/*<Image className="active_img" src={NavIconActive} />*/}
						</Box>

						<Box>
							<Text className="nav_link_text" mb="0px">City</Text>
						</Box>
					</HStack>
			 	</Link>

				
			</Box>

			<Box className="body_section">
				{contentPage}
			</Box>
		</HStack>
		</Box>

	
		</>
		)
}
export default AdminDashboard;