import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import authService from './authService'

// const user = JSON.parse(localStorage.getItem('user'))

const initialState = {
     user: null,
     accessToken: null,
     isError: false,
     isSuccess: false,
     isLoading: false,
     message: ''
}

export const signup = createAsyncThunk('auth/signup', async(user, thunkAPI) => {
        try {
            return await authService.signup(user)
        } catch (error) {
            
        }
    })





export const changePassword = createAsyncThunk('user/changePassword', async(reqData, thunkAPI) => {

     const token = thunkAPI.getState().auth.accessToken
        try {
            return await authService.changePassword(token,reqData)
        } catch (error) {
            
        }
})

export const updateProfile = createAsyncThunk('user/updateProfile', async(reqData, thunkAPI) => {

     const token = thunkAPI.getState().auth.accessToken
        try {
            return await authService.updateProfile(token,reqData)
        } catch (error) {
            
        }
})

export const login = createAsyncThunk('auth/login', async(user, thunkAPI) => {
    try {
        return await authService.login(user)
    } catch (error) {
        
        return thunkAPI.rejectWithValue(error);
    }
})

export const logout = createAsyncThunk('auth/logout', async (navigateToLogin, thunkAPI) => {
    try {
        const response = await authService.logout();
        return response
    } catch (error) {
        
    }
})

export const forgotPassword = createAsyncThunk('auth/forgot-password', async (reqData, thunkAPI) => {
    try {
        const response = await authService.forgotPassword(reqData);
        return response
    } catch (error) {
    }
})

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },
        
        
    },
    extraReducers: (builder) => {
        builder
        .addCase(signup.pending, (state) => {
            state.isLoading = true
        })
        .addCase(signup.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.user = action.payload.data
            state.message = action.payload.message
        })
        .addCase(updateProfile.pending, (state) => {
            state.isLoading = true
        })
        .addCase(updateProfile.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.user.data = action.payload.data
            state.message = action.payload.message
        })

        .addCase(changePassword.pending, (state) => {
            state.isLoading = true
        })
        .addCase(changePassword.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            //state.user.data = action.payload.data
            state.message = action.payload.message
        })


        .addCase(login.pending, (state) => {
            state.isLoading = true
            state.isError = false
        })
        .addCase(login.fulfilled, (state, action) => {
           
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.user = action.payload
            state.accessToken = action.payload.data.token
        })

        .addCase(login.rejected, (state,action) => {
            
            state.message = action.payload;
            state.isError = true
        })


        .addCase(logout.fulfilled, (state, action) => {
            state.user = null
            state.accessToken = null
        })
        .addCase(forgotPassword.pending, (state) => {
            state.message='loading'
            state.isLoading = true
        })
        .addCase(forgotPassword.fulfilled, (state, action) => {
            state.message = action.payload.message
            state.isLoading = false
        })
        // .addCase()
    }
})

export const { reset } = authSlice.actions
export default authSlice.reducer