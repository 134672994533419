import React,{useState,useEffect} from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import TextInputComponent from './TextInputComponent';
import { toast } from "react-toastify";
import api from "../utils/axios";
import io from "socket.io-client";
let socket, selectedChatCompare;


function Bidding(props) {
    const { propertyId} = props;
    const [addBid, setAddBid] = React.useState(false)
     const [bidPrice, setBidPrice] = React.useState(0)
   const [bidList, setBidList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reBid, setReBid] = useState(false);
    const { user } = useSelector((state) => state.auth)
    const [socketConnected, setSocketConnected] = useState(false);
    const userId = user.data.id
   const fetchBid = async () => {
    if (!propertyId) return;

    try {
      setLoading(true);

      const { data } = await api.get(`bid/${propertyId}`);

      if(data.find(x => x.user_id === userId)){

         const myBid = data.find(x => x.user_id === userId);

         setBidPrice(myBid.price)
         setReBid(myBid)

         
      }
      setBidList(data);
      setLoading(false);
      //socket.emit("join-chat", selectedChat.id);
    } catch (error) {
      toast.error(error);
    }
  };

   useEffect(() => {
    fetchBid();
    
   
  }, [propertyId]);


   useEffect(() => {
    socket = io(process.env.REACT_APP_BASE_URL_SOCKET);
    socket.emit("setup", user);
    socket.emit("join-bid", propertyId);
    socket.on("connected", () => setSocketConnected(true));

   
    
  }, []);


   const meChange = (e) => {
        setBidPrice(e.target.value)
   }

   const saveBid = async () => {

        if(reBid){
            var { data } = await api.put(`bid/`, {
                  price: bidPrice,
                  propertyId: propertyId,
                  id:reBid.id,
                });
        }else{
            var { data } = await api.post(`bid/`, {
                  price: bidPrice,
                  propertyId: propertyId,
                });
        }
       
        socket.emit("new-bid", propertyId);
        //setBidList([...bidList, data]);
         fetchBid()
        setAddBid(false)
        setReBid(true)
   }

   useEffect(() => {
      socket.on("bid-received", (newMessageReceived) => {
      
        fetchBid();
      });
   });
    return (
        <>
        <div className='section' style={{width:'20vw'}}>
            <div style={{display:'flex',direction:'row',
                justifyContent: 'space-between',
                
            }}>
                <div style={{display:'flex'}}>Auction</div>
                <div style={{display:'flex'}}>
                    
                {reBid ? (

                         <Button 
                        style={{
                            width: '100%'
                        }}
                        variant="primary" 
                        onClick={() => setAddBid(true)}
                        >Re Bid</Button>

                    ) : (

                         <Button 
                        style={{
                            width: '100%'
                        }}
                        variant="primary" 
                        onClick={() => setAddBid(true)}
                        >Add Bid</Button>

                    )}
               

                </div>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th>Bidder</th>
                        <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                {bidList?.map((item) => (
                    <tr>
                        <td>{item.User.firstName} {item.User.lastName}</td>
                        <td>{item.price}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>

         {addBid === true ? (
                <Modal
                show={addBid}
                onHide={() => setAddBid(false)}
                backdrop="static"
                keyboard={false}
                centered
                >
                    <Modal.Body>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0.5rem',
                            justifyContent: 'center',
                            // alignItems: 'center',
                            fontSize: '18px',
                        }}>
                            <h5 className='blue' style={{ marginBottom: '0', fontWeight: '700' }}>Add Bid</h5>
                           
                            <div style={{
                                display: 'flex',
                                gap: '0.75rem'
                            }}>
                                <TextInputComponent value={bidPrice} onChange={meChange} fontSize="14px" label='Your Price' blueHeader style={{width: '100%'}}/> 

                            </div>
                            
                           
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button 
                        style={{
                            width: '100%'
                        }}
                        variant="primary" 
                        onClick={() => {
                            saveBid()
                        }
                        }>Save</Button>
                    </Modal.Footer>
                </Modal>
            ) : null}

        </>
    )
}

export default Bidding