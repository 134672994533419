import axios from 'axios'
import API_ENDPOINTS from '../../utils/apiEndpoints'

const base_url = process.env.REACT_APP_BASE_URL

const signup = async(userData) => {
    const API_URL = base_url + API_ENDPOINTS.auth + API_ENDPOINTS.signup
    const response = await axios.post(API_URL, userData)

    if (response.data) {
        localStorage.setItem('user', JSON.stringify(response.data))
    }

    return response.data
}

const updateProfile = async(token,userData) => {
    const API_URL = base_url + API_ENDPOINTS.user + '/updateProfile'

    const config = {
        headers:{
          Authorization: `Bearer ${token}`,
        }
    };

    const response = await axios.post(API_URL, userData,config)

    if (response.data) {
       // localStorage.setItem('user', JSON.stringify(response.data))
    }

    return response.data
}


const changePassword = async(token,userData) => {
    const API_URL = base_url + API_ENDPOINTS.user + '/changePassword'

    const config = {
        headers:{
          Authorization: `Bearer ${token}`,
        }
    };

    const response = await axios.post(API_URL, userData,config)

    if (response.data) {
       // localStorage.setItem('user', JSON.stringify(response.data))
    }

    return response.data
}

const login = async(userData) => {
    const API_URL = base_url + API_ENDPOINTS.auth + API_ENDPOINTS.login
    const response = await axios.post(API_URL, userData)

    if (response.data) {
        localStorage.setItem('user', JSON.stringify(response.data))
    }

    return response.data
}

const forgotPassword = async(userData) => {
    const API_URL = base_url + API_ENDPOINTS.auth + API_ENDPOINTS.forgotPassword
    const response = await axios.post(API_URL, userData)

    return response.data
}

const logout = async () => {
    await localStorage.removeItem('user')
    await localStorage.removeItem('accessToken')
}

const authService = {
    signup,
    login,
    logout,
    forgotPassword,
    updateProfile,
    changePassword,
}

export default authService