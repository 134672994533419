import React,{useState} from 'react'
import GreyPage from '../components/GreyPage'
import { Formik, Form, useFormik } from 'formik'
import TextInputComponent from '../components/TextInputComponent'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import changePasswordSchema from '../utils/validationSchema/changePasswordSchema';
import { changePassword, reset } from '../features/auth/authSlice'

import Loader from "../components/Loader";
function Settings() {
  const [spinner, setSpinner] = useState(false)
  const dispatch = useDispatch()

   const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
            

            // userImage: null
        },
        validationSchema: changePasswordSchema,
        onSubmit: async (values) => {
          setSpinner(true)
            console.log(values)
            const reqData = {
                ...values,
               
            }
            // console.log("requestData: ", reqData)
            dispatch(changePassword(reqData))
          setSpinner(false)  
        }
    })


    return (
        <>
        {spinner && <Loader /> }
        <div style={{width: '100%'}}>
          <div 
          className='grey-background-home' 
          style={{ 
              width: '79%', 
             
              marginLeft: '21%', 
              paddingRight: '1rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '1.5rem',
              paddingLeft: '2%',
          }}>
            <GreyPage heading='Settings' />

             <Formik
        initialValues={formik.initialValues}>
          {() => 
            {//console.log("formik: ", formik)
            return (<Form 
            className='grey-background'
            style={{
              paddingBottom: '10rem'
            }}
            onSubmit={formik.handleSubmit}>
            <div
            style={{
                width: '100%',
                height: '65vh',
                backgroundColor: 'white',
                display: 'flex',
                paddingTop: '56.5px',
                gap: '0.5rem',
            }}>
                  <div
                style={{
                  paddingLeft: '58.5px',
                  display: 'flex',
                  flexDirection: 'column',
                  
                
                  width: '50%',
                  paddingRight: '58.5px'
                }}>
                  <h4 className='blue' style={{ marginBottom: '1.5rem' }}>Change Password</h4>

                    <input 
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur} 
                                    id='password' 
                                    name='password' 
                                    className='input-text'
                                    type='text'
                                    style={{
                                        width: '100%'
                                    }}></input>
                                    {(formik.errors.password && formik.touched.password) ? <div className='error-msg'>{formik.errors.password}</div> : null}
                    <br />

                    <input 
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur} 
                                    id='confirmPassword' 
                                    name='confirmPassword' 
                                    className='input-text'
                                    type='text'
                                    style={{
                                        width: '100%'
                                    }}></input>
                                    {(formik.errors.confirmPassword && formik.touched.confirmPassword) ? <div className='error-msg'>{formik.errors.confirmPassword}</div> : null}


                     <div
                style={{
                  marginTop: '1.5rem',
                  display: 'flex',
                  gap: '1rem',
                  justifyContent: 'flex-end',
                  marginBottom: '3rem'
                }}>
                  <Button style={{ backgroundColor: 'white', color: '#1761B0', width: '158px', fontSize: '14px', height: '40px' }}>Cancel</Button>
                  <Button style={{ backgroundColor: '#1761B0', color: 'white', width: '158px', fontSize: '14px', height: '40px' }} type='submit'>Save Changes</Button>
                </div>

                </div>
            </div>
             </Form>)}
          }          
        </Formik>
          </div>
        </div>
        </>
      )
}

export default Settings