export const getSender = (loggedUser,fromUser,toUser) => {
  return fromUser.id === loggedUser?.data.id ? toUser.firstName + " " + toUser.lastName : fromUser.firstName + " " + fromUser.lastName;
};

export const getSenderId = (loggedUser,fromUser,toUser) => {
  return fromUser.id === loggedUser?.data.id ? toUser.id : fromUser.id;
};
export const getUserList = (loggedUser,fromUser,toUser) => {
  return fromUser.id === loggedUser?.data.id ? toUser.id + "-"  +  toUser.firstName + " " + toUser.lastName : fromUser.firstName + " " + fromUser.lastName;
};

export const getSenderFull = (loggedUser, fromUser,toUser) => {
  return "Aaa";//fromUser.id === loggedUser?data.id ? toUser.firstName : fromUser.firstName;
};
export const isSameSenderMargin = (messages, m, i, userId) => {
  if (
    i < messages.length - 1 &&
    messages[i + 1].sender._id === m.sender._id &&
    messages[i].sender._id !== userId
  )
    return 33;
  else if (
    (i < messages.length - 1 &&
      messages[i + 1].sender._id !== m.sender._id &&
      messages[i].sender._id !== userId) ||
    (i === messages.length - 1 && messages[i].sender._id !== userId)
  )
    return 0;
  else return "auto";
};

export const isSameSender = (messages, m, i, userId) => {
  return (
    i < messages.length - 1 &&
    (messages[i + 1].sender._id !== m.sender._id ||
      messages[i + 1].sender._id === undefined) &&
    messages[i].sender._id !== userId
  );
};

export const isLastMessage = (messages, i, userId) => {
  return (
    i === messages.length - 1 &&
    messages[messages.length - 1].sender._id !== userId &&
    messages[messages.length - 1].sender._id
  );
};

export const isSameUser = (messages, m, i) => {
  return i > 0 && messages[i - 1].sender._id === m.sender._id;
};
