import { Avatar, Tooltip } from "@chakra-ui/react";
import React, { useRef, useEffect } from "react";
import {
  isLastMessage,
  isSameSender,
  isSameSenderMargin,
  isSameUser,
} from "../../config/chat";
import { useAppContext } from "../../context/ChatProvider";
import moment from 'moment'
const ScrollableChat = ({ messages }) => {
  const { user } = useAppContext();

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

const getInitials = function (string) {
        var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
        
        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };
  return (
    <div>
      {messages &&
        messages.map((m, i) => (
          <div style={{ display: "flex" }} key={m._id}>
            {(isSameSender(messages, m, i, user._id) ||
              isLastMessage(messages, i, user._id)) && (
              <Tooltip
                label={m.sender.username}
                placement="bottom-start"
                hasArrow
              >
                <Avatar
                  mt="7px"
                  mr={1}
                  size="sm"
                  cursor="pointer"
                  name={m.sender.username}
                  src={m.sender.avatar}
                />
              </Tooltip>
            )}
            <span
              style={{
                backgroundColor: `${
                  m.sender._id === user._id ? "transparent" : "#B9F5D0"
                }`,
                marginRight: isSameSenderMargin(messages, m, i, user._id),
                marginTop: isSameUser(messages, m, i, user._id) ? 3 : 10,
                
                
                maxWidth: "100%",
              }}
            >
              <div style={{display: 'none'}}></div>

              <div className="d-flex justify-content-between message_view">
                <div className="user_letter_box">
                  <h2>{getInitials(m.User.fullName)}</h2>
                </div>

                <div className="text_section">
                
                  <h3>{m.User.fullName}</h3>
                  

                  <p>{m.message}</p>
                </div>

                <div className="time_section">
                  <p>{moment(m.createdAt).format('h:mm A')}</p>
                </div>
              </div>

              <div className="date_section">
                <p>{moment(m.createdAt).format('DD MMMM YYYY')}</p>
              </div>
            </span>
          </div>
        ))}
      <div ref={messagesEndRef}></div>
    </div>
  );
};

export default ScrollableChat;
