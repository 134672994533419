import React from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import TextInputComponent from './TextInputComponent';
import api from "../utils/axios";
import { toast } from "react-toastify";
function AgentCard(props) {
    const { name, companyName,typeName,toUser } = props;
    const [contactAgent, setContactAgent] = React.useState(false)
    const [show, setShow] = React.useState(false);
     const [newMessage, setNewMessage] = React.useState('');


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const sendMessage = async () => {

         const { data } = await api.post(`message/new`, {
          message: newMessage,
          toUser:toUser,
        });
        toast.success("Message  has been sent");
        setContactAgent(false)
    }

    const messageChange = (e) => {
        setNewMessage(e.target.value)
        
    }

    return (
        <div
        style={{
            width: '211px',
            height: '227px',
            boxShadow: '0px 3px 6px #00000029',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
            paddingTop: '22px',
            paddingBottom: '22px',
            paddingLeft: '22px',
            paddingRight: '22px'
        }}>
            <div
            style={{
                height: '57px',
                width: '57px',
                backgroundColor: '#E6E6E6',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '19px',
                color: '#1761B0'
            }}>
                JB
            </div>
            <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                // gap: '0.5rem'
            }}>
                <h5 className='blue'>{name}</h5>
                <p style={{ fontSize: '12px', marginBottom: '0' }}>{typeName} at {companyName}</p>
            </div>
            <Button 
            style={{ fontSize: '14px', padding: '0.5rem', paddingLeft: '1rem', paddingRight: '1rem' }}
            onClick={() => setContactAgent(true)}>Contact {typeName}</Button>
            {contactAgent === true ? (
                <Modal
                show={contactAgent}
                onHide={() => setContactAgent(false)}
                backdrop="static"
                keyboard={false}
                centered
                >
                    <Modal.Body>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0.5rem',
                            justifyContent: 'center',
                            // alignItems: 'center',
                            fontSize: '18px',
                        }}>
                            <h5 className='blue' style={{ marginBottom: '0', fontWeight: '700' }}>Contact {typeName}</h5>
                            <p style={{ fontSize: '14px' }}>Get in touch with the seller/agent</p>
                           
                            <TextInputComponent onChange={messageChange} label='Message' blueHeader textArea style={{width: '100%'}} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button 
                        style={{
                            width: '100%'
                        }}
                        variant="primary" 
                        onClick={() => {
                            sendMessage()
                            
                        }
                        }>Send Message</Button>
                    </Modal.Footer>
                </Modal>
            ) : null}
        </div>
    )
}

export default AgentCard