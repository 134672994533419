import React,{useState,useEffect} from 'react'
import api from "../../utils/axios";
import { useAppContext } from "../../context/ChatProvider";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { getSender,getUserList } from "../../config/chat";
import { AddIcon } from "@chakra-ui/icons";
import { Box, Stack, Text } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { BsSearch } from "react-icons/bs";
import { MdEditSquare } from "react-icons/md";
import ChatLoading from "./ChatLoading";
import moment from 'moment'
const base_url = process.env.REACT_APP_BASE_URL

function ChatUser({fetchAgain}) {
    const [loggedUser, setLoggedUser] = useState();
    const { selectedChat, setSelectedChat, chats, setChats } = useAppContext();
    const fetchChats = async () => {
        try {
           const { data } = await api.get("chat");


          setChats(data);
        } catch (error) {
            console.log(error)
          //toast.error(error);
        }
      };

    useEffect(() => {
    setLoggedUser(getUserFromLocalStorage("user"));
    fetchChats();
  }, []);

    const getInitials = function (string) {
        var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
        
        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };
    return (
        <>

        <Box
      display={{ base: selectedChat ? "none" : "flex", md: "flex" }}
      flexDirection="column"
      alignItems="center"
      className="br_top_left_10 br_bot_left_10"
      style={{borderRightWidth: '1px',borderColor: '#ccc'}}
      bg="white"
      w={{ base: "100%", md: "32%" }}
      
    >
      <Box
        fontFamily="Poppins"
        display="flex"
        w="100%"
        className="user_search_section"
        justifyContent="space-between"
        alignItems="center"
      >
        

        <div className="search_field">
          <BsSearch />
          <input type='text' />
        </div>

        <div className="copy_box">
          <MdEditSquare />
        </div>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        bg="#fff"
        w="100%"
        h="100%"
        className="br_bot_left_10"
        overflowY="hidden"
      >
        {chats ? (
          <Stack overflowY="scroll">
            {chats?.map((chat) => (
              <Box
                onClick={() => setSelectedChat(chat)}
                cursor="pointer"
                bg={
                  selectedChat === chat ? "rgb(23, 97, 176)" : "#fff"
                }
                color={selectedChat === chat ? "white" : "black" }
                p={4}
                className="user_box"
                key={chat?.id}
               
              >
                <div>
                 
                  <div className="d-flex justify-content-between">
                    <div className="name_box">
                      <h3>{getInitials(getSender(loggedUser,chat.fromUser,chat.toUser))}</h3>
                    </div>

                    <div className="text_section">
                      <h2 style={{color: selectedChat === chat ? "white" : "rgb(23, 97, 176)"}}>{getSender(loggedUser,chat.fromUser,chat.toUser)}</h2>
                      <p>{chat.Message.message} </p>
                    </div>

                    <div className="time_section">
                      <p>{moment(chat.Message.createdAt).format('h:mm A')}</p>
                    </div>
                  </div>
                </div>
              </Box>
            ))}
          </Stack>
        ) : (
          <ChatLoading />
        )}
      </Box>
    </Box>
            
        </>
      )
}

export default ChatUser