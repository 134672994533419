import * as yup from "yup";

const addPropertySchema = yup.object({
    property:yup.object({

        propertyType:yup.string().required("Please select property type"),
        title:yup.string().required("Please enter property title"),
        saleType:yup.string().required("Please select sale type"),
        price:yup.string().required("Please enter Price"),
        propertyDescription:yup.string().required("required"),
        signatureFirst:yup.string().required("required"),
        signatureSecond:yup.string().required("required"),
         // maintenance:yup.string().required("Please select maintenance"),

    
                             propertyDetail: yup.object({
                               
                                ownerName:yup.string().required("Please enter ownerName"),
                                country:yup.string().required("Please select country"),
                                state:yup.string().required("Please select state"),
                                city:yup.string().required("Please select city"),
                                address:yup.string().required("Please enter address"),
                                legalName:yup.string().required("Please enter legalName"),
                                propertySize:yup.string().required("Please select propertySize"),
                                fronting:yup.string().required("Please select fronting"),
                                lotFrontage:yup.string().required("Please enter lotFrontage"),
                                // lotDepth:yup.string().required("Please enter lotDepth"),
                                // legalDescription:yup.string().required("Please enter legalDescription"),
                                bedroom:yup.string().required("Please select bedroom"),
                                kitchen:yup.string().required("Please select country"),
                                washroom:yup.string().required("Please select washroom"),
                                squareFeet:yup.string().required("Please select squareFeet"),
                                basement:yup.string().required("Please select basement"),
                                parking:yup.string().required("Please enter parking"),
                                
                                garageType:yup.string().required("Please select garageType"),
                                garageParking:yup.string().required("Please select garageParking"),
                                heat:yup.string().required("Please select heat"),
                                heatType:yup.string().required("Please select heatType"),
                                airCondition:yup.string().required("Please select airCondition"),
                                propertyTax:yup.string().required("Please enter propertyTax"),
                                pool:yup.string().required("Please select pool"),
                                firePlace:yup.string().required("Please select firePlace"),                
                                


                              

                                


                                // interiorNoOfRooms: yup
                                // .string()
                                // .required("Please enter number of rooms")
                                // .matches(new RegExp(/^\d/), "Number of rooms must be a number"),
                                // interiorNoOfBedRooms: yup
                                // .string()
                                // .required("Please enter number of bedrooms")
                                // .matches(new RegExp(/^\d/), "Number of rooms must be a number"),
                                // interiorNoOfKitchens: yup
                                // .string()
                                // .required("Please enter number of kitchens")
                                // .matches(new RegExp(/^\d/), "Number of rooms must be a number"),
                                // interiorNoOfWashrooms: yup
                                // .string()
                                // .required("Please enter number of washrooms")
                                // .matches(new RegExp(/^\d/), "Number of rooms must be a number"),
                                // listPrice: yup
                                // .string()
                                // .required("Please enter a list price")
                                // .matches(new RegExp(/^\d/), "Price must be a number"),     
                                // taxes: yup
                                // .string()
                                // .required("Please enter a list price")
                                // .matches(new RegExp(/^\d/), "Price must be a number"),     
                                // taxYear: yup.number()
                                // .test('len', 'Year must be exactly 4 characters long', 
                                // val => val && val.toString().length === 4),

                            })

    })
})

export default addPropertySchema;