import React from 'react';
import {
  
  SelectControl,
  
} from "formik-chakra-ui";

function SelectField(props) {
	const {name ,list,title,fieldRequired} = props
	return(
		<>
			<div className="field_section">
				
				
				<SelectControl
		            name={name}
		            label={  	
	                fieldRequired ? <div className="head" >{title} <span style={{color:"red"}}>*</span> </div> : title
		            }
		            className="SelectControl"
		            selectProps={{ placeholder: "-- Select --- " }}
		          >
		        
		          
		          {list?.map((item) => {
		          			return (
		          						<option value={item.value}>{item.text}</option>
		          				)

		          })}
		           
		          </SelectControl>
			</div>
		</>
	);
}

export default SelectField;