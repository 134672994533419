import React from 'react'
import HeaderTemplate from '../components/HeaderTemplate'
import Dropzone from '../components/Dropzone'
import { useLocation } from 'react-router-dom'
import { S3Client, AbortMultipartUploadCommand, PutObjectCommand } from "@aws-sdk/client-s3";

function AddPropertyImages() {
    const location = useLocation();
    const { state } = location;
    const [files, setFiles] = React.useState([])
    const [newState, setNewState] = React.useState(null)
    

    React.useEffect(() => {
        for (let [key, value] of Object.entries(state['property'])) {
            if (Array.isArray(value) === true){
                if (value.length === 1) state['property'][key] = value[0]
                else {
                    var stringValue = "";
                    for (var j=0; j<2; j++){
                        stringValue = stringValue + value[j]
                        if (j===0) stringValue = stringValue + ", "
                    }
                    state['property'][key] = stringValue
                }
            }
        }
    }, [])


    React.useEffect(() => {
        setNewState({...state, propertyFile: files})
        // console.log("pls: ", newState)
    }, [files])

    return (
    <div>
        <HeaderTemplate nextScreen='/add-property-floor-plan' state={newState}/>
        <div className='grey-background'>
            <div id='add-photos-video' className='add-section'>
                <h4 className='blue'>Add Photos & Video</h4>
                <p>Please upload images and videos of the property you're listing.</p>
                <Dropzone setFiles={setFiles}/>
            </div>
        </div>
    </div>
    )
}

export default AddPropertyImages