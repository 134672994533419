import React from 'react'
import AuthPageSideImage from '../../components/AuthPageSideImage';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'
import { MdOutlineArrowBack } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { forgotPassword, reset } from '../../features/auth/authSlice';
import { Formik, Form, useFormik } from 'formik'
import Modal from 'react-bootstrap/Modal';

function LostPassword() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { message } = useSelector(state => state.auth)

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        onSubmit: async (values) => {
            // console.log("requestData: ", values)
            dispatch(forgotPassword(values))
        }
    })

    // React.useEffect(() => {
    //     if (message === 'Email sent.') {
    //         navigate('/')
    //     }
    // }, [message])

    return (
        <div
        style={{
            display: 'flex',
            height: '100vh',
            flexWrap: 'wrap'
        }}>
            <AuthPageSideImage />
            <div
            className='login-section'
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '3rem',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '3rem',
                }}>
                    <div
                    style={{
                        display: 'flex',
                        gap: '1rem'
                    }}>
                        <MdOutlineArrowBack 
                        style={{
                            color: '#1761B0',
                            height: '24px',
                            width: '24px'
                        }}/>  <h4 className='blue'>Lost Password</h4>
                    </div>
                    <div>
                        <p>
                        Please enter your email address. You will receive an email message with instructions on how to reset your password.
                        </p>
                    </div>
                    <Formik initialValues={formik.initialValues}>
                    {() => 
                        {console.log("formik:", formik )
                        return(
                            <Form
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '2rem',
                            }}
                            onSubmit={formik.handleSubmit}>
                                <h5 className='blue'>Email Address</h5>
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} id='email' name='email' type='text' className='input-text'></input>
                                <Button
                                style={{
                                    borderRadius: '10px',
                                    height: '63px',
                                    fontWeight: '600',
                                    fontSize: '20px',
                                    backgroundColor: '#1761B0',
                                    cursor: 'pointer'
                                }}
                                type='submit'
                                >Send</Button>
                            </Form>)}
                        }
                        </Formik>
                    <div style={{ marginBottom: '5rem' }}>
                        <p>Don't have an account? <Link to='/signup'>Sign Up</Link></p>
                    </div>
                </div>
            </div>
            {message === 'Email sent.' ? (
                <Modal
                show={true}
                // onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                >
                    <Modal.Body>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '18px'
                        }}>
                            <span className='green' style={{ fontWeight: '600' }}>Success</span>
                            <span style={{ textAlign: 'center' }}>The email has been sent</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button 
                        variant="primary" 
                        onClick={() => {
                                navigate('/')
                                dispatch(reset())
                            }
                        }>OK</Button>
                    </Modal.Footer>
                </Modal>
            ) : null}
        </div>
  )
}

export default LostPassword