import React,{useState,useEffect} from 'react'
import GreyPage from '../components/GreyPage'
import ChatUser from '../components/chat/ChatUser'
import ChatBox from '../components/chat/ChatBox'
import { useAppContext } from "../context/ChatProvider";
import { Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";


function Messages() {

  const { user } = useAppContext();
  const [fetchAgain, setFetchAgain] = useState(false);

  
    return (
       <div style={{ width: "100%" }}>
          <div 
          className='grey-background-home' 
          style={{ 
              width: '79%', 
            
              marginLeft: '21%',
              paddingLeft: '2%', 
              paddingRight: '1rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '1.5rem',
          }}>
            <GreyPage heading='Messages' />


             <div style={{ width: "100%" }}>
              
              <Box
                display="flex"
                justifyContent="space-between"
                w="100%"
                h="91.5vh"
                className="message_box"
              >
                <ChatUser fetchAgain={fetchAgain} />
                {user && (
                  <ChatBox fetchAgain={fetchAgain} setFetchAgain={setFetchAgain} />
                )}
              </Box>
            </div>

             
          </div>
        </div>
      )
}

export default Messages