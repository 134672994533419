import React,{useEffect} from 'react'
import HeaderTemplate from '../components/HeaderTemplate'
import DropZoneEdit from '../components/DropZoneEdit'
import { useLocation } from 'react-router-dom'
import { S3Client, AbortMultipartUploadCommand, PutObjectCommand } from "@aws-sdk/client-s3";
import DropZoneSingle from '../components/DropZoneSingle'
import propertyService from '../features/property/propertyService'
import {useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { FiEye, FiEdit, FiTrash2 } from 'react-icons/fi';
import { useParams } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';

function EditPropertyImages(props) {
    const location = useLocation();
    const { state } = location;
    
    const { id } = useParams()
    const pid = id
    const [files, setFiles] = React.useState([])
    const [newState, setNewState] = React.useState(null)

    const [editImage, setEditImage] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    
     const [imageList, setImageList] = React.useState([])
 
     const { user } = useSelector((state) => state.auth)

    useEffect(() => {
        setNewState({...state, propertyFile: files})
        loadImage(id);
    }, [id])


    const loadImage = async (id) => {
        const token = user.data.token;
       
        const list = await propertyService.imageList(token,id)

        setImageList(list)

    }


    const deleteFile = async (id) => {
        const token = user.data.token;
       
        const list = await propertyService.removeImage(token,id)

        loadImage(pid)

    }


    const updateImage = async (id,data) => {
        const token = user.data.token;
        
        setLoading(true);
        const list = await propertyService.updateImage(token,id,data)
        setLoading(false);
        loadImage(pid)
        setEditImage(false)

    }


    const editFile = async (id) => {
        const token = user.data.token;
       
       setEditImage(id)
        
        

    }

   

    

    return (


        <>

        {editImage !== false ? (
                <Modal
                show={editImage}
                onHide={() => setEditImage(false)}
                backdrop="static"
                keyboard={false}
                centered
                >
                    <Modal.Body>
                    {loading && (

                            <Spinner animation="grow" role="status">
                                
                            </Spinner>

                        )}
                    

                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            
                            justifyContent: 'center',
                            // alignItems: 'center',
                            fontSize: '18px',
                        }}>
                            <h5 className='blue' style={{ marginBottom: '0', fontWeight: '700' }}>Manage Photos & Video</h5>
                           
                            <DropZoneSingle id={editImage} propertyId={id} updateImage={updateImage} />
                           
                        </div>
                    </Modal.Body>
                    <Modal.Footer>

                        

                        <Button 
                        style={{
                            
                        }}
                        variant="secondry" 
                        onClick={() => {
                            setEditImage(false)
                        }
                        }>Close</Button>
                    </Modal.Footer>
                </Modal>
            ) : null}
    <div>
        <HeaderTemplate isDetailsScreen={`true`} pid={pid} isReview={`true`} />
       
        <div className='grey-background'

         style={{ 
                
                 

                  height: '100vh',
            }}
        >

        

            <div id='add-photos-video' className='add-section'>
                <h4 className='blue'>Manage Photos & Video</h4>

                <div className="row">
                    <div className="col-sm-11">
                        
                        <div className="text-end">
                            <a onClick={() => editFile('new')} className="blue"><b>Add New Image</b></a>
                        </div>
                    </div>
                </div>

                <div className="row">
                    

                    {imageList?.data?.map((item,i) => {

                    return (
                            <div className="col-sm-3">

                            <div className="card" >
                              <img className="img-responsive"  src={item.fileURL} />
                              <div className="card-body">
                                
                                <div className="row">
                                    <div className="col-sm-10 text-start">
                                        Category: {item.PropertyCategory?.name}
                                    </div>
                                   
                                    <div className="col-sm-2 text-end" style={{alignText:'right'}} >
                                        <a onClick={() => editFile(item.id)}><FiEdit style={{ color: 'blue',alignText:'right',display:'inline-block' }}/></a>
                                        <a  onClick={() => deleteFile(item.id)}><FiTrash2 style={{ color: 'blue',alignText:'right',display:'inline-block' }}/></a>
                                    </div>
                                </div>
                                

                                    
                                
                              </div>
                            </div>

                                
                                    
                                    
                                
                            </div>
                        )
                    })}
                    {imageList?.data?.length == 0  ? 
                        <div className="text-center"><b>There is no image in this property</b>
                        <br />
                        <a onClick={() => editFile('new')} className="blue"><b>Add New Image</b></a>
                        
                        </div> : 

                        null

                    }


                </div>
                
            </div>
        </div>


    </div>
    </>
    )
}

export default EditPropertyImages