import React,{useState,useEffect} from 'react'
import GreyPage from '../components/GreyPage'
import { FaUserCircle } from 'react-icons/fa'
import { HiCamera } from 'react-icons/hi'
import TextInputComponent from '../components/TextInputComponent'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, useFormik } from 'formik'
import accountDetailsSchema from '../utils/validationSchema/accountDetailsSchema';
import { updateProfile, reset } from '../features/auth/authSlice'
import SelectComponent from '../components/SelectComponent'
function Profile() {
  const { user } = useSelector((state) => state.auth)
  

  const [selectedFile, setSelectedFile] = useState()
    const [preview, setPreview] = useState()


   const dispatch = useDispatch()

   const companyDetail = JSON.parse(user?.data?.companyDetail)
   const formik = useFormik({
        initialValues: {
            firstName: user.data.firstName,
            lastName: user.data.lastName,
            dob: '',
            email:user.data.email,
            contactNumber: user.data.contactNumber,
            retalerNumber: '',
           
            companyName:  (companyDetail) ? companyDetail.companyName : '',
            companyAddress:(companyDetail) ? companyDetail.companyAddress : '',
            companyCity:(companyDetail) ? companyDetail.companyCity : '',
            companyCountry:(companyDetail) ? companyDetail.companyCountry : '',
            companyState:(companyDetail) ? companyDetail.companyState : '',
            companyPostCode:(companyDetail) ? companyDetail.companyPostCode : '',
            companyPhoneNumber:(companyDetail) ? companyDetail.companyPhoneNumber : '',
            jobRole:(companyDetail) ? companyDetail.jobRole : '',
            signingOfficer:(companyDetail) ? companyDetail.signingOfficer : '',

            // userImage: null
        },
        validationSchema: accountDetailsSchema,
        onSubmit: async (values) => {
            console.log(values)
            const reqData = {
                ...values,
               
            }
            // console.log("requestData: ", reqData)
            dispatch(updateProfile(reqData))
        }
    })


   useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])


   const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedFile(e.target.files[0])
    }


  

    return (


       <Formik
        initialValues={formik.initialValues}>
          {() => 
            {//console.log("formik: ", formik)
            return (<Form 
            className='grey-background'
            style={{
              paddingBottom: '10rem'
            }}
            onSubmit={formik.handleSubmit}>
        <div>

          <div 
          className='grey-background-home' 
          style={{ 
              width: '81vw', 
              height: '100vh', 
              marginLeft: '19vw', 
              paddingRight: '5rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '3rem',
          }}>
            <GreyPage heading='Profile' />
            <div
            style={{
                width: '100%',
                  height: '100vh', 
                backgroundColor: 'white',
                display: 'flex',
                paddingTop: '56.5px'
            }}>
              <div
              style={{
                width: '15%',
                display: 'flex',
                justifyContent: 'center',
                paddingLeft: '58.5px',
              }}>
                <div style={{ position: 'absolute' }}>
                  

                  {selectedFile ?  <img src={preview} style={{  height: '100px', width: '100px',borderRadius:'50%' }} /> : <FaUserCircle style={{ color: '#C5C5C5', height: '115px', width: '115px' }}/>
                  }

                  <div style={{
                    backgroundColor: '#1761B0',
                    height: '41px',
                    width: '41px',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '0'


                  }}>
                    <HiCamera style={{ width: '25px', height: '21px', color: 'white' }} 

                    
                    
                        onClick={
                                    () => {
                                        document.getElementById('userImage').click()
                                    }
                                  }
                    />


                   
                    <input type='file' id="userImage" style={{display:'none'}} onChange={onSelectFile} />
                     

                  </div>

                             
                </div>

              </div>
              <div
              style={{
                paddingLeft: '58.5px',
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                overflowY: 'scroll',
                width: '85%',
              
                paddingRight: '58.5px'
              }}>
                <h4 className='blue' style={{ marginBottom: '1.5rem' }}>Basic Information</h4>
                <div style={{
                  display: 'flex',
                  gap: '1rem'
                }}>
               
                <TextInputComponent label='First Name' onChange={formik.handleChange} onBlur={formik.handleBlur} id="firstName" value={formik.values.firstName} style={{width: '100%'}}/>
                <TextInputComponent label='Last Name' onChange={formik.handleChange} onBlur={formik.handleBlur} id="lastName" value={formik.values.lastName} style={{width: '100%'}}/>
                </div>
                <div style={{
                  display: 'flex',
                  gap: '1rem'
                }}>
                  <TextInputComponent label='Email' onChange={formik.handleChange} onBlur={formik.handleBlur} id="email" value={formik.values.email} style={{width: '50%'}}/> <TextInputComponent label='Phone Number' onChange={formik.handleChange} onBlur={formik.handleBlur} id="contactNumber" value={formik.values.contactNumber} style={{width: '50%'}}/>
                </div>
                
                <h4 className='blue' style={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}>Company Information</h4>
                <TextInputComponent label='Company Name' onChange={formik.handleChange} onBlur={formik.handleBlur} id="companyName" value={formik.values.companyName} style={{width: '100%'}}/>
                <div style={{
                  display: 'flex',
                  gap: '1rem'
                }}>
                  <SelectComponent label="Are you the signing officer?" value={formik.values.signingOfficer}  formik={formik} onChange={formik.handleChange} onBlur={formik.handleBlur} id='signingOfficer'  options={[{value: 'Yes', text: 'Yes'},{value: 'No', text: 'No'}]} style={{width: '100%'}} />
                  <SelectComponent  formik={formik} onChange={formik.handleChange} onBlur={formik.handleBlur} id='jobRole' label="Job Role" value={formik.values.jobRole} options={[{value: '1', text: 'Manager'}]}  style={{width: '100%'}} />
                                        
                </div>
                <div style={{
                  display: 'flex',
                  gap: '1rem'
                }}>
                <TextInputComponent label='Company Address' onChange={formik.handleChange} onBlur={formik.handleBlur} id="companyAddress" value={formik.values.companyAddress} style={{width: '100%'}}/>
                 <SelectComponent  formik={formik} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.companyState} id='companyState' label="State" options={[{value: '1', text: 'Sindh'}]} style={{width: '100%'}} />
                                        
                  
                </div>
                <div style={{
                  display: 'flex',
                  gap: '1rem'
                }}>
                <SelectComponent  formik={formik} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.companyCity} id='companyCity' label="City" options={[{value: '1', text: 'Karachi'}]} style={{width: '100%'}} />
                <SelectComponent  formik={formik} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.companyCountry} id='companyCountry' label="Cuontry" options={[{value: '1', text: 'Pakistan'}]} style={{width: '100%'}} />
                  
                </div>

                <div style={{
                  display: 'flex',
                  gap: '1rem'
                }}>
                <TextInputComponent label='Post Code' onChange={formik.handleChange} onBlur={formik.handleBlur} id="companyPostCode" value={formik.values.companyPostCode} style={{width: '100%'}}/>
                <TextInputComponent label='Company phone number' onChange={formik.handleChange} onBlur={formik.handleBlur} id="companyPhoneNumber" value={formik.values.companyPhoneNumber} style={{width: '100%'}}/>
                  
                </div>
                <div
                style={{
                  marginTop: '1.5rem',
                  display: 'flex',
                  gap: '1rem',
                  justifyContent: 'flex-end',
                  marginBottom: '3rem'
                }}>
                  <Button style={{ backgroundColor: 'white', color: '#1761B0', width: '158px', fontSize: '14px', height: '40px' }}>Cancel</Button>
                  <Button style={{ backgroundColor: '#1761B0', color: 'white', width: '158px', fontSize: '14px', height: '40px' }} type='submit'>Save Changes</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Form>)}
          }          
        </Formik>
      )
}

export default Profile