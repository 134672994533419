import {BrowserRouter as Router, Routes, Route, Switch} from 'react-router-dom'
import SignUp from './pages/Auth/SignUp';
import SignIn from './pages/Auth/SignIn';
import AccountDetails from './pages/Auth/AccountDetails';
import ThankYou from './pages/Auth/ThankYou';
import LostPassword from './pages/Auth/LostPassword';
import Dashboard from './pages/Dashboard';
import NewPassword from './pages/Auth/NewPassword';
import AddProperty from './pages/AddProperty';
import EditProperty from './pages/EditProperty';
import AddPropertyImages from './pages/AddPropertyImages';
import EditPropertyImages from './pages/EditPropertyImages';
import AddPropertyFloorPlan from './pages/AddPropertyFloorPlan';
import AddPropertyDocuments from './pages/AddPropertyDocuments';
import ListMyProperty from './pages/ListMyProperty';
import PropertyDetails from './pages/PropertyDetails';
import Listings from './pages/Listings';

import Navbar from './components/Navbar';
import './index.css';
import { ChatProvider } from "./context/ChatProvider";
import { ChakraProvider } from "@chakra-ui/react";
import SearchAndFilter from './pages/SearchAndFilter';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AdminLogin from './pages/Admin/login';
import AdminDashboard from './pages/Admin/AdminDashboard';
import Country from './pages/Admin/Country/List';
import State from './pages/Admin/State/List';
import City from './pages/Admin/City/List';
import { PrivateRoute } from './components/PrivateRoute';

function App() {
  return (
    <>
    <Router>
    <ChatProvider>
    <ChakraProvider>
      <div>
        <Routes>
          <Route path='/signup' element={<SignUp />} />
          <Route path='/' element={<SignIn />} />
          <Route path='/account-details' element={<AccountDetails />} />
          <Route path='/thank-you' element={<ThankYou />} />
          <Route path='/lost-password' element={<LostPassword />} />
          <Route path='/new-password' element={<NewPassword />} />
          <Route path='/add-property/:saleMethod' element={<AddProperty />} />
          <Route path='/add-property-images' element={<AddPropertyImages />} />
         
          <Route path='/add-property-floor-plan' element={<AddPropertyFloorPlan />} />
          <Route path='/add-property-documents' element={<AddPropertyDocuments />} />
          <Route path='/list-my-property' element={<ListMyProperty />} />
          
          <Route exact path='/property/:id' element={<PropertyDetails />} />


          <Route exact path='/property-list' element={<Listings />} />

           <Route exact path='/edit-property-images/:id' element={<EditPropertyImages />} />


          <Route path='/edit-property/:id' element={<EditProperty />} />
          <Route path='/search' element={<SearchAndFilter />} />
          <Route path='/home' element={<Navbar/>} />


          // Admin Routes Start
          <Route path='/admin' element={<AdminLogin />} />
          <Route path='/admin/dashboard' element={
            <PrivateRoute>
              <AdminDashboard />
            </PrivateRoute>
          } />
          <Route path='/admin/country' element={
            <PrivateRoute>
              <Country />
            </PrivateRoute>
          } />
          <Route path='/admin/state' element={
            <PrivateRoute>
              <State />
            </PrivateRoute>
          } />

          <Route path='/admin/city' element={
            <PrivateRoute>
              <City />
            </PrivateRoute>
          } />
          // Admin Routes End

        </Routes>
        <ToastContainer position="top-center" />
      </div>
      </ChakraProvider>
      </ChatProvider>
    </Router>
    </>
  );
}

export default App;
